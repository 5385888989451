import React, { useState, useEffect, useRef } from "react";
import styles from "./chatPage.module.less";
import { updateUserWork } from "@/api/userWork";
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import ChatBubble from "@/components/Element/AIChatRegion/ChatBubble";
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import SendDisableIcon from '@/assets/img/send_disable.svg';
import SendAbleIcon from '@/assets/img/send_able.svg';
import { cloneDeep } from "lodash";
import { requestLLMCall, requestLLMCallV2, searchWithLLMCall } from '@/services/requestLLMCall';
import { parseContent } from "@/utils/utils";
import { ChatMessage, MediaInfo } from '@/base/ChatMessage';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import FingerClickIcon from '@/assets/img/playground/finger_click.svg';
import { Select, Switch } from "antd";
import DropdownArrowIcon from '@/assets/img/playground/dropdown_arrow.svg';
import service from '@/services/axios';
import LinkIcon from '@/assets/img/playground/chat_link.svg';
import mammoth from 'mammoth';
import DeleteIcon from '@/assets/img/cuowu.svg';
import TeacherGenIcon from '@/assets/img/playground/chat_teacher_gen.svg';
import GenContentModal from "../GenContentModal/GenContentModal";
import ChatData from "@/base/PlaygroundData/ChatData";
import { getUserWork } from "@/api/userWork";
import { useParams } from 'react-router-dom';
import DataDefinition from "@/base/DataDefinition";
import ModifyAgentConfig from "../ModifyAgentConfig/ModifyAgentConfig";
import IntelligentAgentIcon from '@/assets/img/playground/chat_intelligent_agent.svg';
import axios, { CancelTokenSource } from 'axios';

const originalModelList = [
    { vendor: 'Deepseek', model: 'deepseek-chat', name: 'Deepseek-Chat' },
    { vendor: 'Kimi', model: 'moonshot-v1-8k', name: 'Kimi-8K' },
    { vendor: 'Kimi', model: 'moonshot-v1-32k', name: 'Kimi-32K' },
    { vendor: 'Kimi', model: 'moonshot-v1-128k', name: 'Kimi-128K' },
    { vendor: 'OpenAI', model: 'gpt-4o-mini', name: 'ChatGPT-4o-mini' },
    { vendor: 'OpenAI', model: 'gpt-4o', name: 'ChatGPT-4o' },
];

// 根据环境变量决定是否包含OpenAI的模型  
const modelList = (import.meta.env.VITE_OSS_SITE === "Aliyun")
    ? originalModelList.filter(model => model.vendor !== 'OpenAI')
    : originalModelList;

const ChatPage: React.FC = () => {
    const { md5 } = useParams();
    const { userInfoStore, ttsStore } = useStores();
    const [chatAgentInfo, setChatAgentInfo] = useState<ChatData>(new ChatData());
    const [inputValue, setInputValue] = useState('');
    const [chatDocContent, setChatDocContent] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const isComponentMounted = useRef<boolean>(true);
    const endMarkArr = ['.', '?', '!', ',', '，', '。', '？', '！', '、']; // 定义句子结束标记
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const docFileInputRef = useRef<HTMLInputElement | null>(null);
    const [searchUsingNetwork, setSearchUsingNetwork] = useState(false);
    const [isGenContentModalOpen, setIsGenContentModalOpen] = useState(false);
    const [isModifyAgentConfigOpen, setIsModifyAgentConfigOpen] = useState(false);
    const abortControllerRef = useRef<AbortController | null>(null);
    const cancelTokenSource = useRef<CancelTokenSource | null>(null);

    // 根据环境设置默认的模型、模型供应商和模型名称  
    const isAliyun = import.meta.env.VITE_OSS_SITE === "Aliyun";
    const defaultModelInfo = isAliyun
        ? { vendor: 'Deepseek', model: 'deepseek-chat', name: 'Deepseek-Chat' }
        : { vendor: 'OpenAI', model: 'gpt-4o-mini', name: 'ChatGPT-4o-mini' };

    const [model, setModel] = useState(defaultModelInfo.model);
    const [modelVendor, setModelVendor] = useState(defaultModelInfo.vendor);
    const [selectedModelName, setSelectedModelName] = useState(defaultModelInfo.name);

    const handleModelChange = (value: string) => {
        const selectedModel = modelList.find((item) => item.name === value);
        if (selectedModel) {
            setModel(selectedModel.model);
            setModelVendor(selectedModel.vendor);
            setSelectedModelName(selectedModel.name);
        }
    }
    const getChatAgentInfo = async () => {
        if (!md5 || userInfoStore.userInfoData.id === 0) return;
        try {
            const res: any = await getUserWork(md5, userInfoStore.userInfoData.id);
            console.log('res in getChatAgentInfo:', res);
            if (res.status === "success") {
                setChatAgentInfo(new ChatData(DataDefinition.toCamelCaseObj(res.data)));
            }
        } catch (error) {
            console.error('Error getting chat agent info:', error);
        }
    }
    useEffect(() => {
        if (userInfoStore.userInfoData.id === 0 || !md5) return;
        getChatAgentInfo();
    }, [userInfoStore.userInfoData.id, md5]);

    const sendStreamCall = async (requestParams: LLMCallRequestData, messages: ChatMessage[]) => {
        console.log('LLM 向后端发送POST请求:', requestParams.toJSON());
        let response: any = null;
        // 创建 AbortController 用于取消请求  
        const controller = new AbortController();
        abortControllerRef.current = controller;
        try {
            response = await requestLLMCall(requestParams, controller.signal);
            if (!response.ok) {
                console.log('请求失败，状态码为：' + response.status);
                // 这里可以返回或抛出错误
                return;
            }
        } catch (error) {
            console.log('请求失败，错误为：' + error);
            messages[messages.length - 1].rawContent = "请求失败，请重试";
            const newChatList = cloneDeep(messages);
            // setChatList(newChatList);
            chatAgentInfo.workInfo.chatList = newChatList;
            setChatAgentInfo(cloneDeep(chatAgentInfo));
            return;
        }

        console.log('LLM 请求成功');
        const reader = response.body.getReader();
        //转为音频的文字片段
        let responseTextBuffer = '';
        try {
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    // 在最后结束的时候可能还有剩余的字符串没有处理，也需要进行处理
                    if (responseTextBuffer.length > 0 &&
                        chatAgentInfo?.workInfo.shouldDoTts &&
                        !ttsStore.userNeedPause &&
                        isComponentMounted.current) {
                        await textToSpeech(responseTextBuffer);
                    }
                    responseTextBuffer = ""; // 清空

                    const chatAgentInfoBackup = cloneDeep(chatAgentInfo);
                    chatAgentInfoBackup.userName = userInfoStore.userInfoData.name || '';
                    updateUserWork(chatAgentInfoBackup);
                    break;
                }
                if (!isComponentMounted.current) {
                    break;
                }
                let str = new TextDecoder("utf-8").decode(value);
                let parseStr = parseContent(str);
                messages[messages.length - 1].rawContent += parseStr;
                responseTextBuffer += parseStr;
                const newChatList = cloneDeep(messages);
                // setChatList(newChatList);
                chatAgentInfo.workInfo.chatList = newChatList;
                setChatAgentInfo(cloneDeep(chatAgentInfo));
                //转语音
                while (hasEnoughCharacters(responseTextBuffer)) {
                    let endMarkIndex = responseTextBuffer.length;
                    for (let i = 0; i < endMarkArr.length; i++) {
                        let index = responseTextBuffer.indexOf(endMarkArr[i]);
                        if (index !== -1 && index < endMarkIndex) {
                            endMarkIndex = index;
                        }
                    }
                    let group = responseTextBuffer.slice(0, endMarkIndex + 1);
                    responseTextBuffer = responseTextBuffer.slice(endMarkIndex + 1);
                    if (isComponentMounted.current && chatAgentInfo.workInfo.shouldDoTts && !ttsStore.userNeedPause) {
                        //// 创建 CancelTokenSource 用于取消请求
                        cancelTokenSource.current = axios.CancelToken.source();
                        await textToSpeech(group, cancelTokenSource.current);
                    }
                }
            }
        } catch (error) {
            console.error('读取流数据时出错：', error);
        } finally {
            setIsFetching(false);
        }

    }

    const sendNonStreamCall = async (requestParams: LLMCallRequestData,
        messages: ChatMessage[],
        mode: number = 1) => {
        console.log('sendNonStreamCall - requestParams:', requestParams);
        console.log('sendNonStreamCall - msgs:', messages);
        const newMessages = cloneDeep(messages);
        let res = null;
        try {
            if (searchUsingNetwork) {
                res = await searchWithLLMCall(requestParams);
            } else {
                res = await requestLLMCallV2(requestParams, mode);
            }
            console.log('res in sendNonStreamCall:', res);
        } catch (error) {
            console.log('error in sendNonStreamCall:', error);
            res = { type: 'error', content: '请求失败，请重试' };
        }

        // mode 1的response格式不一样
        if (mode === 1) {
            if (res.type === 'image') {
                const imgMessage = new ChatMessage(
                    {
                        type: 4,
                        mediaInfo: new MediaInfo({ mediaType: "image", mediaUrl: res.file_url }),
                        rawContent: res.description
                    });
                newMessages[messages.length - 1] = imgMessage;
            } else if (res.type === 'text') {
                newMessages[messages.length - 1].rawContent = res.content;
            } else if (res.type === 'video') {
                const videoMessage = new ChatMessage(
                    {
                        type: 5,
                        mediaInfo: new MediaInfo({ mediaType: "video", mediaUrl: res.file_url }),
                        rawContent: res.description
                    });
                newMessages[messages.length - 1] = videoMessage;
            } else if (res.type === 'error') {
                newMessages[messages.length - 1].rawContent = res.content;
            }
        }

        // setChatList(newMessages);
        chatAgentInfo.workInfo.chatList = newMessages;
        setChatAgentInfo(cloneDeep(chatAgentInfo));
        const chatAgentInfoBackup = cloneDeep(chatAgentInfo);
        chatAgentInfoBackup.userName = userInfoStore.userInfoData.name || '';
        updateUserWork(chatAgentInfoBackup);
        setIsFetching(false);
    }

    //发送消息
    const handleSend = async (userInput: string = '') => {
        console.log('handleSend in AIChat - userInput:', userInput);
        if (isFetching || userInput.trim() === "") return;
        const userMessage = new ChatMessage({ type: 3, rawContent: userInput });
        // gpt response可能不是raw message，有可能返回图像
        let gptMessage = new ChatMessage({ type: 1, rawContent: "" });
        // const newMessages = [...chatList, userMessage, gptMessage];
        const backUpChatList = cloneDeep(chatAgentInfo.workInfo.chatList);
        const newMessages = [...chatAgentInfo.workInfo.chatList, userMessage, gptMessage];
        // setChatList(newMessages);
        chatAgentInfo.workInfo.chatList = newMessages;
        setChatAgentInfo(cloneDeep(chatAgentInfo));
        // ttsStore.clearAudioSegments();
        setInputValue("");
        setIsFetching(true);  // 开始获取数据
        ttsStore.setUserNeedPause(false);

        const stream: boolean = chatAgentInfo.workInfo.isStream;
        let systemPrompt: string = '';
        const tools: string = chatAgentInfo.workInfo.chatTools;

        if (chatAgentInfo.workInfo.systemPrompt) {
            systemPrompt = chatAgentInfo.workInfo.systemPrompt
        }

        const requestParams = LLMCallRequestData.genLLMCallParams(
            userInput + chatDocContent,
            userInfoStore.userInfoData.id,
            userInfoStore.userInfoData.name,
            stream,
            // chatList,
            backUpChatList,
            systemPrompt,
            tools,
            modelVendor,
            model,
        )
        console.log('requestParams:', requestParams);

        setChatDocContent('');

        if (stream) {
            sendStreamCall(requestParams, newMessages);
        } else {
            sendNonStreamCall(requestParams, newMessages);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (event.isComposing || event.keyCode === 229) {
                // 在拼音输入法中，按下回车只插入字母，不发送  
                event.preventDefault(); // 阻止默认行为  
            } else {
                // 处理中未使用拼音输入法回车，发送消息  
                event.preventDefault(); // 阻止默认行为  
                handleSend(inputValue);
            }
        }
    };
    const hasEnoughCharacters = (responseTextBuffer: string) => {
        // return responseTextBuffer.length >= 10 ? true : false;
        return endMarkArr.some((endMark) => responseTextBuffer.includes(endMark)); // 判断是否有句子结束
    }

    const textToSpeech = async (text: string, cancelToken: CancelTokenSource | null = null) => {
        const ttsParams = {
            text: text,
            // 默认 灿灿
            voice_type: chatAgentInfo.workInfo.ttsVoice ? chatAgentInfo.workInfo.ttsVoice : "BV700_streaming",
        }
        try {
            const res: any = await service.post('/tts', ttsParams, { cancelToken: cancelToken?.token });
            if (res.status === 'success' && res.data !== null) {
                const audioSegment = res.data;
                if (isComponentMounted.current) {
                    if (ttsStore.userNeedPause) return;
                    ttsStore.addAudioSegment(audioSegment);
                }
            }
        } catch (error) {
            console.log('error:', error);
        }
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                if (e.target) {
                    const fileContent = e.target.result as string; // The content is now a string  
                    setChatDocContent(fileContent);
                }
            };

            if (file.type === 'text/plain') {
                reader.readAsText(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                // If it's a DOCX file, we'll use Mammoth to convert it to plain text  
                const arrayBuffer = await file.arrayBuffer();
                mammoth.extractRawText({ arrayBuffer: arrayBuffer })
                    .then(result => {
                        setChatDocContent(result.value);
                    })
                    .catch(err => console.error('Error reading DOCX file:', err));
            }
            // 重置文件输入的值  
            event.target.value = '';
        }
    };

    // 添加useEffect以处理自动滚动
    useEffect(() => {
        if (chatContainerRef.current) {
            const { current: container } = chatContainerRef;
            // 使用scrollTo方法进行平滑滚动
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth' // 这个设置能使滚动行为变得平滑
            });
        }
    }, [chatAgentInfo?.workInfo?.chatList]);

    useEffect(() => {
        const playAudio = () => {
            if (ttsStore.audioSegments.length > 0) {
                audioRef.current = new Audio(`data:audio/mp3;base64,${ttsStore.audioSegments[0]}`);
                audioRef.current.play();
                ttsStore.setPlaying(true);
                audioRef.current.onended = () => {
                    ttsStore.removeAudioSegment();
                    ttsStore.setPlaying(false);
                };
            }
        };

        if (!ttsStore.isPlaying && ttsStore.audioSegments.length > 0 && isComponentMounted.current) {
            playAudio(); // only start a new audio when there is no audio currently playing
        }
    }, [ttsStore.audioSegments.length, ttsStore.isPlaying]);

    useEffect(() => {
        if (ttsStore.userNeedPause) {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
                ttsStore.resetTTSStore();
                ttsStore.setPlaying(false);
            }
        }
    }, [ttsStore.userNeedPause])

    useEffect(() => {
        setIsModifyAgentConfigOpen(false);
    }, [chatAgentInfo])

    useEffect(() => {
        isComponentMounted.current = true;

        return () => {
            isComponentMounted.current = false;
            // 在组件卸载时取消请求  
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel();
            }
            // 组件卸载时，停止播放音频，清空音频片段，重置TTS状态，此时在生成的音频不要插入到音频片段中，如果llm请求还在进行，也要停止，不再拼接str，并且重置isFetching状态，并且把当前的chatList保存到chatAgentInfo中，然后更新到后端
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
            ttsStore.resetTTSStore();
            setIsFetching(false);
            const chatAgentInfoBackup = cloneDeep(chatAgentInfo);
            chatAgentInfoBackup.userName = userInfoStore.userInfoData.name || '';
            updateUserWork(chatAgentInfoBackup);
        }
    }, [chatAgentInfo.id]);

    return (
        <>
            <div className={styles.chatBox}>
                <div className={styles.modelSelectBox}>
                    <Select
                        suffixIcon={<img src={DropdownArrowIcon} />}
                        className={styles.modelSelect}
                        value={selectedModelName}
                        onChange={handleModelChange}
                        dropdownStyle={{ zIndex: 9999, width: '20rem' }}
                    >
                        {modelList.map((option) => (
                            <Select.Option key={option.model} value={option.name}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={styles.headerTextBox}>
                    <div className={styles.headerText}>
                        {chatAgentInfo.workInfo?.headerText || '你好，我是你的智能助手'}
                    </div>
                </div>
                <div
                    className={styles.aiChat}
                    ref={chatContainerRef}
                >
                    {chatAgentInfo.workInfo?.chatList.length === 0 && (
                        <>
                            {chatAgentInfo.workInfo?.presetMessages.map((item: any, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className={styles.presetQuestion}
                                        onClick={() => {
                                            handleSend(item.rawContent);
                                        }}
                                    >
                                        <div>{item.rawContent}</div>
                                        <div>
                                            <img
                                                src={FingerClickIcon}
                                                className={styles.fingerClickIcon}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                    {chatAgentInfo.workInfo?.chatList.map((item: any, index: number) => {
                        return (
                            <ChatBubble
                                key={index}
                                item={item}
                                role="gpt"
                                scene='playground'
                                //如果是最后一个消息，并且ttsStore的isPlaying为true，则显示正在播放的图标
                                isThisMsgPlaying={index === chatAgentInfo.workInfo.chatList.length - 1 &&
                                    chatAgentInfo.workInfo.isStream &&
                                    (ttsStore.isPlaying || isFetching && chatAgentInfo.workInfo.shouldDoTts && !ttsStore.userNeedPause)}
                                isStream={chatAgentInfo.workInfo.isStream}
                                ttsVoice={chatAgentInfo.workInfo.ttsVoice}
                                gptAvatar={chatAgentInfo.workType === "playground_agent" ? chatAgentInfo.workInfo.agentAvatar : ""}
                            />
                        )
                    }
                    )}
                </div>
                <div className={styles.inputAreaBox}>
                    {
                        chatDocContent && <div className={styles.tipDoc}>
                            引用文件
                            <img
                                src={DeleteIcon}
                                onClick={() => setChatDocContent('')}
                            />
                        </div>
                    }
                    <div className={`${styles.box}`}>
                        <div className={styles.inputBox}>
                            <textarea
                                className={styles.inputArea}
                                placeholder="输入消息..."
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                        </div >
                    </div>
                    <div className={styles.bottomBox}>
                        <div className={styles.onlineSearchBox}>
                            {/* <Switch
                            checked={searchUsingNetwork}
                            onChange={() => setSearchUsingNetwork(!searchUsingNetwork)}
                        />
                        <div className={styles.onlineSearchText}>联网搜索</div> */}
                        </div>
                        <div className={styles.operationBox}>
                            <div className={styles.link}>
                                <input
                                    type="file"
                                    accept=".txt, .docx"
                                    style={{ display: 'none' }}
                                    ref={docFileInputRef}
                                    onChange={handleFileChange}
                                />
                                <img
                                    src={LinkIcon}
                                    onClick={
                                        () => {
                                            if (docFileInputRef.current) { docFileInputRef.current.click() }
                                        }}
                                />
                            </div>
                            <div className={styles.audioBtn}>
                                <SpeechToText
                                    setInputMessage={setInputValue}
                                    inputMessage={inputValue}
                                />
                            </div>
                            <div className={styles.sendBtn}>
                                <img
                                    src={inputValue.trim() === '' ? SendDisableIcon : SendAbleIcon}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSend(inputValue);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {userInfoStore.userInfoData.userRole === 1 &&
                    <div
                        className={styles.teacherGenIcon}
                        onClick={() => setIsGenContentModalOpen(true)}
                    >
                        <img src={TeacherGenIcon} />
                    </div>}

                <GenContentModal
                    isGenContentModalOpen={isGenContentModalOpen}
                    currentItem={chatAgentInfo}
                    setIsGenContentModalOpen={setIsGenContentModalOpen}
                />
                {chatAgentInfo && !isModifyAgentConfigOpen && chatAgentInfo.workType === "playground_agent" && !chatAgentInfo.workInfo.isPresetAgent && (
                    <div className={styles.modifyAgentConfigBtn} onClick={() => setIsModifyAgentConfigOpen(true)}>
                        <img src={chatAgentInfo.workInfo.agentAvatar || IntelligentAgentIcon} alt="intelligent agent" />
                    </div>
                )}
            </div>
            {chatAgentInfo && isModifyAgentConfigOpen && chatAgentInfo.workType === "playground_agent" && !chatAgentInfo.workInfo.isPresetAgent && (
                <ModifyAgentConfig
                    modifyItem={chatAgentInfo}
                    setIsModifyAgentConfigOpen={setIsModifyAgentConfigOpen}
                    updateChatAgentInfo={getChatAgentInfo}
                />
            )}
        </>
    )
}

export default observer(ChatPage);
