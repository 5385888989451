import React, { useState, useEffect } from 'react';
import styles from './modifyAgentConfig.module.less';
import { ChatMessage } from '@/base/ChatMessage';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import UploadAgentAvatarIcon from '@/assets/img/playground/chat_upload_avatar.svg';
import AddPresetQuestionIcon from '@/assets/img/playground/add_preset_question.svg';
import DelPresetQuestionIcon from '@/assets/img/playground/del_preset_question.svg';
import { Select, message, Checkbox } from 'antd';
import { updateUserWork } from "@/api/userWork";
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import CancelIcon from '@/assets/img/cuowu.svg';
import mammoth from 'mammoth';
import ChatData from '@/base/PlaygroundData/ChatData';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { getAllUserWorks } from '@/api/userWork';
import DataDefinition from "@/base/DataDefinition";

const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

interface ModifyAgentConfigProps {
    modifyItem: ChatData,
    setIsModifyAgentConfigOpen: (isOpen: boolean) => void,
    updateChatAgentInfo: () => void,
}

const ModifyAgentConfig: React.FC<ModifyAgentConfigProps> = ({
    modifyItem,
    setIsModifyAgentConfigOpen,
    updateChatAgentInfo,
}) => {
    const { userInfoStore, playgroundStore } = useStores();
    const [agentName, setAgentName] = useState(modifyItem.workInfo?.agentName || '');
    const [agentSystemPrompt, setAgentSystemPrompt] = useState('');
    const [agentAvatar, setAgentAvatar] = useState(modifyItem.workInfo?.agentAvatar || '');
    const [headerText, setHeaderText] = useState(modifyItem.workInfo?.headerText || '');
    const [presetQuestions, setPresetQuestions] = useState<ChatMessage[]>(modifyItem.workInfo?.presetMessages || []);
    const [ttsVoice, setTtsVoice] = useState(modifyItem.workInfo?.ttsVoice || 'BV700_streaming');
    const [isStream, setIsStream] = useState(modifyItem.workInfo?.isStream || false);
    const [knowledgeBaseDoc, setKnowledgeBaseDoc] = useState(modifyItem.workInfo?.knowledgeBaseDoc || '');
    const [docContent, setDocContent] = useState('');
    const [selectedTools, setSelectedTools] = useState<string[]>(modifyItem.workInfo?.chatTools.split(',') || []);

    useEffect(() => {
        setAgentName(modifyItem.workInfo?.agentName || '');
        setAgentAvatar(modifyItem.workInfo?.agentAvatar || '');
        setHeaderText(modifyItem.workInfo?.headerText || '');
        setPresetQuestions(modifyItem.workInfo?.presetMessages || []);
        setTtsVoice(modifyItem.workInfo?.ttsVoice || 'BV700_streaming');
        setIsStream(modifyItem.workInfo?.isStream || false);
        setKnowledgeBaseDoc(modifyItem.workInfo?.knowledgeBaseDoc || '');
        //根据knowledgeBaseDoc获取文档内容
        if (modifyItem.workInfo?.systemPrompt) {
            getDocumentContent(modifyItem.workInfo?.systemPrompt);
            getSystemPrompt(modifyItem.workInfo?.systemPrompt);
        }
        setSelectedTools(modifyItem.workInfo?.chatTools.split(',') || []);
        console.log(modifyItem.workInfo?.chatTools.split(','));

    }, [modifyItem])

    const getDocumentContent = (text: string) => {
        const splitPrompt = text.split('<DOC_START>');
        if (splitPrompt.length > 1) {
            const extractedDocContent = splitPrompt[1].trim();
            setDocContent(extractedDocContent);
        } else {
            setDocContent('');
        }
    }

    const getSystemPrompt = (text: string) => {
        const splitPrompt = text.split('<DOC_START>');
        if (splitPrompt.length > 1) {
            const extractedSystemPrompt = splitPrompt[0].trim();
            setAgentSystemPrompt(extractedSystemPrompt);
        } else {
            setAgentSystemPrompt(text);
        }
    }

    const handleAddPresetQuestion = () => {
        const newPresetQuestion = new ChatMessage({});
        setPresetQuestions([...presetQuestions, newPresetQuestion]);
    }

    const handleDelPresetQuestion = (id: number) => {
        const newPresetQuestions = presetQuestions.filter((question) => question.id !== id);
        setPresetQuestions(newPresetQuestions);
    }

    const updateAgent = async () => {
        if (!agentName) {
            message.error('请输入智能体名称');
            return;
        }
        const markedDocContent = docContent ? `<DOC_START>\n${docContent}` : '';
        const newWorkInfo = (new AIChatRegionContent({
            systemPrompt: agentSystemPrompt + markedDocContent,
            agentName: agentName,
            agentAvatar: agentAvatar,
            headerText: headerText,
            presetMessages: presetQuestions,
            ttsVoice: ttsVoice,
            chatList: modifyItem.workInfo?.chatList || [],
            isStream: isStream,
            knowledgeBaseDoc: knowledgeBaseDoc,
            chatTools: selectedTools.join(','),
        }));
        const newAgent = new ChatData({
            id: modifyItem.id,
            md5: modifyItem.md5,
            userId: modifyItem.userId,
            userName: modifyItem.userName,
            workType: modifyItem.workType,
            workInfo: newWorkInfo,
            createTime: modifyItem.createTime,
        });
        try {
            const res = await updateUserWork(newAgent);
            if (res.status === 'success') {
                message.success('修改成功');
                updateChatAgentInfo();
                getAllAgents();
                onCancel();
            }
        } catch (error) {
            message.error('修改失败');
        }
    }

    //获取所有的智能体
    const getAllAgents = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_agent");
            if (res.status === "success") {
                const agentItems = res.data.map((item: any) => new ChatData(DataDefinition.toCamelCaseObj(item)));
                if (agentItems.length > 0) {
                    playgroundStore.setUserAgentList(agentItems.reverse());
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onCancel = () => {
        setIsModifyAgentConfigOpen(false);
        setAgentName('');
        setAgentSystemPrompt('');
        setAgentAvatar('');
        setHeaderText('');
        setPresetQuestions([]);
        setTtsVoice('BV700_streaming');
        setIsStream(false);
        setKnowledgeBaseDoc('');
        setDocContent('');
        setSelectedTools([]);
    }

    const readFileContent = async (file: any) => {
        // 判断文件类型  
        const fileType = file.type;

        if (fileType === 'text/plain') {
            // 处理 .txt 文件  
            const text = await file.text();
            setDocContent(text);
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // 处理 .docx 文件  
            const arrayBuffer = await file.arrayBuffer();
            const { value } = await mammoth.extractRawText({ arrayBuffer });
            setDocContent(value);
        } else {
            message.error('文件类型不支持');
        }
    };

    //置空知识库doc
    const handleClearDoc = () => {
        setKnowledgeBaseDoc('');
        setDocContent('');
    };

    const propsUpload = {
        isDoc: true,
    };

    return (
        <div className={styles.modifyAgentConfigBox}>
            <div className={styles.cancelIcon} onClick={onCancel}>
                <img src={CancelIcon} />
            </div>
            <div className={styles.content}>
                <div className={styles.mainTitle}>修改AI智能体配置</div>
                <div className={styles.agentAvatarBox}>
                    <img src={agentAvatar} className={styles.agentAvatar} />
                    <div className={styles.uploadAvatar}>
                        <UploadMedia
                            successCallBack={(url) => {
                                setAgentAvatar(url);
                            }}
                            icon={UploadAgentAvatarIcon}
                            accept_str="image/*"
                            dir="avatar"
                            isMultiple={false}
                        />
                    </div>
                </div>
                <div className={styles.agentNameBox}>
                    <div className={styles.agentNameTitle}>智能体名称</div>
                    <input
                        type="text"
                        className={styles.agentNameInput}
                        placeholder="请输入智能体名称"
                        value={agentName}
                        onChange={(e) => setAgentName(e.target.value)}
                    />
                </div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>系统提示词</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入系统提示词"
                        value={agentSystemPrompt}
                        onChange={(e) => setAgentSystemPrompt(e.target.value)}
                    />
                </div>
                <div className={styles.uploadDocBox}>
                    <div className={styles.uploadDocTitle}>
                        <div className={styles.uploadDocTitleText}>知识库文档</div>
                        <UploadMedia
                            successCallBack={(url, file) => {
                                readFileContent(file);
                                setKnowledgeBaseDoc(url);
                            }}
                            btn_text="上传文档"
                            accept_str=".txt,.docx,.doc"
                            dir="docs"
                            isMultiple={false}
                            uploadProps={propsUpload}
                        />
                        <div className={styles.clearDoc} onClick={handleClearDoc}>
                            清空
                        </div>
                    </div>

                    <div className={styles.docContent}>
                        {docContent}
                    </div>
                </div>
                <div className={styles.agentNameBox}>
                    <div className={styles.agentNameTitle}>头部标题</div>
                    <input
                        type="text"
                        className={styles.agentNameInput}
                        placeholder="请输入头部标题"
                        value={headerText}
                        onChange={(e) => setHeaderText(e.target.value)}
                    />
                </div>
                <div className={styles.addPresetQuestionsBox}>
                    <div className={styles.addPresetQuestionsTitle}>
                        <span>添加预设问题</span>
                        <div
                            className={styles.addPresetQuestionsBtn}
                            onClick={handleAddPresetQuestion}
                        >
                            <img src={AddPresetQuestionIcon} />
                            添加
                        </div>
                    </div>
                    <div className={styles.presetQuestionsList}>
                        {presetQuestions.map((question, index) => (
                            <div key={question.id} className={styles.presetQuestion}>
                                <div className={styles.presetQuestionTitle}>预设问题{index + 1}</div>
                                <div className={styles.inputBox}>
                                    <input
                                        type="text"
                                        className={styles.presetQuestionInput}
                                        placeholder="请输入问题"
                                        value={question.rawContent}
                                        onChange={(e) => {
                                            question.rawContent = e.target.value;
                                            setPresetQuestions([...presetQuestions]);
                                        }}
                                    />
                                    <div
                                        className={styles.delPresetQuestion}
                                        onClick={() => handleDelPresetQuestion(question.id)}
                                    >
                                        <img src={DelPresetQuestionIcon} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.voiceSelectBox}>
                    <div className={styles.voiceSelectTitle}>AI回答音色</div>
                    <Select
                        value={ttsVoice}
                        style={{ width: 120 }}
                        onChange={(value) => setTtsVoice(value)}
                        className={styles.voiceSelect}
                    >
                        {voiceList.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={styles.streamBox}>
                    <div className={styles.streamTitle}>流式回答</div>
                    <Select
                        value={isStream}
                        onChange={(value) => setIsStream(value)}
                        className={styles.streamSelect}
                    >
                        <Select.Option value={true}>
                            是
                        </Select.Option>
                        <Select.Option value={false}>
                            否
                        </Select.Option>
                    </Select>
                </div>
            </div>
            <div className={styles.createAgentBtnBox}>
                <div
                    className={styles.createAgentBtn}
                    onClick={updateAgent}
                >
                    保存修改
                </div>
            </div>
        </div>
    )
}

export default observer(ModifyAgentConfig);
