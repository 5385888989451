import DataDefinition from "./DataDefinition";
import { ElementData, ElementContent } from "./ElementData/ElementData";
import TextRegionContent from "./ElementData/TextRegionContent";
import ImageRegionContent from "./ElementData/ImageRegionContent";
import ButtonRegionContent from "./ElementData/ButtonRegionContent";
import AdvancedButtonRegionContent from "./ElementData/AdvancedButtonRegionContent";
import BtnTextGroupRegionContent from "./ElementData/BtnTextGroupRegionContent";
import BtnGenericGroupRegionContent from "./ElementData/BtnGenericGroupRegionContent";
import VideoRegionContent from "./ElementData/VideoRegionContent";
import AudioRegionContent from "./ElementData/AudioRegionContent";
import SunoGenContent from "./ElementData/SunoGenContent";
import AIChatRegionContent from "./ElementData/AIChatRegionContent";
import TeacherWordsRegionContent from "./ElementData/TeacherWordsRegionContent";
import LineRegionContent from "./ElementData/LineRegionContent";
import RatingRegionContent from "./ElementData/RatingRegionContent"
import CodeRegionContent from "./ElementData/CodeRegionContent"
import QRCodeRegionContent from "./ElementData/QRCodeRegionContent";
import ShareWithSoundRegionContent from "./ElementData/ShareWithSoundRegionContent";
import { DragClassifyRegionContent, DragItem, CategoryItem } from "./ElementData/DragClassifyRegionContent"
import ShowCodeRegionContent from "./ElementData/ShowCodeRegionContent"
import FlashCardRegionContent from "./ElementData/FlashCardRegionContent"
import { LineConnectorRegionContent, FirstGroupItem, SecondGroupItem } from "./ElementData/LineConnectorRegionContent";
import { BlockData, BlockTraceData } from "./BlockData";
import { SectionData } from "./SectionData";
import { ChapterData } from "./ChapterData";
import { CourseData } from "./CourseData";
import { cloneDeep } from "lodash";
import HiddenChatContent from "./ElementData/HiddenChatContent";

// gen*开头的一般都有validityCheck
// caller already called toCamelCase
export function genObjectFromJSON<T>(data: any, cls: new (...args: any[]) => T): T | null {
    //console.log("genObjectFromJSON -- data", data, cls.name);
    if (data === null) {
        //console.log("genObjectFromJSON -- return null");
        return null;
    }
    const instance: any = new cls();
    //console.log("genObjectFromJSON -- instance -- right after ctor", instance);

    // TODO: add a dirty check here, may need to remove
    // check if data is a string type
    if (typeof data === 'string') {
        return instance;
    }


    // 获取所有字段并设置其值
    for (const key of Object.keys(data)) {
        const fieldType = Reflect.getMetadata('design:type', instance, key);
        //console.log("genObjectFromJSON -- key, fieldType", key, fieldType);

        // 递归处理嵌套类型
        if (Array.isArray(data[key])) {
            if (data[key].length > 0) {
                // 现在elementType直接是构造函数了
                const elementType = Reflect.getMetadata('array_elment', instance, key);
                //console.log("genObjectFromJSON -- enum array", key, elementType);
                // 我们特殊标记的元数据
                if (!elementType) {
                    instance[key] = data[key];
                } else if (elementType === ElementData) {
                    instance[key] = data[key].map((item: any) => genElementDatafromJSON(item));
                } else {
                    // 其他类型
                    instance[key] = data[key].map((item: any) => genObjectFromJSON(item, elementType));
                }
            }
        } else if (fieldType && typeof fieldType === 'function' && fieldType !== Object) {
            if(fieldType === ElementData) {
                instance[key] = genElementDatafromJSON(data[key]);
            } else {
                instance[key] = genObjectFromJSON(data[key], fieldType);
            }
            //}
        } else {
            // 基础类型字段直接赋值
            instance[key] = data[key];
        }
    }

    // return之前需要做一次validityCheck
    instance.validityCheck();
    return instance;
};

// caller already called toCamelCase
export function genElementDatafromJSON(data: any): ElementData<ElementContent> {
    //console.log("# genElementDatafromJSON -- origin", data_copy);
    //console.log("# genElementDatafromJSON", data);
    // 生成对应的ElementContent对象
    let content: ElementContent;
    let instance: ElementData<ElementContent>;
    switch (data.type) {
        case 1:
            // 这是一个特殊的Element
            content = genObjectFromJSON<HiddenChatContent>(data.content, HiddenChatContent)!;
            data.content = content;
            instance = new ElementData<HiddenChatContent>(data);
            instance.validityCheck();
            return instance;
        case 101:
            content = genObjectFromJSON<TextRegionContent>(data.content, TextRegionContent)!;
            data.content = content;
            instance = new ElementData<TextRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 102:
            content = genObjectFromJSON<ImageRegionContent>(data.content, ImageRegionContent)!;
            data.content = content;
            instance = new ElementData<ImageRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 103:
            content = genObjectFromJSON<ButtonRegionContent>(data.content, ButtonRegionContent)!;
            data.content = content;
            instance = new ElementData<ButtonRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 104:
            content = genObjectFromJSON<AdvancedButtonRegionContent>(data.content, AdvancedButtonRegionContent)!;
            data.content = content;
            instance = new ElementData<AdvancedButtonRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 105:
            content = genObjectFromJSON<BtnTextGroupRegionContent>(data.content, BtnTextGroupRegionContent)!;
            data.content = content;
            instance = new ElementData<BtnTextGroupRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 106:
            content = genObjectFromJSON<BtnGenericGroupRegionContent>(data.content, BtnGenericGroupRegionContent)!;
            data.content = content;
            instance = new ElementData<BtnGenericGroupRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 107:
            content = genObjectFromJSON<VideoRegionContent>(data.content, VideoRegionContent)!;
            data.content = content;
            instance = new ElementData<VideoRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 108:
            content = genObjectFromJSON<AudioRegionContent>(data.content, AudioRegionContent)!;
            data.content = content;
            instance = new ElementData<AudioRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 109:
            content = genObjectFromJSON<SunoGenContent>(data.content, SunoGenContent)!;
            data.content = content;
            instance = new ElementData<SunoGenContent>(data);
            instance.validityCheck();
            return instance;
        case 110:
            content = genObjectFromJSON<AIChatRegionContent>(data.content, AIChatRegionContent)!;
            data.content = content;
            instance = new ElementData<AIChatRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 111:
            content = genObjectFromJSON<TeacherWordsRegionContent>(data.content, TeacherWordsRegionContent)!;
            data.content = content;
            instance = new ElementData<TeacherWordsRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 113:
            content = genObjectFromJSON<LineRegionContent>(data.content, LineRegionContent)!;
            data.content = content;
            instance = new ElementData<LineRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 114:
            content = genObjectFromJSON<RatingRegionContent>(data.content, RatingRegionContent)!;
            data.content = content;
            instance = new ElementData<RatingRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 115:
            content = genObjectFromJSON<CodeRegionContent>(data.content, CodeRegionContent)!;
            data.content = content;
            instance = new ElementData<CodeRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 117:
            content = genObjectFromJSON<QRCodeRegionContent>(data.content, QRCodeRegionContent)!;
            data.content = content;
            instance = new ElementData<QRCodeRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 118:
            content = genObjectFromJSON<DragClassifyRegionContent>(data.content, DragClassifyRegionContent)!;
            data.content = content;
            instance = new ElementData<DragClassifyRegionContent>(data);
            instance.validityCheck();
            console.log("instance", instance, data);
            // throw new Error('We Stop here -- DragClassifyRegionContent');
            return instance;
        case 119:
            content = genObjectFromJSON<ShowCodeRegionContent>(data.content, ShowCodeRegionContent)!;
            data.content = content;
            instance = new ElementData<ShowCodeRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 120:
            content = genObjectFromJSON<FlashCardRegionContent>(data.content, FlashCardRegionContent)!;
            data.content = content;
            instance = new ElementData<FlashCardRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 121:
            content = genObjectFromJSON<LineConnectorRegionContent>(data.content, LineConnectorRegionContent)!;
            data.content = content;
            instance = new ElementData<LineConnectorRegionContent>(data);
            instance.validityCheck();
            return instance;
        case 122:
            content = genObjectFromJSON<ShareWithSoundRegionContent>(data.content, ShareWithSoundRegionContent)!;
            data.content = content;
            instance = new ElementData<ShareWithSoundRegionContent>(data);
            instance.validityCheck();
            return instance;
        default:
            console.error("Unknown element type", data);
            throw new Error('Unknown element type');
    }
};


export function genBlockDatafromJSON(data: any): BlockData {
    if(!data) {
        console.error("genBlockDatafromJSON -- data is null");
    }
    data = DataDefinition.toCamelCaseObj(data);
    const blockInfo: ElementData<ElementContent>[] = [];
    if (data && data.blockInfo) {
        data.blockInfo.forEach((elementData: any) => {
            blockInfo.push(genElementDatafromJSON(elementData));
        });
    }
    data.blockInfo = blockInfo;
    const instance = new BlockData(data);
    instance.validityCheck();
    return instance;
};

export function genBlockTraceDatafromJSON(data: any): BlockTraceData {
    const data_copy: any = cloneDeep(data);
    //console.log("genBlockTraceDatafromJSON -- origin", data_copy);
    data = DataDefinition.toCamelCaseObj(data);
    const traceInfo: ElementData<ElementContent>[] = [];
    data.traceInfo.forEach((elementData: any) => {
        traceInfo.push(genElementDatafromJSON(elementData));
    });
    data.traceInfo = traceInfo;
    const instance = new BlockTraceData(data);
    instance.validityCheck();
    return instance;
};

export function genSectionDatafromJSON(data: any): SectionData {
    data = DataDefinition.toCamelCaseObj(data);
    const blks: BlockData[] = [];
    data.blks.forEach((blkData: any) => {
        blks.push(genBlockDatafromJSON(blkData));
    });
    data.blks = blks;
    const instance = new SectionData(data);
    instance.validityCheck();
    return instance;
};

export function genChapterDatafromJSON(data: any): ChapterData {
    data = DataDefinition.toCamelCaseObj(data);
    const sections: SectionData[] = [];
    data.sections.forEach((sectionData: any) => {
        sections.push(genSectionDatafromJSON(sectionData));
    });
    data.sections = sections;
    const instance = new ChapterData(data);
    instance.validityCheck();
    return instance;
};

export function genCourseDatafromJSON(data: any): CourseData {
    data = DataDefinition.toCamelCaseObj(data);
    const chapters: ChapterData[] = [];
    data.chapters.forEach((chapterData: any) => {
        chapters.push(genChapterDatafromJSON(chapterData));
    });
    data.chapters = chapters;
    const instance = new CourseData(data);
    instance.validityCheck();
    return instance;
};

export function initElementContentFromTemplate<T extends ElementContent>(
    template: any,
    classType: new (...args: any[]) => T,
    positionX: number,
    positionY: number,
    maxZIndex: number
): T {
    const instance = genObjectFromJSON(template, classType) as T;
    if (instance) {
        instance.positionX = positionX;
        instance.positionY = positionY;
        instance.zIndex = maxZIndex;
    }
    return instance;
}




