import React, { useEffect, useRef, useState } from 'react';
import styles from "./bindWeChatQRCode.module.less";
import { useMediaQuery } from 'react-responsive';
import { userInfoStore } from '@/store/userInfoStore';
import VerifyMobile from '../VerifyMobile/VerifyMobile';

declare global {
    interface Window {
        WxLogin: any;
    }
}

const BindWeChatQRCode: React.FC = () => {
    let wxLoginObj = useRef(null);
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isPad = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1279px)' });
    const isPC = useMediaQuery({ query: '(min-width: 1280px)' });
    const [isVerifyCodeValid, setIsVerifyCodeValid] = useState(false);

    useEffect(() => {
        let padCss = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g'
        let pcCss = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDMwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g'

        if (!isVerifyCodeValid) {
            return;
        }
        // 这个 useEffect 在组件挂载时执行  
        wxLoginObj.current = new window.WxLogin({
            self_redirect: true,
            id: 'wx_bind_container',  // 这里是你想要放置二维码的 DOM 元素的 ID  
            appid: import.meta.env.VITE_WECHAT_AIBAO_APP_ID,
            scope: 'snsapi_login',
            redirect_uri: encodeURIComponent(`${import.meta.env.VITE_FE_URL}/bind_wechat_redirect/${userInfoStore.userInfoData.id}`), // 这里是你的回调地址
            state: Math.ceil(Math.random() * 1000), // 可用的状态信息, 在验证后返回  
            style: 'black', // 可以添加样式  
            href: isPad ? padCss : pcCss // 可以添加自定义链接  
        });

        return () => {
            // 清理以防止内存泄漏  
            wxLoginObj.current = null;
        }
    }, [isPad, isPC, isVerifyCodeValid]);
    return (
        <div className={styles.content}>
            <div className={styles.settingItem}>
                <div className={styles.stepTitle}>1.请先验证手机号码</div>
                <VerifyMobile
                    userPhoneNum={userInfoStore.userInfoData.phone}
                    verifySuccessCallback={() => setIsVerifyCodeValid(true)}
                />
                {isVerifyCodeValid && <>
                    <div className={styles.stepTitle}>2.绑定微信</div>
                    <div id="wx_bind_container" className={styles.wxLoginContainer}></div>
                </>}
            </div>
        </div>
    )
}

export default BindWeChatQRCode;


