import styles from './layouts.module.less'
import { useState } from 'react';
import { Outlet } from 'react-router-dom'
import { Layout, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { useLocation } from 'react-router-dom';
import UserPng from '@/assets/img/user.png'
import UserProfileMenu from '@/components/UserProfileMenu/UserProfileMenu';
import ClassroomIcon from '@/assets/img/menu_classroom.svg';
import PlaygroundIcon from '@/assets/img/menu_playground.svg';
import CommunityIcon from '@/assets/img/menu_community.svg';
import CoinsIcon from "@/assets/img/user_center/coins_icon.svg";
import DiamondIcon from "@/assets/img/user_center/diamond_icon.svg";
import RobotLogo from '@/assets/img/robot_logo.svg';
import MenuItem from './MenuItem';

const { Header } = Layout;

const items: MenuItem[] = [
    {
        label: '课堂',
        key: 'Classroom',
        path: '/classroom',
        icon: <img src={ClassroomIcon} alt="Classroom" />,
    },
    {
        label: '游乐场',
        key: 'Playground',
        icon: <img src={PlaygroundIcon} alt="Playground" />,
        path: '/playground',
    },
    {
        label: '社区',
        key: 'Community',
        icon: <img src={CommunityIcon} alt="Community" />,
        path: '/community',
    }
]

//找到当前path对应的key
const findKeyInMenu = (items: MenuItem[], path: string) : string => {
    if (path === '/') {
        return 'Classroom';
    }
    for (let index = 0; index < items.length; index++) {
        if (items[index].path === path) {
            return items[index].key;
        }
        if (items[index].children) {
            const key = findKeyInMenu(items[index].children!, path);
            if (key) {
                return key;
            }
        }
    }
    return '';
}

const HeaderLayout = observer(() => {
    const { userInfoStore } = useStores();
    const location = useLocation();
    const currentKey = findKeyInMenu(items, location.pathname);
    const [current, setCurrent] = useState(currentKey || '');
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const closeTooltip = () => {
        setTooltipVisible(false);
    };

    return (
        <Layout className={styles.layout}>
            <Header className={styles.head}>
                <div className={styles.topBarLogo}>
                    <img src={RobotLogo} alt="艾宝AI" />
                    <span>艾宝AI</span>
                </div>
                <div className={styles.rightArea}>
                    <div className={styles.userAssets}>
                        <div className={styles.assetsItem}>
                            <img src={CoinsIcon} alt="金币" />
                            <span>{userInfoStore.userAssetData.numCoins}</span>
                        </div>
                        {
                            // 钻石功能暂时关闭
                            false &&
                            <div className={styles.assetsItem}>
                                <img src={DiamondIcon} alt="钻石" />
                                <span>{userInfoStore.userAssetData.numDiamonds}</span>
                            </div>
                        }
                    </div>
                    <Tooltip
                        title={<UserProfileMenu closeTooltip={closeTooltip} setCurrent={setCurrent} />}
                        trigger='click'
                        color='#ffffff'
                        open={tooltipVisible}
                        onOpenChange={(visible) => setTooltipVisible(visible)}
                    >
                        <div className={styles.userInfo}>
                            <div className={styles.profile}>
                                <img src={userInfoStore.userInfoData.avatarUrl ? userInfoStore.userInfoData.avatarUrl  : UserPng} alt="user" />
                            </div>
                            <div className={styles.userName}>
                                {userInfoStore.userInfoData.name || userInfoStore.userInfoData.name}
                            </div>
                        </div>

                    </Tooltip>
                </div>
            </Header>
            <Outlet></Outlet>
        </Layout>
    )
})
export default HeaderLayout;
