import DataDefinition from "../DataDefinition";

class ChatAgentInfo extends DataDefinition {
    systemPrompt: string;
    headerText: string;
    agentAvatar: string;
    presetMessage: string;
    shareDescription: string;

    constructor({
        systemPrompt = '',
        headerText = '',
        agentAvatar = '',
        presetMessage = '',
        shareDescription = ''
    }: Partial<ChatAgentInfo> = {}) {
        super();
        this.allowedFields = ['systemPrompt', 'headerText', 'agentAvatar', 'presetMessage', 'shareDescription'];
        this.systemPrompt = systemPrompt;
        this.headerText = headerText;
        this.agentAvatar = agentAvatar;
        this.presetMessage = presetMessage;
        this.shareDescription = shareDescription;
    }
}

export default ChatAgentInfo;