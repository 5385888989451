import {ElementContent} from './ElementData';

class CodeRegionContent extends ElementContent {
    backgroundColor: string;
    code: string;
    visible: boolean;
    showGameBtn: boolean;
    showCodeBtn: boolean;
    showDownloadBtn: boolean;
    showShareBtn: boolean;
    elementMode: string;
    
    constructor({
        positionX = 0,
        positionY = 0,
        width = 600,
        height = 500,
        backgroundColor = "#ffffff",
        code = "",
        visible = true,
        showGameBtn = true,
        showCodeBtn = true,
        showDownloadBtn = true,
        showShareBtn = true,
        elementMode = "normal"
    }: Partial<CodeRegionContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'backgroundColor', 'code', 'visible', 'showGameBtn', 'showCodeBtn', 
            'showDownloadBtn', 'showShareBtn', 'elementMode'];
        this.backgroundColor = backgroundColor;
        this.code = code;
        this.visible = visible;
        this.showGameBtn = showGameBtn;
        this.showCodeBtn = showCodeBtn;
        this.showDownloadBtn = showDownloadBtn;
        this.showShareBtn = showShareBtn;
        this.elementMode = elementMode
    }

    getReadableContent(): string {
        return "代码如下：" + this.code;
    }
};

export default CodeRegionContent;