import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styles from '../common/element.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import TextPlayableRegion from '../TextRegion/TextPlayableRegion';
import ButtonRegion from './ButtonRegion';
import ImageRegion from '../ImageRegion/ImageRegion';
import SoundPlayingIcon from '@/assets/img/sound_playing_icon.svg';
import PlayTTSIcon from '@/assets/img/play_tts.svg';
import PauseTTSIcon from '@/assets/img/pause_tts.svg';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import MultimediaAPI from '@/api/multimedia';
import useCommonFunction from '@/hooks/useCommonFunction';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { throttle } from 'lodash';
//import mixpanel from 'mixpanel-browser';
import { ElementData } from '@/base/ElementData/ElementData';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import TextRegionContent from '@/base/ElementData/TextRegionContent';

interface AdvancedButtonRegionProps extends IntrinsicElementProps<AdvancedButtonRegionContent> {
    handleContentChange?: (elementData: ElementData<TextRegionContent>, newText: string) => void;
}

const AdvancedButtonRegion = forwardRef<any, AdvancedButtonRegionProps>((props, ref) => {
    const btnClickSoundRef = useRef<HTMLAudioElement | null>(null);
    const { audioStore } = useStores();
    const { executeFunc } = useCommonFunction();
    const { elementData, isEditable, handleFocusItem, handleResize, handleDragStop, handleDelete, handleContentChange } = props;

    const { children, visible: initialVisible, onclickFunc, showAudioIcon, autoPlay = false } = elementData?.content;

    const [allAudioUrls, setAllAudioUrls] = useState<{ id: string | number, audioUrl: string }[]>([]);
    const [isHandlePlaying, setIsHandlePlaying] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [elementVisible, setElementVisible] = useState(initialVisible !== undefined ? initialVisible : true);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (elementData?.content?.clickSoundUrl) {
            btnClickSoundRef.current = new Audio(elementData.content.clickSoundUrl);
        }
    }, [elementData?.content?.clickSoundUrl]);

    const imgIcon = () => {
        if (isHandlePlaying) {
            return isHovering ? PauseTTSIcon : SoundPlayingIcon;
        } else {
            return PlayTTSIcon;
        }
    };

    const getAllAudioUrls = async () => {
        const promises = children?.textRegions?.map(async (item) => {
            if (item.content.audioUrl) {
                return { id: item.id, audioUrl: item.content.audioUrl };
            } else {
                const newText = containsVariablePattern(item.content.text) ? rewriteText(item.content.text) : item.content.text;

                const api = new MultimediaAPI();
                try {
                    const res = await api.genVoiceAndUpload({ text: newText }, 'tts');
                    return { id: item.id, audioUrl: res.file_url };
                } catch (error) {
                    console.error('genVoiceAndUpload error:', error);
                    return { id: item.id, audioUrl: null, error: true };
                }
            }
        });

        try {
            if(promises) {
                const results = await Promise.all(promises);
                const successResults = results.filter(item => !item.error) as { id: string | number, audioUrl: string }[];
                setAllAudioUrls(successResults);
                return successResults;
            }
        } catch (error) {
            console.error('Error with Promise.all:', error);
        }
    };

    const playAudioSequentially = async (urls: { id: string | number, audioUrl: string }[]) => {
        setIsHandlePlaying(true);
        for (const urlObj of urls) {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }

            await new Promise<void>((resolve, reject) => {
                const audio = new Audio(urlObj.audioUrl);
                audioRef.current = audio;

                audio.onended = () => resolve();
                audio.onerror = () => {
                    console.error(`Error playing ${urlObj.audioUrl}`);
                    resolve(); // Continue to the next one
                };
                audio.play().catch(reject);
            });
        }
        setIsHandlePlaying(false);
        audioRef.current = null;
    };

    const handlePlay = async () => {
        audioStore.setCurrentAudioEleId(elementData.id);

        if (autoPlay) {
            if (allAudioUrls.length === 0) {
                setIsHandlePlaying(true);
                const urls = await getAllAudioUrls();
                if ((urls ?? []).length > 0) {
                    if (urls && urls.length > 0) {
                        await playAudioSequentially([urls[0]]);
                    }
                }
            } else {
                if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current = null;
                }
                await playAudioSequentially([allAudioUrls[0]]);
            }
        } else {
            if (allAudioUrls.length === 0) {
                const urls = await getAllAudioUrls();
                if (urls) {
                    playAudioSequentially(urls);
                }
            } else {
                if (audioRef.current) {
                    audioRef.current.play();
                    setIsHandlePlaying(true);
                } else {
                    playAudioSequentially(allAudioUrls);
                }
            }
        }
    };

    const handlePause = () => {
        if (audioRef.current) {
            setIsHandlePlaying(false);
            audioRef.current.pause();
        }
    };

    const playClickBtnSound = () => {
        const audio = btnClickSoundRef.current;
        if (audio) {
            audio.currentTime = 0;
            audio.play().then(() => {
                console.log('Audio played successfully');
            }).catch((error) => {
                console.error('Error playing audio:', error);
            });

            setTimeout(() => {
                if (audio.duration > 3) {
                    audio.pause();
                    audio.currentTime = 0;
                }
            }, 1000); // 最多播放两秒
        }
    };

    const showElement = () => {
        setElementVisible(true);
        if (autoPlay) {
            handlePlay();
        }
    };

    useImperativeHandle(ref, () => ({
        showElement,
    }));

    const throttledPlaySound = throttle(playClickBtnSound, 300); // 每3秒最多触发一次

    const clickAdvancedButton = (e: React.MouseEvent) => {
        if (isEditable) {
            handleFocusItem(elementData, e);
        } else {
            // if (import.meta.env.PROD) {
            //     mixpanel.track('AdvancedRegion-handleClick');
            // }

            if (elementData?.content?.clickSoundUrl) {
                throttledPlaySound();
            }

            if (onclickFunc?.funcName === "showSpecifiedElementAndHide") {
                setElementVisible(false);
            }
            console.log('AdvBtn onclickFunc:', onclickFunc);
            executeFunc(onclickFunc?.funcName!, onclickFunc?.funcParams);
        }
    };

    useEffect(() => {
        setElementVisible(initialVisible !== undefined ? initialVisible : true);
    }, [initialVisible]);

    useEffect(() => {
        if (audioStore.currentAudioEleId !== elementData.id) {
            setIsHandlePlaying(false);
            if (audioRef.current) {
                audioRef.current.pause();
            }
        }
    }, [audioStore.currentAudioEleId, elementData.id]);

    return (
        <>
            {elementVisible || isEditable ? (
                <BaseDragableElement
                    elementData={elementData}
                    isEditable={isEditable}
                    handleFocusItem={handleFocusItem}
                    handleResize={handleResize}
                    handleDragStop={handleDragStop}
                    handleDelete={handleDelete}
                >
                    <div
                        style={{
                            ...commonStyle,
                            display: elementVisible ? 'block' : 'none',
                            cursor: onclickFunc?.funcName !== "none" || isEditable ? 'pointer' : '',
                        }}
                        onClick={clickAdvancedButton}
                        className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
                    >
                        {children?.textRegions?.map((textRegion, index) => (
                            <TextPlayableRegion
                                key={textRegion.id}
                                elementData={textRegion}
                                isEditable={isEditable}
                                handleFocusItem={handleFocusItem}
                                handleResize={handleResize}
                                handleDragStop={handleDragStop}
                                handleDelete={handleDelete}
                                handleContentChange={handleContentChange ?? (() => {})}
                                index={index}
                            />
                        ))}

                        {children?.imgRegions?.map((imgRegion, index) => (
                            <ImageRegion
                                key={imgRegion.id}
                                elementData={imgRegion}
                                isEditable={isEditable}
                                handleFocusItem={handleFocusItem}
                                handleResize={handleResize}
                                handleDragStop={handleDragStop}
                                handleDelete={handleDelete}
                                index={index}
                            />
                        ))}

                        {children?.buttons?.map((btn, index) => (
                            <ButtonRegion
                                key={btn.id}
                                elementData={btn}
                                isEditable={isEditable}
                                handleFocusItem={handleFocusItem}
                                handleResize={handleResize}
                                handleDragStop={handleDragStop}
                                handleDelete={handleDelete}
                                index={index}
                            />
                        ))}

                        {showAudioIcon && (
                            <img
                                src={imgIcon()}
                                className={styles.playTTSIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (isHandlePlaying) {
                                        handlePause();
                                    } else {
                                        handlePlay();
                                    }
                                }}
                                onMouseEnter={() => setIsHovering(true)}
                                onMouseLeave={() => setIsHovering(false)}
                            />
                        )}
                    </div>
                </BaseDragableElement>
            ) : null}
        </>
    );
});

AdvancedButtonRegion.displayName = 'AdvancedButtonRegion';

export default observer(AdvancedButtonRegion);
