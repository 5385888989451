import React, { useEffect, useState } from 'react';
import styles from "./aiGenVideo.module.less"
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { createUserWork, getAllUserWorks, deleteUserWork } from '@/api/userWork';
import { useParams } from 'react-router-dom';
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import DataDefinition from "@/base/DataDefinition";
import { useNavigate } from 'react-router-dom';
import ChatPage from './ChatPage/ChatPage';
import { generateMD5 } from '@/utils/utils';
import HiIcon from '@/assets/img/chat_region_hi.svg';
import RecentChatIcon from '@/assets/img/playground/chat_recent_chat.svg';
import MoreOperationsIcon from '@/assets/img/playground/chat_more_operations.svg';
import { Popover } from 'antd';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import ChatData from '@/base/PlaygroundData/ChatData';

const AIGenVideo: React.FC = () => {
    const { md5 } = useParams();
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [chatHistoryList, setChatHistoryList] = useState<ChatData[]>([]);
    const [currentChatInfo, setCurrentChatInfo] = useState<ChatData | null>(null);

    //创建新的默认聊天记录
    const createNewDefaultVideoAgent = async (md5: string) => {
        const newChatAgent = new ChatData({
            md5: md5,
            userId: userInfoStore.userInfoData.id,
            userName: userInfoStore.userInfoData.name,
            workType: "playground_video",
            workInfo: new AIChatRegionContent({
                headerText: `你好${userInfoStore.userInfoData.name ? `,${userInfoStore.userInfoData.name}` : ""}，我是你的AI视频助手`,
                isStream: false,
                chatTools: ''
            }),
        });
        const createRes: any = await createUserWork(newChatAgent);
        if (createRes.status === 'success') {
            createRes.data = new ChatData(DataDefinition.toCamelCaseObj(createRes.data));
            return createRes.data;
        }
        throw new Error("创建新的默认聊天记录失败");
    };
    //获取所有的聊天记录
    const getAllChatHistory = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_video");
            if (res.status === "success") {
                const historyItems = res.data.map((item: any) => new ChatData(DataDefinition.toCamelCaseObj(item)));
                if (historyItems.length > 0) {
                    setChatHistoryList(historyItems.reverse());
                    if (!md5) {
                        navigate(`/playground/video/${historyItems[0].md5}`);
                        setCurrentChatInfo(historyItems[0]);
                    } else {
                        const currentChat = historyItems.find((item: any) => item.md5 === md5);
                        if (currentChat) {
                            setCurrentChatInfo(currentChat);
                        } else {
                            navigate(`/playground/video/${historyItems[0].md5}`);
                            setCurrentChatInfo(historyItems[0]);
                        }
                    }
                } else {
                    const newChat = await createNewDefaultVideoAgent(generateMD5(`${userInfoStore.userInfoData.id}@@default_video0`));
                    setChatHistoryList([newChat]);
                    navigate(`/playground/video/${newChat.md5}`);
                    setCurrentChatInfo(newChat);
                }
            } else if (res.status === "error") {
                const newChat = await createNewDefaultVideoAgent(generateMD5(`${userInfoStore.userInfoData.id}@@default_video0`));
                setChatHistoryList([newChat]);
                navigate(`/playground/video/${newChat.md5}`);
                setCurrentChatInfo(newChat);
            }
        } catch (error) {
            console.error(error);
            // 这里可以选择显示错误提示给用户  
        }
    };

    const handleChatItemClick = (item: any) => {
        setCurrentChatInfo(item);
        navigate(`/playground/video/${item.md5}`); // 更新 URL  
    };

    const createNewChat = async () => {
        //用最后一个聊天记录的ID+1生成md5,因为是到最前面插入，所以是chatHistoryList[0].id+1
        const newChat = await createNewDefaultVideoAgent(generateMD5(`${userInfoStore.userInfoData.id}@@default_video${chatHistoryList[0].id + 1}`));
        setChatHistoryList([newChat, ...chatHistoryList]);
        setCurrentChatInfo(newChat);
        navigate(`/playground/video/${newChat.md5}`);
    }

    //删除聊天或者智能体
    const deleteChatOrAgent = async (userId: number, md5: string, workType: string) => {
        try {
            const res: any = await deleteUserWork(userId, md5);
            if (res.status === "success") {
                const newChatHistoryList = chatHistoryList.filter((item: any) => item.md5 !== md5);
                setChatHistoryList(newChatHistoryList);
                if (currentChatInfo && currentChatInfo.md5 === md5) {
                    if (newChatHistoryList.length > 0) {
                        setCurrentChatInfo(newChatHistoryList[0]);
                        navigate(`/playground/video/${newChatHistoryList[0].md5}`);
                    } else {
                        const newChat = await createNewDefaultVideoAgent(generateMD5(`${userInfoStore.userInfoData.id}@@default_video0`));
                        setChatHistoryList([newChat]);
                        setCurrentChatInfo(newChat);
                        navigate(`/playground/video/${newChat.md5}`);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id === 0) return;
        getAllChatHistory();
    }, [userInfoStore.userInfoData.id]);

    //更多操作的列表组件
    const moreOperationsList = ({ userId, md5, workType }: any) => {
        return (
            <div className={styles.moreOperationsList}>
                <div
                    className={styles.operationItem}
                    onClick={() => deleteChatOrAgent(userId, md5, workType)}
                >
                    删除
                </div>
            </div>
        )
    }
    const handleMoreOperationsClick = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation(); // Prevents the click event from bubbling up  
    }

    return (
        <div className={styles.chatPageBox}>
            <div className={styles.sidebarBox}>
                <div className={styles.contentBox}>
                    <div className={styles.header}>
                        <img src={HiIcon} alt="hi" />
                        <div className={styles.title}>视频生成</div>
                    </div>
                    <div className={styles.historyBox}>
                        <div className={styles.recentChat}>
                            <img src={RecentChatIcon} alt="recent chat" />
                            <div className={styles.recentChatTitle}>
                                视频生成记录
                            </div>
                        </div>
                        <div
                            className={styles.createDefaultChatBtn}
                            onClick={createNewChat}
                        >
                            + 新建聊天
                        </div>
                        <div className={styles.historyList}>
                            {chatHistoryList.map((item: ChatData) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleChatItemClick(item)}
                                    className={styles.historyItem}
                                    style={{
                                        backgroundColor: currentChatInfo && currentChatInfo.id === item.id ? "#EEF2F6" : "",
                                    }}
                                >
                                    {item.workInfo.chatList.length > 0 ? item.workInfo.chatList[0].rawContent : "新对话"}
                                    <Popover
                                        content={moreOperationsList({ userId: item.userId, md5: item.md5, workType: item.workType })}
                                        trigger="click"
                                        placement="bottomRight"
                                        overlayClassName={styles.moreOperationsPopover}
                                    >
                                        <img src={MoreOperationsIcon}
                                            alt="more operations"
                                            className={styles.moreOperations}
                                            onClick={handleMoreOperationsClick}
                                        />
                                    </Popover>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    className={styles.exitBtn}
                >
                    <img
                        src={GoBackIcon}
                        alt="go back"
                        onClick={() => navigate("/playground")}
                    />
                </div>
            </div>
            <div className={styles.chatPage}>
                {currentChatInfo && (<ChatPage chatInfo={currentChatInfo} />)}
            </div>
        </div>
    )
}

export default observer(AIGenVideo);