import DataDefinition from "../DataDefinition";

class ElementContent extends DataDefinition {
    positionX: number;
    positionY: number;
    width: number;
    height: number;
    zIndex: number;

    constructor(positionX: number, positionY: number, width: number, height: number, zIndex: number = 0) {
        super();
        this.positionX = positionX;
        this.positionY = positionY;
        this.width = width;
        this.height = height;
        this.zIndex = zIndex;
    }

    // 具体的Elment需要重载这个方法
    getChildren(): ElementData<ElementContent>[] {
        return [];
    }

    // 具体的Elment需要重载这个方法
    convertToText(): string {
        return "";
    }

    getReadableContent(): string {
        return "";
    }

    static duplicate(srcElment: ElementContent, positionX: number, positionY: number, maxZIndex: number): ElementContent {
        return new ElementContent(positionX, positionY,
            srcElment.width, srcElment.height, srcElment.zIndex);
    }

    static fromJSON<T>(data: any, ctor: new (data: Partial<T>) => T): T {
        return new ctor(data);
    }

};

class ElementData<T> extends DataDefinition {
    id: number;
    type: number;
    content: T;
    isFocus?: boolean;
    childrenLevel?: boolean;

    constructor({
        id = 0,
        type = 0,
        content = {} as T,
        isFocus = false,
        childrenLevel = false
    }: Partial<ElementData<T>> = {}) {
        super();
        this.allowedFields = ['id', 'type', 'content', 'isFocus', 'childrenLevel'];
        this.id = id;
        this.type = type;
        this.content = content;
        this.isFocus = isFocus;
        this.childrenLevel = childrenLevel;
    }
};

export { ElementContent, ElementData };