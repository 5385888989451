import { ElementData, ElementContent } from "./ElementData/ElementData";
import DataDefinition from "./DataDefinition";
import AdvancedButtonRegionContent from "./ElementData/AdvancedButtonRegionContent";
import BtnGenericGroupRegionContent from "./ElementData/BtnGenericGroupRegionContent";
import BtnTextGroupRegionContent from "./ElementData/BtnTextGroupRegionContent";
import { DragClassifyRegionContent } from "./ElementData/DragClassifyRegionContent";
import FlashCardRegionContent from "./ElementData/FlashCardRegionContent";
import { LineConnectorRegionContent } from "./ElementData/LineConnectorRegionContent";
import {PromptSetVarConfig } from "./ElementData/UtilTypes";

class BlockData extends DataDefinition {
    id: number;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ElementData)
    blockInfo: ElementData<ElementContent>[];
    blockType: number;
    extras: Record<string, any>;

    constructor({
        id = 0,
        blockInfo = [],
        blockType = 0,
        extras = {}
    }: Partial<BlockData> = {}) {
        super();
        this.allowedFields = ['id', 'blockInfo', 'blockType', 'extras'];
        this.id = id;
        this.blockInfo = blockInfo;
        this.blockType = blockType;
        this.extras = extras;
        // 因为 extra目前就是一个字段，但是里面可能有postProcessHandlers，所以这里要处理一下
        if(this.extras && this.extras.postProcessHandlers) {
            this.extras.postProcessHandlers = this.extras.postProcessHandlers.map((item: any) => new PromptSetVarConfig(item));
        }   
    }

    getFlattenItems(): ElementData<ElementContent>[] {
        let result: ElementData<ElementContent>[] = [];
        this.blockInfo.forEach((item) => {
            result.push(item);
            if (item.content instanceof AdvancedButtonRegionContent) {
                // type 104
                // merge all children into result
                result = result.concat(item.content.getChildren());
            } else if (item.content instanceof BtnGenericGroupRegionContent) {
                // type 106
                result = result.concat(item.content.getChildren());
            } else if (item.content instanceof BtnTextGroupRegionContent) {
                // type 105
                result = result.concat(item.content.getChildren());
            } else if (item.content instanceof DragClassifyRegionContent) {
                result = result.concat(item.content.getChildren());
            } else if (item.content instanceof FlashCardRegionContent) {
                // type 120
                result = result.concat(item.content.getChildren());
            } else if (item.content instanceof LineConnectorRegionContent) {
                // type 121
                result = result.concat(item.content.getChildren());
            }
        });
        return result;
    }

    getParentItem(currentItem: ElementData<ElementContent>): ElementData<ElementContent> {

        if (!currentItem) {
            return currentItem;
        }

        let parentItem = currentItem;

        this.blockInfo.forEach(item => {
            if (item.type === 104) {
                (item.content as AdvancedButtonRegionContent).children.textRegions.forEach(textItem => {
                    if (textItem.id === currentItem.id) {
                        parentItem = item;
                    }
                });

                (item.content as AdvancedButtonRegionContent).children.imgRegions.forEach(imgItem => {
                    if (imgItem.id === currentItem.id) {
                        parentItem = item;
                        //return item;
                    }
                });

                (item.content as AdvancedButtonRegionContent).children.buttons.forEach(buttonItem => {
                    if (buttonItem.id === currentItem.id) {
                        parentItem = item;
                        //return item;
                    }
                });
            } else if (item.type === 106) {
                (item.content as BtnGenericGroupRegionContent).buttons.forEach(button => {
                    if (button.btnObj?.id === currentItem.id) {
                        parentItem = item;
                    }
                });
            }
        });

        return parentItem;
    }

    getMaxMinZIndex(): [number, number, number, number] {

        // get both max and min zIndex, should also consider children
        let maxZIndex = 0;
        let minZIndex = Number.MAX_SAFE_INTEGER;
        let maxID = -1;
        let minID = -1;

        const flattenedItems = this.getFlattenItems();
        console.log("flattenedItems", flattenedItems);


        flattenedItems.forEach((item) => {
            if (item.content && item.content.zIndex > maxZIndex) {
                maxZIndex = item.content.zIndex;
                maxID = item.id;
            }
            if (item.content && item.content.zIndex < minZIndex) {
                minZIndex = item.content.zIndex;
                minID = item.id;
            }
        });

        return [maxZIndex, maxID, minZIndex, minID];
    }

    getItemById(id: number): ElementData<ElementContent> | undefined {
        const flattenedItems = this.getFlattenItems();
        return flattenedItems.find((item) => item.id === id);
    }

    // 这应该是button的index
    getItemIndex(currentItem: ElementData<ElementContent>): [number, string, number] {
        let result: [number, string, number] = [-1, '', -1];
        this.blockInfo.forEach((item, index) => {
            if (item.id === currentItem.id) {
                result = [index, '', -1];
            } else if (item.content instanceof AdvancedButtonRegionContent) {
                // TODO: 之前这里的条件是 if (item.type === 104 || item.type === 111)
                item.content.children.textRegions?.forEach((textItem, textIndex) => {
                    if (textItem.id === currentItem.id) {
                        result = [index, 'text', textIndex];
                    }
                });

                item.content.children.imgRegions.forEach((imgItem, imgIndex) => {
                    if (imgItem.id === currentItem.id) {
                        result = [index, "img", imgIndex];
                    }
                });

                item.content.children.buttons.forEach((buttonItem, buttonIndex) => {
                    if (buttonItem.id === currentItem.id) {
                        result = [index, "btn", buttonIndex];
                    }
                });
            }
        });
        return result;
    }
}

class BlockTraceData extends DataDefinition {
    id: number;
    userId: number;
    origBlockId: number;
    traceType: number;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ElementData)
    traceInfo: ElementData<ElementContent>[];
    extras: Record<string, any>;
    updateTime: number;

    constructor({
        id = 0,
        userId = 0,
        origBlockId = 0,
        traceType = 0,
        traceInfo = [],
        extras = {},
        updateTime = 0
    }: Partial<BlockTraceData> = {}) {
        super();
        this.allowedFields = ['id', 'userId', 'origBlockId', 'traceType', 'traceInfo', 'extras', 'updateTime'];
        this.id = id;
        this.userId = userId;
        this.origBlockId = origBlockId;
        this.traceType = traceType;
        this.traceInfo = traceInfo;
        this.extras = extras;
        // 因为 extra目前就是一个字段，但是里面可能有postProcessHandlers，所以这里要处理一下
        if(this.extras && this.extras.postProcessHandlers) {
            this.extras.postProcessHandlers = this.extras.postProcessHandlers.map((item: any) => new PromptSetVarConfig(item));
        }   
        this.updateTime = updateTime;
    }

    getReadableContent(): string {
        let content: string = '';
        for(let i = 0; i < this.traceInfo.length; i++) {
            content += this.traceInfo[i].content.getReadableContent() + '\n';
        }
        return content;
    }

};


export { BlockData, BlockTraceData };