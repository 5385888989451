import React, { useState, useEffect } from 'react';
import styles from './genContent.module.less';
import { Modal } from 'antd';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import { genObjectFromJSON } from '@/base/DataObjectGenerator';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import { userInfoStore } from '@/store/userInfoStore';
import { requestLLMCallV2 } from '@/services/requestLLMCall';
import MarkdownWithHighlight from '@/components/MarkdownWithHighlight/MarkdownWithHighlight';
import ChatData from '@/base/PlaygroundData/ChatData';

interface GenContentModalProps {
    setIsGenContentModalOpen: (isOpen: boolean) => void;
    isGenContentModalOpen: boolean;
    currentItem: ChatData;
}

const GenContentModal: React.FC<GenContentModalProps> = ({
    currentItem,
    isGenContentModalOpen,
    setIsGenContentModalOpen
}) => {
    const [reviewSystemPrompt, setReviewSystemPrompt] = useState('');
    const [review, setReview] = useState('');
    const [isReviewLoading, setIsReviewLoading] = useState(false);

    const onCancel = () => {
        setIsGenContentModalOpen(false);
    }


    const generateReview = async () => {
        if (isReviewLoading) return;
        console.log('GenContentModalProps reviewSystemPrompt:', reviewSystemPrompt);
        const chatElement: AIChatRegionContent = genObjectFromJSON<AIChatRegionContent>(currentItem.workInfo, AIChatRegionContent)!;
        console.log('chatElement:', chatElement);
        const elementText = chatElement.getReadableContent();
        let msg: string = '';
        msg = '以下是学生和AI的对话：\n' + elementText;
        msg = msg + '\n请给出你的review。'
        console.log('msg:', msg);

        const systemPrompt = reviewSystemPrompt;

        const requestParams = LLMCallRequestData.genLLMCallParams(
            msg,
            userInfoStore.userInfoData.id,
            userInfoStore.userInfoData.name,
            false,
            [],
            systemPrompt
        );

        setIsReviewLoading(true);

        const res = await requestLLMCallV2(requestParams, 1);
        console.log('res:', res.content);
        setReview(res.content);
        setIsReviewLoading(false);
    }

    return (
        <Modal
            centered
            open={isGenContentModalOpen}
            onCancel={onCancel}
            footer={null}
            className={styles.genContentModal}
        >
            <div className={styles.genContentBox}>
                <div className={styles.content}>
                    {userInfoStore.userInfoData.userRole === 1 && (
                        <div className={styles.agentDescriptionBox}>
                            <div className={styles.agentDescriptionTitle}>系统提示词</div>
                            <textarea
                                className={styles.agentDescriptionInput}
                                placeholder="请输入系统提示词"
                                value={reviewSystemPrompt}
                                onChange={(e) => setReviewSystemPrompt(e.target.value)}
                            />
                        </div>
                    )}
                    <div className={styles.contentBox}>
                        <div className={styles.genContentTitle}>
                            生成的内容
                        </div>

                        <div className={styles.genContent}>
                            <MarkdownWithHighlight content={review} />
                        </div>
                    </div>
                </div>
                <div className={styles.genContentBtnBox}>
                    <div
                        className={styles.genContentBtn}
                        onClick={generateReview}
                    >
                        {isReviewLoading ? '生成中...' : '生成'}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default GenContentModal;
