import { useState, useEffect } from 'react';
import styles from './share.module.less';
import ShareMusic from './ShareMusic/ShareMusic';
import ShareCode from './ShareCode/ShareCode';
import ShareCyberAvatar from './ShareCyberAvatar/ShareCyberAvatar';
import BasicSharePage from './BasicSharePage/BasicSharePage';
import BasicShareWithSound from './BasicShareWithSound/BasicShareWithSound';
import ShareChatAgent from './ShareChatAgent/ShareChatAgent';
import { getShareLink } from '@/api/userLikeObject';
import { getUserWork, getWechatShareAuthInfo } from '@/api/userWork';
import { useParams } from 'react-router-dom';
import SongInfo from '@/base/ShareData/SongInfo';
import CodeInfo from '@/base/ShareData/CodeInfo';
import CyberAvatarInfo from '@/base/ShareData/CyberAvatarInfo';
import BasicSharePageInfo from '@/base/ShareData/BasicSharePageInfo';
import BasicShareWithSoundInfo from '@/base/ShareData/BasicShareWithSoundInfo';
import ChatAgentInfo from '@/base/ShareData/ChatAgentInfo';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

// 定义 ShareInfo 类型  
type ShareInfo = SongInfo | CodeInfo | CyberAvatarInfo | BasicSharePageInfo | BasicShareWithSoundInfo | ChatAgentInfo;

const Share = () => {
    const { userInfoStore } = useStores();
    const [shareType, setShareType] = useState<string>('');
    const [shareInfo, setShareInfo] = useState<ShareInfo | null>(null);
    const { md5 } = useParams();
    const getShareInfo = async () => {
        if (!md5) {
            console.error('md5 is undefined');
            return;
        }
        try {
            let useId = -1;
            if (userInfoStore.userInfoData.id !== 0) {
                useId = userInfoStore.userInfoData.id;
            }
            const res = await getUserWork(md5, useId);
            if (res.status === "success") {
                const userName = res.data.user_name;
                setShareType(res.data.work_type);
                if (res.data.work_type === 'music') {
                    const songInfo = new SongInfo(SongInfo.toCamelCaseObj(res.data.work_info))
                    setShareInfo(songInfo);
                    let desc = '';
                    if (songInfo.authorName) {
                        desc = songInfo.authorName + '刚做了首歌，快来听听吧！';
                    } else {
                        desc = userName + '刚刚做了一首歌，快来听听吧！'
                    }
                    initWeChatShare(songInfo.name, desc, songInfo.coverUrl);
                } else if (res.data.work_type === 'code') {
                    const codeInfo = new CodeInfo(CodeInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(codeInfo);
                    let desc = '';
                    if (codeInfo.authorName) {
                        desc = codeInfo.authorName + '刚刚做了一个游戏，快来看看吧！';
                    } else {
                        desc = userName + '刚刚做了一个游戏，快来看看吧！';
                    }
                    const imgUrl = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/cdc294a75c8469827d3c69d085af9c3f.png';
                    initWeChatShare('好玩的接金币游戏', desc, imgUrl);
                } else if (res.data.work_type === 'cyberAvatar') {
                    const avatarInfo = new CyberAvatarInfo(CyberAvatarInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(avatarInfo);
                    let desc = '';
                    if (avatarInfo.authorName) {
                        desc = avatarInfo.authorName + '刚刚做了头像和签名档，快来看看吧！';
                    } else {
                        desc = userName + '刚刚做了头像和签名档，快来看看吧！';
                    }
                    initWeChatShare(avatarInfo.name, desc, avatarInfo.avatarUrl);
                } else if (res.data.work_type === 'basicPost') {
                    const postInfo = new BasicSharePageInfo(BasicSharePageInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(postInfo);
                    let desc = '分享一篇文章';
                    initWeChatShare(postInfo.title, desc, postInfo.imgUrl);
                } else if (res.data.work_type === 'basicWithSound') {
                    const postInfo = new BasicShareWithSoundInfo(BasicShareWithSoundInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(postInfo);
                    let desc = postInfo.shareDescription || '分享一篇文章';
                    initWeChatShare(postInfo.title, desc, postInfo.imgUrl);
                } else if (res.data.work_type === 'chatAgent') {
                    const chatAgentInfo = new ChatAgentInfo(ChatAgentInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(chatAgentInfo);
                    let desc = `这是${userInfoStore.userInfoData.name}设计的题目，快来挑战一下吧，看能作对几道`
                    const imgUrl = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/3c289ad23b2108620f0b12ad5e41662f.jpeg';
                    initWeChatShare("答题挑战", desc, imgUrl);
                }
            }
        } catch (error) {
            console.error('Error getting song info:', error);
        }
    }

    const initWeChatShare = async (title: string, desc: string, imgUrl: string) => {
        // 获取当前页面的url
        const currentUrl = window.location.href.split("#")[0];

        try {
            const res: any = await getWechatShareAuthInfo(currentUrl);
            if (res.status === 'success') {
                wx.config({
                    debug: false,
                    appId: "wx903d0bd19b6cbd96",
                    timestamp: res.data.timestamp,
                    nonceStr: res.data.noncestr,
                    signature: res.data.signature,
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                });

                wx.ready(() => {
                    wx.updateAppMessageShareData({
                        title: title,
                        desc: desc,
                        link: currentUrl,
                        imgUrl: imgUrl,
                        success: function () {
                            console.log('分享成功');
                        }
                    });
                    wx.updateTimelineShareData({
                        title: title,
                        link: currentUrl,
                        imgUrl: imgUrl,
                        success: function () {
                            console.log('分享成功');
                        }
                    });
                });

            }
        } catch (error) {
            console.error('Error getting wechat signature:', error);
        }
    }

    useEffect(() => {
        getShareInfo();
    }, [md5]);
    return (
        <div className={styles.shareBox}>
            {shareType === 'music' && <ShareMusic songInfo={shareInfo as SongInfo} />}
            {shareType === 'code' && <ShareCode codeInfo={shareInfo as CodeInfo} />}
            {shareType === 'cyberAvatar' && <ShareCyberAvatar cyberAvatarInfo={shareInfo as CyberAvatarInfo} />}
            {shareType === 'basicPost' && <BasicSharePage basicSharePageInfo={shareInfo as BasicSharePageInfo} />}
            {shareType === 'basicWithSound' && <BasicShareWithSound basicShareWithSoundInfo={shareInfo as BasicShareWithSoundInfo} />}
            {shareType === 'chatAgent' && <ShareChatAgent chatAgentInfo={shareInfo as ChatAgentInfo} />}
        </div>
    )
}

export default observer(Share);