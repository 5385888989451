import { ElementContent } from './ElementData';
import TeacherProfile from '/assets/img/teacherProfile.svg'
import TeacherProfileGif from '/assets/img/teacherProfile.gif'
import { ChatMessage } from '../ChatMessage';
import AIChatRegionContent from './AIChatRegionContent';

class TeacherWordsRegionContent extends ElementContent {
    teacherProfile: typeof TeacherProfile;
    teacherProfileGif: typeof TeacherProfileGif;
    // font color
    color: string;
    // font size
    size: number;
    teacherName: string;
    teacherWords: string;
    autoPlay: boolean;
    audioUrl: string;
    systemPrompt: string;
    showSpeedWordNum: number;
    showSpeedTime: number;
    characterPerPage: number;
    doneCallFunc: Record<string, any>;
    // 把chatList换成一个AIChatRegionContent
    shouldDoTts: boolean;
    ttsVoice: string;
    shouldOpenTeacherDialog: boolean;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 900,
        height = 180,
        teacherProfile = TeacherProfile,
        teacherProfileGif = TeacherProfileGif,
        color = "#000000",
        size = 18,
        teacherName = "叶子老师",
        teacherWords = "这是老师的话",
        autoPlay = true,
        audioUrl = "",
        systemPrompt = "",
        showSpeedWordNum = 3,
        showSpeedTime = 400,
        characterPerPage = 75,
        doneCallFunc = {},
        shouldDoTts = true,
        ttsVoice = "BV700_streaming",
        shouldOpenTeacherDialog = true
    }: Partial<TeacherWordsRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'teacherProfile', 'teacherProfileGif', 'color', 'size',
            'teacherName', 'teacherWords', 'autoPlay', 'audioUrl', 'systemPrompt',
            'showSpeedWordNum', 'showSpeedTime', 'characterPerPage', 'doneCallFunc',
            'shouldDoTts', 'ttsVoice', 'shouldOpenTeacherDialog'];
        this.teacherProfile = teacherProfile;
        this.teacherProfileGif = teacherProfileGif;
        this.color = color;
        this.size = size;
        this.teacherName = teacherName;
        this.teacherWords = teacherWords;
        this.autoPlay = autoPlay;
        this.audioUrl = audioUrl;
        this.systemPrompt = systemPrompt;
        this.showSpeedWordNum = showSpeedWordNum;
        this.showSpeedTime = showSpeedTime;
        this.characterPerPage = characterPerPage;
        this.doneCallFunc = doneCallFunc;
        this.shouldDoTts = shouldDoTts;
        this.ttsVoice = ttsVoice;
        this.shouldOpenTeacherDialog = shouldOpenTeacherDialog;
    }

};

export default TeacherWordsRegionContent;