import { Modal, Input, InputNumber, Button, Radio } from "antd";
import { useState, useEffect } from "react";
import UploadMedia from "../UploadMedia/UploadMedia";
import styles from "./createCourseModal.module.less";

interface CreateCourseModalProps {
    isModalOpen: boolean;
    handleCourseOk: () => void;
    handleCourseCancel: () => void;
    inputCourseName: string;
    inputCourseIntro: string;
    courseCoverUrl: string;
    courseVisibleToNormalUsers: boolean;
    courseNameChange: (e: any) => void;
    courseCoverUrlChange: (url: string) => void;
    courseIntroduceChange: (e: any) => void;
    courseVisibleToNormalUsersChange: (value: boolean) => void;
    isCreating: boolean;
};


const CreateCourseModal: React.FC<CreateCourseModalProps> = ({
    isModalOpen,
    handleCourseOk,
    handleCourseCancel,
    inputCourseName,
    inputCourseIntro,
    courseCoverUrl,
    courseVisibleToNormalUsers,
    courseNameChange,
    courseCoverUrlChange,
    courseIntroduceChange,
    courseVisibleToNormalUsersChange,
    isCreating,
}) => {
    const onRadioChange = (e: any) => {
        courseVisibleToNormalUsersChange(e.target.value);
    };

    return (
        <Modal
            title="课程名称"
            open={isModalOpen}
            onOk={() => handleCourseOk()}
            onCancel={handleCourseCancel}
            centered
            okText="确认"
            cancelText="取消"
            confirmLoading={isCreating} // 设置Modal的confirmLoading状态为isCreating的值
        >
            <UploadMedia
                successCallBack={courseCoverUrlChange}
                btn_text={"上传封面"}
                accept_str={"image/*"}
                dir={"images"}
                isMultiple={false}
            />
            <div className={styles.cover}>
                {courseCoverUrl && <img src={courseCoverUrl} alt="" />}
            </div>
            <Input placeholder="请输入课程名称" value={inputCourseName} onChange={courseNameChange}></Input>
            <Input placeholder="请输入课介绍" value={inputCourseIntro} onChange={courseIntroduceChange}></Input>
            <Radio.Group onChange={onRadioChange} value={courseVisibleToNormalUsers}>
                <Radio value={true}>普通用户可见</Radio>
                <Radio value={false}>普通用户不可见</Radio>
            </Radio.Group>
        </Modal>
    )
}

export default CreateCourseModal;