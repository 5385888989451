import { useEffect, useState } from 'react';
import service from '@/services/axios';
import styles from './weChatAuth.module.less';

const WeChatAuth = () => {
    //从父窗口的url中获取utm参数
    const utm = new URLSearchParams(window.parent.location.search).get('utm');
    const [tipText, setTipText] = useState('登录中...');

    const weChatLoginAuth = async (code: string, state: string, utm: string) => {
        try {
            const res: any = await service.get('/wechat_auth', {
                params: {
                    code,
                    state,
                    utm
                }
            });
            if (res.status === 'success') {
                setTipText('登录成功');
                // 通知父窗口redirect  
                window.parent.postMessage({ token: res.data.access_token, redirect: '/' }, '*');
            } else if (res.status === 'error' && res.data === 'User does not exist') {
                setTipText('微信未绑定，请使用其他方式登录');
                // 通知父窗口redirect  
                window.parent.postMessage({ errorText: '微信未绑定，请使用其他方式登录' }, '*');
            } else {
                setTipText('登录失败');
                console.log('res:', res);
            }
        } catch (error) {
            setTipText('登录失败');
            console.log('error:', error);
        }
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code && state) {
            weChatLoginAuth(code, state, utm ? utm : '');
        }
    }, [])

    return (
        <div className={styles.weChatAuthBox}>
            <div className={styles.loadingBox}>
                <div className={styles.loadingText}>
                    {tipText}
                </div>
            </div>
        </div>
    )
}

export default WeChatAuth;