import PropTypes from 'prop-types';
import { InputNumber, Button, Select, Switch } from 'antd';
import styles from './textRegionModifier.module.less'
import TextToSpeech from '@/components/TextToSpeech/TextToSpeech';
import { ElementData } from '@/base/ElementData/ElementData';
import TextRegionContent from '@/base/ElementData/TextRegionContent';
import React from 'react';
import { simplifyFileName } from '@/utils/utils';

interface TextRegionModifierProps {
    focusedItem: ElementData<TextRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};

const TextRegionModifier: React.FC<TextRegionModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {

    const { Option } = Select;

    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>文本内容</div>
                <textarea
                    value={focusedItem.content.text}
                    onChange={e => onInputChange(focusedItem.id, 'text', e.target.value)}
                    className={styles.textarea} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>字体颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.color}
                    onChange={e => onInputChange(focusedItem.id, 'color', e.target.value)}
                    className={styles.colorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>字体大小</div>
                <Select value={focusedItem.content.size}
                    onChange={value => onInputChange(focusedItem.id, 'size', value)}
                    className={styles.fontSizeSelect}>
                    <Option value={14}>14px</Option>
                    <Option value={16}>16px</Option>
                    <Option value={18}>18px</Option>
                    <Option value={20}>20px</Option>
                    <Option value={22}>22px</Option>
                    <Option value={24}>24px</Option>
                    <Option value={26}>26px</Option>
                    <Option value={28}>28px</Option>
                    <Option value={30}>30px</Option>
                    <Option value={32}>32px</Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>字体粗细</div>
                <Select value={focusedItem.content.fontWeight}
                    onChange={value => onInputChange(focusedItem.id, 'fontWeight', value)}
                    className={styles.fontWeightSelect}>
                    <Option value="400">400</Option>
                    <Option value="500">500</Option>
                    <Option value="600">600</Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>行高</div>
                <InputNumber
                    value={focusedItem.content.lineHeight}
                    onChange={value => onInputChange(focusedItem.id, 'lineHeight', value)}
                    className={styles.lineHeightInput} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>边框宽度</div>
                <InputNumber
                    value={focusedItem.content.borderWidth}
                    onChange={value => onInputChange(focusedItem.id, 'borderWidth', value)}
                    className={styles.borderWidthInput} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>边框颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.borderColor}
                    onChange={e => onInputChange(focusedItem.id, 'borderColor', e.target.value)}
                    className={styles.borderColorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>边框圆角</div>
                <InputNumber
                    value={focusedItem.content.borderRadius}
                    onChange={value => onInputChange(focusedItem.id, 'borderRadius', value)}
                    className={styles.borderRadiusInput} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>文字水平对齐方式</div>
                <Select value={focusedItem.content.textAlign}
                    onChange={value => onInputChange(focusedItem.id, 'textAlign', value)}
                    className={styles.textAlignSelect}>
                    <Option value="left">左对齐</Option>
                    <Option value="center">居中</Option>
                    <Option value="right">右对齐</Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>文字垂直对齐方式</div>
                <Select value={focusedItem.content.verticalAlign}
                    onChange={value => onInputChange(focusedItem.id, 'verticalAlign', value)}
                    className={styles.verticalAlignSelect}>
                    <Option value="stretch">默认</Option>
                    <Option value="flex-start">顶部对齐</Option>
                    <Option value="center">居中</Option>
                    <Option value="flex-end">底部对齐</Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>内边距</div>
                <div className={styles.paddingInput}>
                    <div className={styles.paddingTitle}>
                        <label htmlFor="paddingLeft">左</label>
                        <InputNumber
                            value={focusedItem.content.paddingLeft}
                            onChange={value => onInputChange(focusedItem.id, 'paddingLeft', value)}
                            className={styles.paddingLeftInput} />
                    </div>
                    <div className={styles.paddingTitle}>
                        <label htmlFor="paddingRight">右</label>
                        <InputNumber
                            value={focusedItem.content.paddingRight}
                            onChange={value => onInputChange(focusedItem.id, 'paddingRight', value)}
                            className={styles.paddingRightInput} />
                    </div>
                    <div className={styles.paddingTitle}>
                        <label htmlFor="paddingTop">上</label>
                        <InputNumber
                            value={focusedItem.content.paddingTop}
                            onChange={value => onInputChange(focusedItem.id, 'paddingTop', value)}
                            className={styles.paddingTopInput} />
                    </div>
                    <div className={styles.paddingTitle}>
                        <label htmlFor="paddingBottom">下</label>
                        <InputNumber
                            value={focusedItem.content.paddingBottom}
                            onChange={value => onInputChange(focusedItem.id, 'paddingBottom', value)}
                            className={styles.paddingBottomInput} />
                    </div>
                </div>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>学生视角是否展示文本内容</div>
                <Switch
                    checked={focusedItem.content.showText}
                    onChange={value => onInputChange(focusedItem.id, 'showText', value)} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示播放声音按钮</div>
                <Switch
                    checked={focusedItem.content.showAudioIcon}
                    onChange={value => onInputChange(focusedItem.id, 'showAudioIcon', value)} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>播放声音按钮颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.audioIconColor}
                    onChange={e => onInputChange(focusedItem.id, 'audioIconColor', e.target.value)}
                    className={styles.audioIconColorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>播放声音按钮大小</div>
                <Select
                    value={focusedItem.content.audioIconSize}
                    onChange={value => onInputChange(focusedItem.id, 'audioIconSize', value)}
                    className={styles.audioIconSizeSelect}
                >
                    <Option value="large">大</Option>
                    <Option value="medium">中</Option>
                    <Option value="small">小</Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否自动播放</div>
                <Switch
                    checked={focusedItem.content.autoPlay}
                    onChange={value => onInputChange(focusedItem.id, 'autoPlay', value)} />
            </div>
            <div className={styles.modifyItem}>
                <TextToSpeech
                    text={focusedItem.content.text || ''}
                    btnText='生成音频'
                    successCallback={(url) => onInputChange(focusedItem.id, 'audioUrl', url)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>
                    当前是否已有音频链接：{focusedItem.content.audioUrl ? '是' : '否'}
                </div>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>
                    当前音频链接：{simplifyFileName(focusedItem.content.audioUrl)}
                </div>
            </div>
            <div className={styles.modifyItem}>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
        </div>
    )
};

export default TextRegionModifier;