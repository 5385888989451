import { ElementContent } from './ElementData';

class ShareWithSoundRegionContent extends ElementContent {
    imgUrl: string;
    title: string;
    content: string;
    contentVoiceUrl: string;
    shareDescription: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 900,
        height = 500,
        imgUrl = "",
        title = "分享",
        content = "分享内容",
        contentVoiceUrl = "",
        shareDescription = ""
    }: Partial<ShareWithSoundRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'imgUrl', 'title',
            'author', 'content', 'contentVoiceUrl', 'shareDescription'];
        this.imgUrl = imgUrl;
        this.title = title;
        this.content = content;
        this.contentVoiceUrl = contentVoiceUrl;
        this.shareDescription = shareDescription;
    }
};

export default ShareWithSoundRegionContent;