import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Course from "@/pages/Course/Course";
import PlayGround from "@/pages/PlayGround/PlayGround";
import Layouts from "@/pages/Layouts/Layouts";
import HeaderLayout from "@/pages/Layouts/HeaderLayout";
import CheckUserName from "@/pages/CheckUserName/CheckUserName";
import AIChat from "@/pages/PlayGround/AIChat/AIChat";
import ChatPage from "@/pages/PlayGround/AIChat/ChatPage/ChatPage";
import PresetAgents from "@/pages/PlayGround/AIChat/PresetAgents/PresetAgents";
import AIChatWS from "@/pages/PlayGround/AIChatWS/AIChatWS";
import { Navigate } from "react-router-dom";
import Lecture from "@/pages/LessonStudy/Lecture/Lecture";
import BoboMusic from "@/pages/PlayGround/BoboMusic/BoboMusic";
import AIDrawing from "@/pages/PlayGround/AIDrawing/AIDrawing";
import AIGenVideo from "@/pages/PlayGround/AIGenVideo/AIGenVideo";
import Share from "@/pages/Share/Share";
import ShareV2 from "@/pages/Share/ShareV2";
import WeChatAuth from "@/pages/LoginModal/WeChatAuth/WeChatAuth";
import UserCenter from "@/pages/UserCenter/UserCenter";
import MyWorks from "@/pages/UserCenter/MyWorks/MyWorks";
import BlindBox from "@/pages/UserCenter/BlindBox/BlindBox";
import Settings from "@/pages/UserCenter/Settings/Settings";
import Payments from "@/pages/UserCenter/Payments/Payments";
import ProductDetails from "@/pages/ProductDetails/ProductDetails";
import Home from "@/pages/Home/Home";
import PaymentSuccess from "@/pages/ProductDetails/PaymentSuccess/PaymentSuccess";
import LoginV2 from "@/pages/LoginV2/LoginV2";
import BindWeChatRedirect from "@/pages/UserCenter/Settings/BindWeChatRedirect/BindWeChatRedirect";

import { ReactNode } from "react";

function AuthRoute({ children, ...rest }: { children: ReactNode }) {
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    return isLoggedIn ? children : <Navigate to="/home" />;
}

function RootRoute() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<CheckUserName />}>
                    <Route path="/" element={<AuthRoute><Layouts /></AuthRoute>}>
                        <Route index element={<Navigate to="/classroom" />} />
                        <Route path="/classroom" element={<AuthRoute><Course /></AuthRoute>} />
                        <Route path="/playground" element={<AuthRoute><PlayGround /></AuthRoute>} />
                        {/* <Route path="/coursechapter/:courseId" element={<AuthRoute><CourseChapter /></AuthRoute>} /> */}
                        <Route path="/user" element={<AuthRoute><UserCenter /></AuthRoute>} />
                    </Route>
                    {/* <Route path="/playground/ai-chat" element={<AuthRoute><AIChat /></AuthRoute>} /> */}
                    {/* <Route path="/chat/:courseId" element={<AuthRoute><ChatForInfo /></AuthRoute>} /> */}
                    {/* <Route path="/coursestudy/:courseId/:chapterId" element={<AuthRoute><LessonStudy /></AuthRoute>} /> */}
                    <Route path="/coursestudy/:courseId/:chapterId/:sectionId" element={<AuthRoute><Lecture /></AuthRoute>} />
                    <Route path="/playground" element={<HeaderLayout />} >
                        <Route path="/playground/bobo-music" element={<AuthRoute><BoboMusic /></AuthRoute>} />
                        <Route path="/playground/chat" element={<AuthRoute><AIChat /></AuthRoute>} >
                            <Route path="/playground/chat/discover" element={<PresetAgents />} /> 
                            <Route path="/playground/chat/:md5" element={<ChatPage />} />
                        </Route>
                        <Route path="/playground/ai-chat-ws" element={<AuthRoute><AIChatWS /></AuthRoute>} />
                        <Route path="/playground/draw" element={<AuthRoute><AIDrawing /></AuthRoute>} />
                        <Route path="/playground/draw/:md5" element={<AuthRoute><AIDrawing /></AuthRoute>} />
                        <Route path="/playground/video" element={<AuthRoute><AIGenVideo /></AuthRoute>} />
                        <Route path="/playground/video/:md5" element={<AuthRoute><AIGenVideo /></AuthRoute>} />
                    </Route>
                    <Route path="/user" element={<HeaderLayout />} >
                        <Route path="/user/works" element={<AuthRoute><MyWorks /></AuthRoute>} />
                        <Route path="/user/blind-box" element={<AuthRoute><BlindBox /></AuthRoute>} />
                        <Route path="/user/settings" element={<AuthRoute><Settings /></AuthRoute>} />
                        <Route path="/user/payments" element={<AuthRoute><Payments /></AuthRoute>} />
                    </Route>
                </Route>
                <Route path="/share/:md5" element={<Share />} />
                <Route path="/share_v2/:md5" element={<ShareV2 />} />
                <Route path="/wx_auth" element={<WeChatAuth />} />
                <Route path="/home" element={<Home />} />
                <Route path="/product/:productId" element={<ProductDetails />} />
                <Route path="/pay_success" element={<PaymentSuccess />} />
                <Route path="/loginV2" element={<LoginV2 />} />
                <Route path="/bind_wechat_redirect/:userId" element={<BindWeChatRedirect />} />
            </Routes>
        </Router>
    );
}

export default RootRoute;
