import { useState, useEffect } from 'react';
import styles from './userCenter.module.less';
import UserManagementAPI from '@/api/userManagement';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import UserPng from '@/assets/img/user.png';
import GenderFemaleIcon from '@/assets/img/user_center/gender_female.svg';
import GenderMaleIcon from '@/assets/img/user_center/gender_male.svg';
import CoinsIcon from "@/assets/img/user_center/coins_icon.svg";
import DiamondIcon from "@/assets/img/user_center/diamond_icon.svg";
import MedalIcon from "@/assets/img/user_center/medal_icon.svg";
import UserInfoModifierModal from './UserInfoModifierModal/UserInfoModifierModal';
import { useNavigate } from 'react-router-dom';
import userCenterList from './userCenterList';

const UserCenter: React.FC = observer(() => {
    const userAPI = new UserManagementAPI();
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [userName, setUserName] = useState('');
    const [userRealName, setUserRealName] = useState('');
    const [userAvatar, setUserAvatar] = useState('');
    const [userAge, setUserAge] = useState(0);
    const [userGender, setUserGender] = useState('male');
    const [userTagline, setUserTagline] = useState('');
    const [phone, setPhone] = useState('');

    const [coinsNum, setCoinsNum] = useState(0);
    const [diamondNum, setDiamondNum] = useState(0);
    const [medalNum, setMedalNum] = useState(0);

    const [isModifierModalOpen, setIsModifierModalOpen] = useState(false);

    const getUserAssets = async () => {
        try {
            const response = await userAPI.getUserAssets(userInfoStore.userInfoData.id);
            if (response.status === 'success') {
                setCoinsNum(response.data.points || 0);
                setDiamondNum(response.data.stars || 0);
                setMedalNum(response.data.extras?.medals || 0);
            } else {
                setCoinsNum(0);
                setDiamondNum(0);
                setMedalNum(0);
            }
        } catch (error) {
            console.error('Error getting user assets', error)
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id) {
            userInfoStore.refreshUserInfo(userInfoStore.userInfoData.id);
            userInfoStore.refreshUserAssets(userInfoStore.userInfoData.id);
        }
    }, [userInfoStore.userInfoData.id])

    useEffect(() => {
        // 更新用户名
        if (userInfoStore.userInfoData.name) {
            setUserName(userInfoStore.userInfoData.name);
        }
        // 更新用户真实姓名
        if (userInfoStore.userInfoData.realUsername) {
            setUserRealName(userInfoStore.userInfoData.realUsername);
        }
        // 更新用户签名
        if (userInfoStore.userInfoData.tagLine) {
            setUserTagline(userInfoStore.userInfoData.tagLine);
        }
        // 更新用户头像
        if (userInfoStore.userInfoData.avatarUrl) {
            setUserAvatar(userInfoStore.userInfoData.avatarUrl);
        }
        // 更新用户年龄
        if (userInfoStore.userInfoData.age) {
            setUserAge(userInfoStore.userInfoData.age);
        }
        // 更新用户性别
        if (userInfoStore.userInfoData.gender) {
            setUserGender(userInfoStore.userInfoData.gender);
        }
        // 更新用户金币
        if (userInfoStore.userAssetData.numCoins) {
            setCoinsNum(userInfoStore.userAssetData.numCoins);
        }
        // 更新用户钻石
        if (userInfoStore.userAssetData.numDiamonds) {
            setDiamondNum(userInfoStore.userAssetData.numDiamonds);
        }
        // 更新用户勋章
        if (userInfoStore.userAssetData.medals) {
            setMedalNum(userInfoStore.userAssetData.medals.length);
        }
        // 更新用户手机号
        if (userInfoStore.userInfoData.phone) {
            setPhone(userInfoStore.userInfoData.phone);
        }
    }, [userInfoStore.userInfoData.tagLine, userInfoStore.userInfoData.avatarUrl,
    userInfoStore.userInfoData.age, userInfoStore.userInfoData.age,
    userInfoStore.userInfoData.name, userInfoStore.userAssetData.numCoins,
    userInfoStore.userAssetData.numDiamonds, userInfoStore.userAssetData.medals]);


    return (
        <div className={styles.container}>
            <div className={styles.mainBox}>
                <div className={styles.userInfoBox}>
                    <div className={styles.userAvatar}>
                        <img src={userAvatar || UserPng} />
                    </div>
                    <div className={styles.rightBox}>
                        <div className={styles.textRegion}>
                            <div className={styles.userName}>
                                {userName}
                            </div>
                            <div className={styles.realUserName}>
                                ID：{userRealName || phone || ""}
                            </div>
                            <div className={styles.signature}>
                                {userTagline}
                            </div>
                            <div className={styles.ageBox}>
                                <div className={styles.age}>
                                    <div className={styles.genderIcon}>
                                        <img src={userGender === 'male' ? GenderMaleIcon : GenderFemaleIcon} />
                                    </div>
                                    <div className={styles.ageText}>{userAge}岁</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.editBtn} onClick={() => { setIsModifierModalOpen(true) }}>编辑资料</div>
                    </div>
                    <div className={styles.trophy}>
                        <div className={`${styles.trophyItem} ${styles.species}`}>
                            <div className={styles.textBox}>
                                <div className={styles.num}>{coinsNum}</div>
                                <div className={styles.item}>金币</div>
                            </div>
                            <div className={styles.itemIcon}>
                                <img src={CoinsIcon} />
                            </div>
                        </div>
                        <div className={`${styles.trophyItem} ${styles.diamond}`}>
                            <div className={styles.textBox}>
                                <div className={styles.num}>{diamondNum}</div>
                                <div className={styles.item}>钻石</div>
                            </div>
                            <div className={styles.itemIcon}>
                                <img src={DiamondIcon} />
                            </div>
                        </div>
                        <div className={`${styles.trophyItem} ${styles.medal}`}>
                            <div className={styles.textBox}>
                                <div className={styles.num}>{medalNum}</div>
                                <div className={styles.item}>勋章</div>
                            </div>
                            <div className={styles.itemIcon}>
                                <img src={MedalIcon} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.operationBox}>
                    {
                        userCenterList.map((item) => (
                            <div
                                key={item.id}
                                className={styles.operationItem}
                                onClick={() => navigate(item.path)}
                            >
                                <div className={styles.titleBox}>
                                    <div className={styles.title}>{item.title}</div>
                                    <div className={styles.description}>{item.description}</div>
                                </div>
                                <div className={styles.iconBox}>
                                    <img src={item.icon} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                isModifierModalOpen && (
                    <UserInfoModifierModal
                        isModifierModalOpen={isModifierModalOpen}
                        setIsModifierModalOpen={setIsModifierModalOpen}
                    />)
            }
        </div>
    )
})

export default UserCenter;