import { useState, useEffect } from 'react';
import { Drawer, Button, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import service from '@/services/axios';
import styles from './templateFileSelector.module.less';
import cloneDeep from 'lodash/cloneDeep';
import { ChildrenElementContent } from '@/base/ElementData/AdvancedButtonRegionContent';

interface TemplateFileSelectorProps {
    fileSelectorOpen: boolean;
    setFileSelectorOpen: (fileSelectorOpen: boolean) => void;
    afterSelectionCallback: (obj: any) => void;
    templateType: number;
}

const TemplateFileSelector: React.FC<TemplateFileSelectorProps> = ({
    fileSelectorOpen,
    setFileSelectorOpen,
    afterSelectionCallback,
    templateType }) => {
    ChildrenElementContent
    const [templateFileList, setTemplateFileList] = useState<any[]>([]);
    const [templateProps, setTemplateProps] = useState<ChildrenElementContent>(new ChildrenElementContent({
        numTextChildren: 1,
        numImgChildren: 1,
        numBtnChildren: 0,
    }));

    const updateSetting = (fieldName: keyof ChildrenElementContent, value: any) => {
        const newProps = cloneDeep(templateProps);
        newProps.setField(fieldName, value);
        setTemplateProps(newProps);
    }

    useEffect(() => {
        if (fileSelectorOpen && templateType) {
            getTemplateFileList();
        }
    }, [fileSelectorOpen, templateType]);

    const getTemplateFileList = async () => {
        try {
            // 拿到所有templates file
            console.log('call to get template files');
            const res: any = await service.get('/fetch_file_urls', { params: { dir: 'templates' } });
            if (res.status === 'success') {
                // 需要进一步做过滤
                const templateFiles = res.data.files_info;
                let filteredTemplateFiles = [];
                for (let i = 0; i < templateFiles.length; i++) {
                    const tType = parseInt(templateFiles[i].file_url.split('/').pop().split('-')[0], 10);

                    if (tType === templateType) {
                        // 需要从文件名中提前出thumbnail
                        const segs = templateFiles[i].file_url.split('/').pop().split('-');
                        let thumbnail = '';
                        const isAliyun = import.meta.env.VITE_OSS_SITE === "Aliyun";
                        if (!isAliyun)
                            thumbnail = 'https://agi4kids.s3.us-west-2.amazonaws.com/images/' + segs[1] + '.' + segs[2].split('.')[0];
                        else {
                            thumbnail = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/' + segs[1] + '.' + segs[2].split('.')[0];
                        } 
                        const iProp = {
                            fullName: templateFiles[i].file_url,
                            thumbnail: thumbnail,
                            mode: 1
                        };

                        filteredTemplateFiles.push(iProp);
                    }
                }
                setTemplateFileList(filteredTemplateFiles);
                console.log('filteredTemplateFiles', filteredTemplateFiles);
            } else {
                setTemplateFileList([]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    // mode 0 -- new
    // mode 1 -- load from library
    const handleClick = (itemProp: any) => {
        console.log('TemplateFileSelector handleClick', itemProp);
        afterSelectionCallback(itemProp);
        setFileSelectorOpen(false);
        document.body.style.cursor = 'copy';
    };

    const renderTemplateItem = (itemProp: any) => {
        // 现就显示文件名
        return <div key={itemProp.fullName} onClick={() => handleClick(itemProp)}>
            <img src={itemProp.thumbnail} alt="thumbnail" style={{ width: '100px', height: '100px' }} />
            <div>{itemProp.fullName.split('/').pop().substr(0, 16)}</div>

        </div>;
    };

    return (
        <Drawer
            title="选择模板"
            placement="right"
            closable={true}
            onClose={() => setFileSelectorOpen(false)}
            open={fileSelectorOpen}
            width={500}
            className={styles.templateFileSelector}
        >
            <div className={styles.divHorizontalLayout}>
                {
                    templateType === 103 && <div className={styles.uploadMedia}>
                        <Button onClick={() => handleClick({
                            fullName: 'new',
                            mode: 0,
                        })}>
                            新建文本按钮
                        </Button>
                    </div>
                }
                {
                    templateType === 104 && <>
                        <div>
                            <div>
                                <label>文本区域个数</label>
                                <InputNumber
                                    value={templateProps.numTextChildren || 0}
                                    onChange={value => { updateSetting('numTextChildren', value) }}
                                />
                            </div>
                            <div>
                                <label>图片区域个数</label>
                                <InputNumber
                                    value={templateProps.numImgChildren || 0}
                                    onChange={value => { updateSetting('numImgChildren', value) }}
                                />
                            </div>
                            <div>
                                <label>按钮区域个数</label>
                                <InputNumber
                                    value={templateProps.numBtnChildren || 0}
                                    onChange={value => { updateSetting('numBtnChildren', value) }}
                                />
                            </div>
                        </div>
                        <div className={styles.uploadMedia}>
                            <Button onClick={() => handleClick({
                                fullName: 'new',
                                mode: 0,
                                numTxtRegion: templateProps.numTextChildren,
                                numImgRegion: templateProps.numImgChildren,
                                numBtnRegion: templateProps.numBtnChildren,
                            })}>
                                新建高级按钮
                            </Button>
                        </div>
                    </>
                }
                {
                    templateType === 105 && <div className={styles.uploadMedia}>
                        <Button onClick={() => handleClick({
                            fullName: 'new',
                            mode: 0,
                        })}>
                            新建文字按钮组
                        </Button>
                    </div>
                }
                {
                    templateType === 106 && <div className={styles.uploadMedia}>
                        <Button onClick={() => handleClick({
                            fullName: 'new',
                            mode: 0,
                        })}>
                            新建高级按钮组
                        </Button>
                    </div>
                }
            </div>
            <hr />
            <br></br>
            <br></br>
            <div className={styles.fileList}>
                {templateFileList.map(renderTemplateItem)}
            </div>
        </Drawer>
    );
};

export default TemplateFileSelector;