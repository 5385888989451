import service from "@/services/axios";

const ApiPath = {
    GET_SECTION_TRACE_RESET_COUNT:(userId: number, sectionId: number) => `/get_section_trace_reset_count/${userId}/${sectionId}`,
    UPDATE_SECTION_TRACE_RESET_COUNT: '/update_section_trace_reset_count',
}

export const getSectionTraceResetCountApi = (userId: number, sectionId: number): Promise<any> => {
    return service.get(ApiPath.GET_SECTION_TRACE_RESET_COUNT(userId, sectionId));
}

export const updateSectionTraceResetCountApi = (data: any): Promise<any> => {
    return service.put(ApiPath.UPDATE_SECTION_TRACE_RESET_COUNT, data);
}