import React, { useState } from 'react';
import styles from "./settings.module.less";
import { useNavigate } from 'react-router-dom';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import SettingPassword from './SettingPassword/SettingPassword';
import BindWeChatQRCode from './BindWeChatQRCode/BindWeChatQRCode';

const Settings: React.FC = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('password');

    const tabChange = (tab: string) => {
        setActiveTab(tab);
    }

    return (
        <div className={styles.settingsPage}>
            <div className={styles.goBack} onClick={() => navigate('/user')}>
                <img src={GoBackIcon} alt="go back" />
            </div>

            <div className={styles.content}>
                <div className={styles.mainTitle}>设置</div>
                <div className={styles.subTitle}>在这里设置一些信息</div>
                <div className={styles.settingsBox}>
                    <div className={styles.tabs}>
                        <div
                            className={`${styles.tabItem} ${activeTab === 'password' ? styles.activeTabItem : ''}`}
                            onClick={() => tabChange('password')}
                        >
                            设置登录密码
                        </div>
                        <div
                            className={`${styles.tabItem} ${activeTab === 'wechat' ? styles.activeTabItem : ''}`}
                            onClick={() => tabChange('wechat')}
                        >
                            绑定微信
                        </div>
                    </div>
                    {activeTab === 'password' && <SettingPassword />}
                    {activeTab === 'wechat' && <BindWeChatQRCode />}
                </div>

            </div>
        </div>
    )
}

export default Settings;
