import DataDefinition from "./DataDefinition";
import { SectionData } from "./SectionData";

class ChapterData extends DataDefinition {
    id: number;
    name: string;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', SectionData)
    sections: SectionData[];
    extras: Record<string, any>;
    updateTime: number;

    constructor({
        id = 0,
        name = '',
        sections = [],
        extras = {},
        updateTime = 0
    }: Partial<ChapterData> = {}) {
        super();
        this.allowedFields = ['id', 'name', 'sections', 'extras', 'updateTime'];
        this.id = id;
        this.name = name;
        this.sections = sections;
        this.extras = extras;
        this.updateTime = updateTime;
    }
}

export { ChapterData };