import DataDefinition from "../DataDefinition";

class SongInfo extends DataDefinition {
    id: number;
    name: string;
    authorName: string;
    authorId: number;
    coverUrl: string;
    musicUrl: string;
    lyrics: string;
    tags: string;
    likeCount: number;
    playCount: number;
    updateTime: number;

    constructor({
        id = 0,
        name = "",
        authorName = "",
        authorId = 0,
        coverUrl = "",
        musicUrl = "",
        lyrics = "",
        tags = "",
        likeCount = 0,
        playCount = 0,
        updateTime = 0
    }: Partial<SongInfo> = {}) {
        super();
        this.allowedFields = ['id', 'name', 'authorName', 'authorId', 'coverUrl', 'musicUrl', 'lyrics', 'tags', 'likeCount', 'playCount', 'updateTime'];
        this.id = id;
        this.name = name;
        this.authorName = authorName;
        this.authorId = authorId;
        this.coverUrl = coverUrl;
        this.musicUrl = musicUrl;
        this.lyrics = lyrics;
        this.tags = tags;
        this.likeCount = likeCount;
        this.playCount = playCount;
        this.updateTime = updateTime;
    }
}

export default SongInfo;