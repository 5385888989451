import styles from './shareMusic.module.less';
import { useState, useEffect } from 'react';
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import LikeIcon from '@/assets/img/heart_red.svg';
import UnLikeIcon from '@/assets/img/heart_grey.svg';
import ShareIcon from '@/assets/img/share_icon.svg';
import DownloadIcon from '@/assets/img/download_icon.svg';
import { Tooltip, QRCode } from 'antd';
import { getLikeCnt, likeObjectAndUpdate, unlikeObjectAndUpdate, userShareObject } from '@/api/userLikeObject';
import { useMediaQuery } from 'react-responsive';
import SongInfo from '@/base/ShareData/SongInfo';

interface ShareMusicProps {
    songInfo: SongInfo;
}

const ShareMusic: React.FC<ShareMusicProps> = ({ songInfo }) => {
    console.log(songInfo);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [qrUrl, setQrUrl] = useState('');
    const [qrLoading, setQrLoading] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isNotMobile = useMediaQuery({ query: '(min-width: 769px)' });

    const clickLikeIcon = async () => {
        const data = {
            user_id: -1,
            object_id: songInfo?.id,
            object_type: 'music',
            create_time: 0
        }
        if (isLiked) {
            setIsLiked(false);
            const res = await unlikeObjectAndUpdate(data);
            setLikeCount(res.data);
        } else {
            setIsLiked(true);
            const res = await likeObjectAndUpdate(data);
            setLikeCount(res.data);
        }
    }
    const clickShareIcon = async () => {
        setQrLoading(true);
        const data = {
            user_id: -1,
            object_type: "music",
            object_id: songInfo?.id,
            channel: "share",
            extras: {},
            create_time: 0
        };
        try {
            const res = await userShareObject(data);
            if (res.status === 'success') {
                generateShareInfo();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const generateShareInfo = async () => {
        const url = window.location.href;
        setQrUrl(url);
        setQrLoading(false);
    };
    const clickDownloadIcon = async () => {
        try {
            const response = await fetch(songInfo?.musicUrl);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${songInfo?.name}.mp3`; // 或者使用其他格式  
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl); // 释放blob URL  
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    const getLikeCount = async () => {
        try {
            const res = await getLikeCnt({ object_id: songInfo?.id });
            if (res.status === "success") {
                setLikeCount(res.data);
            }
        } catch (error) {
            console.error('Error getting like count:', error);
        }
    }
    useEffect(() => {
        if (songInfo.id) {
            getLikeCount();
        }
    }, [songInfo.id]);
    return (
        <div className={styles.page}>
            {isNotMobile &&
                <div className={styles.tabletDesktopLayout}>
                    <div className={styles.title}>这是{songInfo.authorName}创作的歌曲，快点击播放收听吧</div>
                    <div className={styles.mediumAudioBox}>
                        <div className={styles.playRegion}>
                            <div className={styles.player}>
                                <div className={styles.songCover}>
                                    {songInfo?.coverUrl ? (
                                        <img src={songInfo?.coverUrl} alt="song cover" />
                                    ) : (
                                        <div className={styles.defaultCover}></div>
                                    )}
                                </div>
                                <div className={styles.audio}>
                                    <AudioPlayer
                                        audioSrc={songInfo?.musicUrl}
                                        isPlaying={isPlaying}
                                        setIsPlaying={setIsPlaying}
                                    />
                                </div>
                            </div>
                            <div className={styles.userOperation}>
                                <div className={styles.like} onClick={clickLikeIcon}>
                                    {isLiked ? (
                                        <img src={LikeIcon} alt="" />
                                    ) : (
                                        <img src={UnLikeIcon} alt="" />
                                    )}
                                    {likeCount !== 0 && <div className={styles.likeCount}>{likeCount}</div>}
                                </div>
                                <div className={styles.share} onClick={clickShareIcon}>
                                    <Tooltip
                                        color='white'
                                        trigger='click'
                                        title={<QRCode
                                            value={qrUrl} size={150}
                                            status={qrLoading ? "loading" : "active"} />
                                        }
                                    >
                                        <img src={ShareIcon} alt="" />
                                    </Tooltip>
                                </div>
                                <div className={styles.download} onClick={clickDownloadIcon}>
                                    <img src={DownloadIcon} alt="Download" />
                                </div>
                            </div>
                        </div>
                        <div className={styles.infoRegion}>
                            <div className={styles.songName}>{songInfo?.name}</div>
                            <div className={styles.artist}>{"作者: " + songInfo.authorName}</div>
                            <div className={styles.lyricBox}>
                                <div className={styles.lyric}>
                                    <div className={styles.content}>
                                        {songInfo?.lyrics}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isMobile &&
                <div className={styles.mobileLayout}>
                    <div className={styles.title}>这是{songInfo.authorName}创作的歌曲，快点击播放吧</div>
                    <div className={styles.player}>
                        <div className={styles.songCover}>
                            {songInfo?.coverUrl ? (
                                <img src={songInfo?.coverUrl} alt="song cover" />
                            ) : (
                                <div className={styles.defaultCover}></div>
                            )}
                        </div>
                        <div className={styles.audio}>
                            <AudioPlayer
                                audioSrc={songInfo?.musicUrl}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                            />
                        </div>
                    </div>
                    <div className={styles.userOperation}>
                        <div className={styles.like} onClick={clickLikeIcon}>
                            {isLiked ? (
                                <img src={LikeIcon} alt="" />
                            ) : (
                                <img src={UnLikeIcon} alt="" />
                            )}
                            {likeCount !== 0 && <div className={styles.likeCount}>{likeCount}</div>}
                        </div>
                        {/* <div className={styles.share} onClick={clickShareIcon}>
                            <Tooltip
                                color='white'
                                trigger='click'
                                title={<QRCode
                                    value={qrUrl} size={150}
                                    status={qrLoading ? "loading" : "active"} />
                                }
                            >
                                <img src={ShareIcon} alt="" />
                            </Tooltip>
                        </div> */}
                        {/*<div className={styles.download} onClick={clickDownloadIcon}>
                            <img src={DownloadIcon} alt="Download" />
                        </div>*/}
                    </div>
                    <div className={styles.infoRegion}>
                        <div className={styles.songName}>{songInfo?.name}</div>
                        <div className={styles.artist}>{"作者: " + songInfo.authorName}</div>
                        <div className={styles.lyricBox}>
                            <div className={styles.lyric}>
                                <div className={styles.content}>
                                    {songInfo?.lyrics}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ShareMusic;