import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'antd';

const OrientationChecker: React.FC = () => {
    const [isPortrait, setIsPortrait] = useState(false);
    const checkOrientation = () => {
        const orientationType = window.screen.orientation?.type || (window.innerWidth < window.innerHeight ? 'portrait' : 'landscape');
        setIsPortrait(orientationType.startsWith('portrait'));
    };

    useEffect(() => {
        checkOrientation();

        const handleOrientationChange = () => {
            checkOrientation();
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    return (
        <Modal
            open={isPortrait}
            footer={null}
            centered
            onCancel={() => setIsPortrait(false)}
        >
            <Alert
                message="温馨提示"
                description="为了更好的体验，请使用横屏模式"
                type="warning"
                showIcon
            />
        </Modal>
    );
};

export default OrientationChecker;