import DataDefinition from "../DataDefinition";

class CyberAvatarInfo extends DataDefinition {
    avatarUrl: string;
    name: string;
    tagline: string;
    authorName: string;

    constructor({
        avatarUrl = "",
        name = "",
        tagline = "",
        authorName = "",
    }: Partial<CyberAvatarInfo> = {}) {
        super();
        this.allowedFields = ['avatarUrl', 'name', 'tagline', 'authorName'];
        this.avatarUrl = avatarUrl;
        this.name = name;
        this.tagline = tagline;
        this.authorName = authorName;
    }
}

export default CyberAvatarInfo;