import service from "@/services/axios";

const ApiPath = {
    GET_USER_WORK: '/user_work_v2',
    CREATE_USER_WORK: '/user_work_v2',
    UPDATE_USER_WORK: '/user_work_v2',
    GET_ALL_USER_WORKS: '/user_work_all',
    DELETE_USER_WORK: (userId: number, md5: string) => `/user_work_v2/${userId}/${md5}`,
    GET_WECHAT_SHARE_AUTH_INFO: '/generate_wechat_share_data',
}

export const getUserWork = (md5: string, userId: number): Promise<any> => {
    return service.get(ApiPath.GET_USER_WORK, { params: { md5, user_id: userId } });
}

export const createUserWork = (data: any): Promise<any> => {
    return service.post(ApiPath.CREATE_USER_WORK, data);
}

export const updateUserWork = (data: any): Promise<any> => {
    return service.put(ApiPath.UPDATE_USER_WORK, data);
}

export const getAllUserWorks = (userId: number, workType?: string): Promise<any> => {
    const params: { user_id: number; work_type?: string } = {
        user_id: userId, // user_id is required  
    };

    if (workType) {
        params.work_type = workType; // Add work_type if provided  
    }

    return service.get(ApiPath.GET_ALL_USER_WORKS, { params });
} 

export const deleteUserWork = (userId: number, md5: string): Promise<any> => {
    return service.delete(ApiPath.DELETE_USER_WORK(userId, md5));
}

export const getWechatShareAuthInfo = (url: string): Promise<any> => {
    return service.get(ApiPath.GET_WECHAT_SHARE_AUTH_INFO, { params: { url } });
}