import { genUUIDv4 } from '@/utils/id_generator';
import StudyReportIcon from "@/assets/img/user_center/study_report_icon.svg";
import TaskCenterIcon from "@/assets/img/user_center/task_center_icon.svg";
import MyWorksIcon from "@/assets/img/user_center/my_works_icon.svg";
import TeacherImageIcon from "@/assets/img/user_center/teacher_image_icon.svg";
import StudyPlanIcon from "@/assets/img/user_center/study_plan_icon.svg";
import SettingsIcon from "@/assets/img/user_center/settings_icon.svg";
import BlindBoxIcon from "@/assets/img/user_center/blind_box_icon.png";
import PaymentIcon from "@/assets/img/user_center/payment_icon.svg";

const userCenterList = [
    // {
    //     id: genUUIDv4(),
    //     title: "学习报告",
    //     description: "查看你的学习报告",
    //     icon: StudyReportIcon,
    //     path: "",
    // },
    // {
    //     id: genUUIDv4(),
    //     title: "任务中心",
    //     description: "做任务奖励金币哦",
    //     icon: TaskCenterIcon,
    //     path: "",
    // },
    {
        id: genUUIDv4(),
        title: "我的作品",
        description: "查看自己完成的作品",
        icon: MyWorksIcon,
        path: "/user/works",
    },
    {
        id: genUUIDv4(),
        title: "盲盒游戏",
        description: "开启属于你的盲盒",
        icon: BlindBoxIcon,
        path: "/user/blind-box",
    },
    {
        id: genUUIDv4(),
        title: "设置密码/绑定微信",
        description: "修改密码或绑定微信",
        icon: SettingsIcon,
        path: "/user/settings",
    },
    {
        id: genUUIDv4(),
        title: "订单",
        description: "查看订单",
        icon: PaymentIcon,
        path: "/user/payments",
    }
    // {
    //     id: genUUIDv4(),
    //     title: "学习进度规划",
    //     description: "定制个人学习计划",
    //     icon: StudyPlanIcon,
    //     path: "",
    // },
    // {
    //     id: genUUIDv4(),
    //     title: "功能设置",
    //     description: "基础功能设置",
    //     icon: SettingsIcon,
    //     path: "",
    // },
]

export default userCenterList;