import DataDefinition from "./DataDefinition";
import {ChapterData}  from "./ChapterData";

class CourseData extends DataDefinition {
    id: number;
    name: string;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ChapterData)
    chapters: ChapterData[];
    extras: Record<string, any>;
    updateTime: number;

    constructor({
        id = 0,
        name = '',
        chapters = [],
        extras = {},
        updateTime = 0
    }: Partial<CourseData> = {}) {
        super();
        this.allowedFields = ['id', 'name', 'chapters', 'extras', 'updateTime'];
        this.id = id;
        this.name = name;
        this.chapters = chapters;
        this.extras = extras;
        this.updateTime = updateTime;
    }
}

export { CourseData };