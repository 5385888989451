import React, { useState } from 'react';
import styles from './verifyMobile.module.less';
import { Modal, Button, message } from 'antd';
import service from '@/services/axios';

interface VerifyMobileProps {
    userPhoneNum: string;
    verifySuccessCallback: () => void;
}

const VerifyMobile: React.FC<VerifyMobileProps> = ({
    userPhoneNum,
    verifySuccessCallback,
}) => {
    const [phoneError, setPhoneError] = useState("");
    const [verifyCode, setVerifyCode] = useState("");
    const [isCodeBtnDisabled, setCodeBtnDisabled] = useState(false);// 获取验证码按钮是否禁用
    const [countdown, setCountdown] = useState(60); // 再次获取验证码倒计时秒数
    const [verifyCodeError, setVerifyCodeError] = useState("");

    const validatePhone = () => {
        if (!/^1[3456789]\d{9}$/.test(userPhoneNum)) {
            setPhoneError("手机号格式不正确");
            return false;
        }
        setPhoneError("");
        return true;
    };

    const validateVerifyCode = () => {
        if (!verifyCode.trim()) {
            setVerifyCodeError("验证码不能为空");
            return false;
        }
        setVerifyCodeError("");
        return true;
    };

    const getVerifyCode = async () => {
        const isPhoneValid = validatePhone();
        if (!isPhoneValid) {
            return;
        }
        setCodeBtnDisabled(true);
        const data = {
            sms_account: "7e7ce07d",
            phone_number: userPhoneNum,
            sign: "神元力量",
            template_id: "ST_810192b9",
        };
        //获取验证码
        try {
            const res: any = await service.post("/send_sms_verify_code", data);
            if (res.status === "success") {
                message.success("验证码已发送");

                setCountdown(60);

                const timer = setInterval(() => {
                    setCountdown((prev) => {
                        if (prev === 1) {
                            clearInterval(timer);
                            setCodeBtnDisabled(false);
                            return 60; // 重置为初始倒计时秒数  
                        }
                        return prev - 1;
                    });
                }, 1000);
            } else {
                message.error("获取验证码失败，请稍后再试");
                setCodeBtnDisabled(false);
            }
        } catch (error) {
            console.warn(error);
            message.error("获取验证码异常，请稍后再试");
            setCodeBtnDisabled(false);
        }
    };

    //验证验证码
    const handleVerifyCode = async () => {
        const isPhoneValid = validatePhone();
        const isVerifyCodeValid = validateVerifyCode();

        if (!isPhoneValid || !isVerifyCodeValid) {
            return;
        }
        const data = {
            sms_account: "7e7ce07d",
            phone_number: userPhoneNum,
            code: verifyCode,
            scene: "注册验证码",
            type: 1
        };
        try {
            const res: any = await service.post("/check_sms_verify_code", data);
            if (res.status === "success" && res.data.Result === "0") {
                message.success("验证成功");
                if (verifySuccessCallback) {
                    verifySuccessCallback();
                }
            } else {
                message.error("验证码错误，请重新输入");
            }
        } catch (error) {
            console.warn(error);
            message.error("登录异常，请稍后再试");
        }
    };
    return (
        <div className={styles.phoneBox}>
            <div className={styles.inputBox}>
                <input
                    type="text"
                    placeholder="请输入手机号"
                    className={styles.input}
                    value={userPhoneNum}
                    readOnly
                    onBlur={validatePhone}
                />
                {phoneError && <div className={styles.errorText}>{phoneError}</div>}
            </div>
            <div className={styles.getCodeBox}>
                <input
                    type="text"
                    placeholder="请输入验证码"
                    className={styles.input}
                    value={verifyCode}
                    onChange={(e) => setVerifyCode(e.target.value)}
                    onBlur={validateVerifyCode}
                />
                <div className={styles.getCodeBtnBox}>
                    <Button
                        className={`${styles.getCodeBtn} ${userPhoneNum.trim() !== "" && /^1[3456789]\d{9}$/.test(userPhoneNum) ? styles.getCodeBtnActive : ""}`}
                        onClick={getVerifyCode}
                        disabled={isCodeBtnDisabled}
                    >
                        {isCodeBtnDisabled ? `${countdown}s后重新获取` : '获取验证码'}
                    </Button>
                </div>
                {verifyCodeError && <div className={styles.errorText}>{verifyCodeError}</div>}
            </div>

            <Button
                className={`${styles.loginBtn} ${userPhoneNum.trim() !== "" && /^1[3456789]\d{9}$/.test(userPhoneNum) && verifyCode.trim() !== "" ? styles.loginBtnActive : ""}`}
                onClick={handleVerifyCode}>
                确定
            </Button>
        </div>
    )
}

export default VerifyMobile;