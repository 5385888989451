import styles from './teacherWordsRegion.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import MessageIcon from '/assets/img/teacher_message.svg';
import MultimediaAPI from '@/api/multimedia';
import SoundPlayingIcon from '@/assets/img/sound_playing_icon.svg';
import PlayTTSV2Icon from '@/assets/img/new_speaker.svg';
import PauseTTSIcon from '@/assets/img/pause_tts.svg';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import useCommonFunction from '@/hooks/useCommonFunction';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { strTrimLeft } from '@/utils/utils';
import TeacherWordsRegionContent from '@/base/ElementData/TeacherWordsRegionContent';
import { convertUrl } from '@/utils/utils';

interface TeacherWordsRegionProps extends IntrinsicElementProps<TeacherWordsRegionContent> {
    AIChatRef: React.RefObject<any>;
};

const TeacherWordsRegion = forwardRef<any, TeacherWordsRegionProps>((props, ref) => {
    const { executeFunc } = useCommonFunction();
    const { elementData, isEditable, handleFocusItem, handleResize,
        handleDragStop, handleDelete, AIChatRef } = props;
    const [isPlaying, setIsPlaying] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [displayedText, setDisplayedText] = useState('');
    const [audioSrc, setAudioSrc] = useState(convertUrl(elementData.content.audioUrl) || '');
    const [currentTime, setCurrentTime] = useState(0);
    const [textIntervalId, setTextIntervalId] = useState<number | null>(null);
    const [teacherSpeakingRate, setTeacherSpeakingRate] = useState(Number(localStorage.getItem('teacherSpeakingRate')) || 1);

    const sentenceIndexRef = useRef(0);
    const textIndexRef = useRef(0);
    let showSpeedWordNum = elementData.content.showSpeedWordNum || 3;
    let showSpeedTime = elementData.content.showSpeedTime || 500;
    let characterPerPage = elementData.content.characterPerPage || 75;

    let text = containsVariablePattern(elementData.content.teacherWords) ? rewriteText(elementData.content.teacherWords) : elementData.content.teacherWords;
    const genSentencesRef = (text: string) => {
        const rawSentences = text.split(/(?<=[。？！])|\n{2}/) || [text];
        const processedSentences: string[] = [];

        rawSentences.forEach(sentence => {
            // 如果句子长度超出限制，就进行截断
            if (sentence.length > characterPerPage) {
                processedSentences.push(sentence.slice(0, characterPerPage - 1)); // 添加截断后的部分  
                processedSentences.push(sentence.slice(characterPerPage - 1)); // 剩余的句子  
            } else {
                processedSentences.push(sentence); // 添加剩余的部分  
            }
        });

        return processedSentences; // 返回处理后的句子
    }
    const sentencesRef = useRef(genSentencesRef(text));
    const currentSentenceRef = useRef(sentencesRef.current[0] || '');

    const teacherWordsAudioRef = useRef<HTMLAudioElement>(null);
    const [finalText, setFinalText] = useState(text);
    const ttsApi = new MultimediaAPI();
    const { commonStatusStore, audioStore, viewportStore, lectureStore, userInfoStore, ttsStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const { scaleRatio } = viewportStore;

    const isComponentMounted = useRef<boolean>(false);

    const chatModalTTSAudioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        setFinalText(containsVariablePattern(elementData.content.teacherWords) ? rewriteText(elementData.content.teacherWords) : elementData.content.teacherWords);
    }, [elementData.content.teacherWords]);

    const generateAudio = async () => {
        try {
            const res = await ttsApi.genVoiceAndUpload({ text: finalText }, 'tts');
            return res.file_url;
        } catch (error) {
            console.error('genVoiceAndUpload:', error);
            throw error;
        }
    };

    const playAudio = async () => {
        audioStore.setCurrentAudioEleId(elementData.id);
        let audioElement = teacherWordsAudioRef.current;

        if (!audioSrc) {
            try {
                const audioUrl = await generateAudio();
                if (audioStore.currentAudioEleId !== elementData.id) return;
                if (!isComponentMounted.current) return;
                setAudioSrc(convertUrl(audioUrl));
                if (audioElement) {
                    audioElement.src = audioUrl;
                    //播放速度
                    audioElement.playbackRate = teacherSpeakingRate;
                }
            } catch (error) {
                return;
            }
        } else {
            if (audioElement) {
                audioElement.src = audioSrc;
                //播放速度
                audioElement.playbackRate = teacherSpeakingRate;
            }
        }

        if (audioElement) {
            audioElement.currentTime = currentTime;  // Set the current time to the last paused position.  
            audioElement.play().then(() => {
                setIsPlaying(true);
                commonStatusStore.setIsTeacherSpeaking(true);
                if (elementData.content.autoPlay) {
                    //根据语音时长，语速playbackRate和文字总字数计算500毫秒显示多少字
                    let duration = audioElement.duration;
                    let totalCharacters = finalText.length;
                    // 计算每个字的时长 
                    const timePerCharacter = duration * 1000 / (totalCharacters * teacherSpeakingRate);
                    // 计算在500毫秒内可以展示的字数  
                    const charactersToDisplay = 500 / timePerCharacter;
                    startDisplayingText(500, charactersToDisplay);
                }
            }).catch(error => {
                console.error('play failed:', error);
            });
            //播放结束
            audioElement.onended = () => {
                setIsPlaying(false);
                setCurrentTime(0);
                stopDisplayingText();
                commonStatusStore.setIsTeacherSpeaking(false);
                if (!isTeacher) {
                    executeFunc(elementData.content?.doneCallFunc?.funcName, elementData.content?.doneCallFunc?.funcParams);
                    if (AIChatRef && AIChatRef.current?.showChatFirstMsg) {
                        AIChatRef.current.showChatFirstMsg();
                    }
                }
            };
        }
    };

    const startDisplayingText = (showSpeedTime: number = 500, showSpeedWordNum: number = 3) => {
        // 当 displayedText 已经是全部内容时，将 sentencesChunk 设为 ""  
        let sentencesChunk = sentenceIndexRef.current === 0 ? '' : displayedText;
        // 使用一个计数器，预防特殊情况
        let cntCalled = 0;

        const intervalId = setInterval(() => {
            setDisplayedText(prev => {
                cntCalled++;
                // 先生成一个sentencesChunk
                if (sentencesChunk === '') {
                    let i = sentenceIndexRef.current;
                    let totalCharacter = 0;
                    while (i < sentencesRef.current.length &&
                        totalCharacter + sentencesRef.current[i].length <= characterPerPage) {
                        totalCharacter += sentencesRef.current[i].length;
                        sentencesChunk = sentencesChunk + sentencesRef.current[i];
                        i++;
                    }

                    currentSentenceRef.current = strTrimLeft(sentencesChunk);
                    sentenceIndexRef.current = i;
                }

                textIndexRef.current = Math.min(textIndexRef.current + showSpeedWordNum, currentSentenceRef.current.length);
                const text = currentSentenceRef.current.slice(0, textIndexRef.current);
                if (textIndexRef.current >= currentSentenceRef.current.length) {
                    textIndexRef.current = 0;
                    sentencesChunk = '';

                    if (sentenceIndexRef.current >= sentencesRef.current.length) {
                        sentenceIndexRef.current = 0;
                        textIndexRef.current = 0;
                        clearInterval(intervalId);
                    }
                }

                if (cntCalled >= 300) {
                    console.log("cntCalled >= 300, Exit");
                    sentenceIndexRef.current = 0;
                    textIndexRef.current = 0;
                    clearInterval(intervalId);
                }

                return text;
            });
        }, showSpeedTime);
        setTextIntervalId(intervalId);
    };

    const stopDisplayingText = () => {
        if (textIntervalId) {
            clearInterval(textIntervalId);
            setTextIntervalId(null);
        }
    };

    const handlePlay = async () => {
        let audioElement = teacherWordsAudioRef.current;
        if (isPlaying && audioElement) {
            audioElement.pause();
            commonStatusStore.setIsTeacherSpeaking(false);
            setCurrentTime(audioElement.currentTime); // Save the current audio time when paused.  
            stopDisplayingText();
            setIsPlaying(false);
            if (AIChatRef && AIChatRef.current?.showChatFirstMsg) {
                AIChatRef.current.showChatFirstMsg();
            }
        } else {
            playAudio();
        }
    };

    const imgIcon = () => {
        if (isPlaying) {
            return isHovering ? PauseTTSIcon : SoundPlayingIcon;
        } else {
            return PlayTTSV2Icon;
        }
    };

    const changeWords = (newWords: string, audioUrl: string) => {
        if (teacherWordsAudioRef.current) {
            teacherWordsAudioRef.current.pause();
        }
        let text = containsVariablePattern(newWords) ? rewriteText(newWords) : newWords;
        setFinalText(text);
        setCurrentTime(0);
        setIsPlaying(false);
        stopDisplayingText();
        sentenceIndexRef.current = 0;
        textIndexRef.current = 0;
        currentSentenceRef.current = '';
        sentencesRef.current = text.split(/(?<=[。？！])/) || [text];
        setDisplayedText('');
        setAudioSrc(convertUrl(audioUrl));
    };

    useImperativeHandle(ref, () => ({
        changeWords
    }));

    useEffect(() => {
        if (elementData.content.autoPlay && !isTeacher) {
            playAudio();
        } else if (!elementData.content.autoPlay && !isTeacher) {
            setDisplayedText(finalText);
        }
    }, [elementData.content.autoPlay, elementData.content.audioUrl, finalText]);

    useEffect(() => {
        let audioElement = teacherWordsAudioRef.current;
        if (audioStore.currentAudioEleId !== elementData.id
            && audioElement && teacherWordsAudioRef.current) {
            teacherWordsAudioRef.current.pause();
            setIsPlaying(false);
            stopDisplayingText();
            setCurrentTime(audioElement.currentTime);
            //console.log(currentSentenceRef.current);
            setDisplayedText(currentSentenceRef.current);
        }
    }, [audioStore.currentAudioEleId, elementData.id]);

    useEffect(() => {
        if (isTeacher) {
            setDisplayedText(finalText);
        }
    }, [finalText, isTeacher]);

    // 和老师对话弹窗的TTS
    useEffect(() => {
        const playAudio = () => {
            if (ttsStore.audioSegments.length > 0) {
                chatModalTTSAudioRef.current = new Audio(`data:audio/mp3;base64,${ttsStore.audioSegments[0]}`);
                chatModalTTSAudioRef.current.play();
                audioStore.setCurrentAudioEleId(elementData.id + 1);
                ttsStore.setPlaying(true);
                chatModalTTSAudioRef.current.onended = () => {
                    ttsStore.removeAudioSegment();
                    ttsStore.setPlaying(false);
                };
            }
        };

        if (!ttsStore.isPlaying && ttsStore.audioSegments.length > 0) {
            if (commonStatusStore.isDialogOpen) {
                playAudio(); // only start a new audio when there is no audio currently playing
            }
        }
    }, [ttsStore.audioSegments.length, ttsStore.isPlaying]);

    useEffect(() => {
        isComponentMounted.current = true;
        return () => {
            isComponentMounted.current = false;
            if (chatModalTTSAudioRef.current) {
                chatModalTTSAudioRef.current.pause();
                chatModalTTSAudioRef.current = null;
            }
            ttsStore.resetTTSStore();
        }
    }, [lectureStore.currentBlockIndex])

    useEffect(() => {
        if (commonStatusStore.isDialogOpen) {
            if (audioStore.currentAudioEleId !== elementData.id + 1 && chatModalTTSAudioRef.current) {
                chatModalTTSAudioRef.current.pause();
                chatModalTTSAudioRef.current = null;
                ttsStore.resetTTSStore();
                ttsStore.setPlaying(true);//为了让自动播放终止
            }
        }
    }, [audioStore.currentAudioEleId])

    useEffect(() => {
        if (ttsStore.userNeedPause) {
            if (chatModalTTSAudioRef.current) {
                chatModalTTSAudioRef.current.pause();
                chatModalTTSAudioRef.current = null;
                ttsStore.resetTTSStore();
                ttsStore.setPlaying(false);
            }
        }
    }, [ttsStore.userNeedPause])

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{
                    ...commonStyle,
                    fontSize: `${scaleRatio * elementData.content.size}px`,
                    color: `${elementData.content.color}`,
                    whiteSpace: 'pre-wrap',
                }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.teacherWordsBox}>
                    <div className={styles.contentBox}>
                        <div
                            className={`${styles.words} ${isTeacher ? styles.ellipsis : ''}`}
                        >
                            {displayedText}
                        </div>
                    </div>
                    <div className={styles.profileBox}>
                        <div className={styles.profile}>
                            <img
                                src={isPlaying ? elementData.content.teacherProfileGif : elementData.content.teacherProfile}
                                alt="teacher profile"
                                onClick={() => {
                                    if (elementData.content.shouldOpenTeacherDialog) {
                                        commonStatusStore.openDialog();
                                    }
                                }}
                                className={styles.profileImg}
                            />
                            <img src={MessageIcon} alt="" className={styles.messageIcon} />
                        </div>
                        <div className={styles.teacherName}>
                            <div className={styles.teacherNameText}>
                                {elementData.content.teacherName}</div>
                            <div className={styles.playIconBox}>
                                <img
                                    src={imgIcon()}
                                    alt=""
                                    onMouseEnter={() => setIsHovering(true)}
                                    onMouseLeave={() => setIsHovering(false)}
                                    onClick={handlePlay}
                                    className={styles.playIcon}
                                />
                                {!isPlaying &&
                                    <div className={styles.teacherSpeakingRateBox}>
                                        {/* <div className={styles.speakingRateText}>播放速度</div> */}
                                        <div className={styles.rateChoiceBox}>
                                            <div
                                                className={`${styles.rateChoice} ${teacherSpeakingRate === 1 ? styles.selected : ''}`}
                                                onClick={() => {
                                                    setTeacherSpeakingRate(1);
                                                    localStorage.setItem('teacherSpeakingRate', '1');
                                                    if (teacherWordsAudioRef.current) {
                                                        teacherWordsAudioRef.current.playbackRate = 1;
                                                    }
                                                }}
                                            >
                                                正常
                                            </div>
                                            <div
                                                className={`${styles.rateChoice} ${teacherSpeakingRate === 0.7 ? styles.selected : ''}`}
                                                onClick={() => {
                                                    setTeacherSpeakingRate(0.7);
                                                    localStorage.setItem('teacherSpeakingRate', '0.7');
                                                    if (teacherWordsAudioRef.current) {
                                                        teacherWordsAudioRef.current.playbackRate = 0.7;
                                                    }
                                                }}
                                            >
                                                慢
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <audio
                    src={elementData.content.audioUrl ? elementData.content.audioUrl : ''}
                    ref={teacherWordsAudioRef}
                />
            </div>
        </BaseDragableElement>
    );
});

TeacherWordsRegion.displayName = 'TeacherWordsRegion';
export default observer(TeacherWordsRegion);