import { useState, useEffect, useRef } from 'react'
import styles from './lecture.module.less'
import { Button, Modal, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { useParams } from 'react-router-dom';
import CreateBlock from '../CreateBlock/CreateBlock';
import VisibleToTeacher from '@/components/VisibleToTeacher/VisibleToTeacher';
import VisibleToStudent from '@/components/VisibleToStudent/VisibleToStudent';
import service from '@/services/axios';
// import { loadPyodide } from "/public/pyodide/pyodide";
import { cloneDeep } from 'lodash';
import TeacherPreviewBlock from '@/components/Block/TeacherPreviewBlock/TeacherPreviewBlock';
import StudentBlock from '@/components/Block/StudentBlock/StudentBlock';
import { useNavigate } from 'react-router-dom';
import { BlockData, BlockTraceData } from '@/base/BlockData';
import { SectionAddBlockRequest, SectionAddBlockTraceRequest } from '@/base/SectionData';
import { genBlockDatafromJSON, genBlockTraceDatafromJSON } from '@/base/DataObjectGenerator';
import CopyBlockModal from '@/components/CopyBlockModal/CopyBlockModal';

const Lecture: React.FC = observer(() => {
    const { lectureStore, userInfoStore } = useStores();
    const { courseId, chapterId, sectionId } = useParams();
    const contentBoxRef = useRef<HTMLDivElement>(null); // 使用 useRef 创建一个引用
    const [createBlockModalOpen, setCreateBlockModalOpen] = useState(false);//控制创建编辑block的弹窗
    const [optionType, setOptionType] = useState(1);//创建还是编辑 1 创建 2 编辑
    const [editingBlock, setEditingBlock] = useState<BlockData | null>(null);//存储要编辑的block
    const [currentBlockId, setCurrentBlockId] = useState(-1);//存储当前要在下方插入block的blockId
    const [dataNow, setDataNow] = useState<number | undefined>(undefined)
    const { confirm } = Modal;
    const navigate = useNavigate();
    const [containerHeight, setContainerHeight] = useState(window.innerHeight);
    const [copyBlockModalOpen, setCopyBlockModalOpen] = useState(false);


    //获取blocklist
    const getBlockList = async () => {
        console.log("getBlockList", userInfoStore.userInfoData.userRole, userInfoStore.userInfoData.userView);
        let res: any;
        //如果是老师身份
        if (userInfoStore.userInfoData.userRole === 1 && userInfoStore.userInfoData.userView === "teacher") {
            res = await service.get(`/section/${sectionId}`);
            console.log("section data", res);
            if (res.status === 'success') {
                const blks: BlockData[] = [];
                res.data.blks.forEach((item: any) => {
                    blks.push(genBlockDatafromJSON(item));
                });
                console.log("getBlockList", blks);
                lectureStore.setOriginBlockList(blks);
                lectureStore.setBlockBackground(res.data?.extras?.blockBackground || '');
            }
            return;
        }

        //如果是学生身份，老师身份已经返回了
        res = await service.post(`/learn_section/${userInfoStore.userInfoData.id}/${sectionId}`);
        if (res.status === 'success') {
            const blks: BlockData[] = [];
            for (let i = 0; i < res.data.origin_section.blks.length; i++) {
                blks.push(genBlockDatafromJSON(res.data.origin_section.blks[i]));
            }
            console.log("getBlockList 2", res.data.origin_section.blks, blks);
            lectureStore.setOriginBlockList(blks);
            lectureStore.setSectionTraceId(res.data.section_trace.id);
            lectureStore.setBlockBackground(res.data.origin_section?.extras?.blockBackground || '');
            retrieveSectionTrace(res.data);
        }
    }
    //获取学习记录，首次进来自动添加第一个block
    const retrieveSectionTrace = async (data: any) => {
        if (data.section_trace.traces.length > 0) {
            const traces: BlockTraceData[] = [];
            for (let i = 0; i < data.section_trace.traces.length; i++) {
                traces.push(genBlockTraceDatafromJSON(data.section_trace.traces[i]));
            }
            lectureStore.setLearnedBlockList(traces);
        } else {
            try {
                // 生成一个新的聊天块
                //const chatBlock = new BlockData({
                //    blockInfo: [new ElementData<HiddenChatContent>()]
                //});

                // TODO: 不需要加这个block了，如果有全局的chatList, 可以复制到当前Block里
                //await addLearnedBlock(data.section_trace.id, chatBlock, 3); // 添加block，存储和老师聊天的内容

                // 只有当上面的 await 调用成功时，下面的代码才会执行
                const block: BlockData = genBlockDatafromJSON(data.origin_section.blks[0]);
                await addLearnedBlock(data.section_trace.id, block, 1);

            } catch (error) {
                console.error("An error occurred:", error);
            }
        }
    }

    useEffect(() => {
        console.log("lecture useEffect", userInfoStore.userInfoData.id, courseId, chapterId, sectionId);
        if (userInfoStore.userInfoData.id && courseId && chapterId && sectionId) {
            getBlockList();
        }
        return () => {
            lectureStore.resetLectureStore();
        }
    }, [courseId, chapterId, sectionId, userInfoStore.userInfoData.id])

    // useEffect(() => {
    //     loadPythonInstance();
    // }, []);
    // //加载python实例
    // const loadPythonInstance = async () => {
    //     if (!pyodideStore.pyodide) {
    //         const pyodideInstance = await loadPyodide({
    //             indexURL: "/pyodide/"
    //         });
    //         pyodideStore.setPyodide(pyodideInstance);
    //     }
    // }
    const scrollBottom = () => {
        setTimeout(() => {
            if (contentBoxRef.current) {
                contentBoxRef.current.scrollTop = contentBoxRef.current.scrollHeight;
            }
        }, 100); // 等待 0 毫秒后执行，这会将动作推到调用栈的末尾，允许 React 完成元素的插入
    };

    const editBlock = (type: number, block: BlockData | null) => {
        // type 1.创建 2.编辑 3.在当前block下方创建
        setOptionType(type);
        console.log("editBlock", type, block);
        if (type === 1) {
            setCurrentBlockId(-1);
            setDataNow(Date.now());
            setEditingBlock(null);
        } else if (type === 2) {
            setCurrentBlockId(block!.id);
            setDataNow(Date.now());
            setEditingBlock(block);
            lectureStore.setBlock(block);
            console.log("editBlock -- lectureStore block", lectureStore.block);
        } else if (type === 3) {
            if (!block) {
                // 在第0块前添加
                setCurrentBlockId(-1);
            } else {
                setCurrentBlockId(block.id);
            }
            setDataNow(Date.now());
            setEditingBlock(null);
        } else if (type === 4) {
            setCurrentBlockId(block!.id);
            setDataNow(Date.now());
            setEditingBlock(null);
        }
        setCreateBlockModalOpen(true);
    }

    const duplicateBlock = async (block: BlockData, offset: number) => {
        console.log("duplicateBlock", block, offset);
        const url = '/section_add_blk';
        const blockIndex = offset + 1;

        const blockParams = new SectionAddBlockRequest({
            sectionId: parseInt(sectionId!),
            block: block,
            offset: blockIndex
        });

        const res: any = await service.put(url, blockParams);

        if (res.status === 'success') {
            await getBlockList();
        }
        console.log("duplicateBlock end");
    };

    const moveBlock = async (direction: number, bID: number, blkIDList: number[], offset: number) => {
        console.log("moveBlock", direction, bID, blkIDList, offset);

        let url = '/section';

        const newBlockList = cloneDeep(blkIDList);

        if (direction === 0) {
            // 上移
            const preBlkID = newBlockList[offset - 1];
            newBlockList[offset - 1] = bID;
            newBlockList[offset] = preBlkID;
        } else if (direction === 1) {
            // 下移
            const nextBlkID = newBlockList[offset + 1];
            newBlockList[offset + 1] = bID;
            newBlockList[offset] = nextBlkID;
        }

        // 生成参数
        let newBlks = [];
        for (let i = 0; i < newBlockList.length; i++) {
            newBlks.push({
                id: newBlockList[i]
            });
        }

        const sectionParams = {
            id: parseInt(sectionId!),
            blks: newBlks
        };

        console.log("moveBlock sectionParams", sectionParams);

        const res: any = await service.put(url, sectionParams);

        if (res.status === 'success') {
            await getBlockList();
        }
        console.log("moveBlock end");
    };


    //删除block
    const deleteBlockConfirmation = (id: number) => {
        confirm({
            title: '确认删除block?',
            content: '删除后将无法找回这个block，你确定要删除吗？',
            async onOk() {
                await deleteBlock(id);
            },
            centered: true,
            okText: '确认',
            cancelText: '取消'
        });
    };

    const deleteBlock = async (id: number) => {
        try {
            const res: any = await service.put('/section_remove_blk', {
                section_id: sectionId,
                block_id: id
            });
            if (res.status === 'success') {
                getBlockList();
            } else {
                message.error("删除失败");
                console.error("Block delete was not successful");
            }
        } catch (error) {
            console.error("Failed to delete Block:", error);
        }
    };

    //添加学习记录
    const addLearnedBlock = async (sectionTraceId: number,
        block: BlockData, traceType: number) => {
        const learnedBlockTrace = new SectionAddBlockTraceRequest({
            sectionTraceId: sectionTraceId,
            blockTrace: new BlockTraceData({
                userId: userInfoStore.userInfoData.id,
                origBlockId: block.id,
                traceType: traceType,
                traceInfo: block.blockInfo,
                extras: block.extras,
            }),
            offset: -1
        });

        console.log("addLearnedBlock @Lecture", learnedBlockTrace.toJSON());
        console.log("addLearnedBlock @Lecture -- block", block);


        //if (block.blockInfo.every(item => item.type !== 20)) {
        try {
            const res: any = await service.put('/section_add_blk_trace', learnedBlockTrace.toJSON());
            if (res.status === 'success') {
                const newLearnedBlockList = cloneDeep(lectureStore.learnedBlockList);
                learnedBlockTrace.blockTrace.id = res.data.blk_trace_id;
                newLearnedBlockList.push(learnedBlockTrace.blockTrace);
                lectureStore.setLearnedBlockList(newLearnedBlockList);
                scrollBottom();
            } else {
                message.error("添加学习记录失败");
            }
        } catch (error) {
            console.log('error', error)
        }
        //} 
        //else {
        //    const newLearnedBlockList = cloneDeep(lectureStore.learnedBlockList);
        //    newLearnedBlockList.push(learnedBlockTrace.blockTrace);
        //    lectureStore.setLearnedBlockList(newLearnedBlockList);
        //    lectureStore.setCanNextStep(false);
        //}
    }

    useEffect(() => {
        // 定义调整高度的函数  
        const handleResize = () => {
            setContainerHeight(window.innerHeight);
        };

        // 初始化调用一次以确保高度正确  
        handleResize();

        // 监听窗口resize事件  
        window.addEventListener('resize', handleResize);

        // 清除事件监听器  
        return () => {
            window.removeEventListener('resize', handleResize);
            lectureStore.setBlockBackground('');
        };
    }, []);
    return (
        <>
            <div className={styles.lectureBox} style={{ height: containerHeight }}>
                <VisibleToTeacher>
                    {/* 点击就滚动到对应block */}
                    <div className={styles.sidebarNavigation}>
                        <ul>
                            {lectureStore.originBlockList?.map((item, index) => (
                                <li key={item.id}>
                                    <Button
                                        onClick={() => {
                                            const blockElement = document.getElementById(`block-${item.id}`);
                                            if (blockElement) {
                                                blockElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                            }
                                        }}
                                    >
                                        Block {index + 1}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.contentBox} ref={contentBoxRef}>
                        {
                            lectureStore.originBlockList?.map((item, index) => (
                                <div id={`block-${item.id}`} key={item.id}>
                                    <TeacherPreviewBlock
                                        blockItem={item}
                                        index={index}
                                        editBlock={editBlock}
                                        duplicateBlock={duplicateBlock}
                                        moveBlock={moveBlock}
                                        deleteBlockConfirmation={deleteBlockConfirmation}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className={styles.createBtn}>
                        <Button
                            type='primary' size='large'
                            style={{ marginRight: '20px' }}
                            onClick={() => setCopyBlockModalOpen(true)}
                        >
                            复制block
                        </Button>
                        <Button type='primary' size='large'
                            style={{ marginRight: '20px' }}
                            onClick={() => editBlock(1, null)} >添加block</Button>
                        <Button type='primary' size='large' onClick={() => navigate(`/classroom`)}>返回之前页面</Button>
                    </div>
                </VisibleToTeacher>
                <VisibleToStudent>
                    <StudentBlock />
                </VisibleToStudent>

            </div >
            <CreateBlock
                createBlockModalOpen={createBlockModalOpen}
                setCreateBlockModalOpen={setCreateBlockModalOpen}
                getBlockList={getBlockList}
                sectionId={sectionId as unknown as number}
                scrollBottom={scrollBottom}
                optionType={optionType}
                editingBlock={editingBlock}
                dataNow={dataNow!}
                blockList={lectureStore.originBlockList}//当前页面全部block的列表，插入时候使用
                currentBlockId={currentBlockId}
            />
            <CopyBlockModal
                isOpen={copyBlockModalOpen}
                onClose={() => setCopyBlockModalOpen(false)}
                targetSectionId={parseInt(sectionId!)}
                successCallback={getBlockList}
                scrollBottom={scrollBottom}
            />
        </>
    )
})
export default Lecture;