import styles from './aiChatRegion.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import ChatBubble from './ChatBubble';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import HiLogo from '@/assets/img/chat_region_hi.svg';
import SendDisableIcon from '@/assets/img/send_disable.svg';
import SendAbleIcon from '@/assets/img/send_able.svg';
import useChat from '@/hooks/useChat';
// import ArrowIcon from '@/assets/img/arrow_right.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import PresetQuestion from './PresetQuestion';
import { cloneDeep } from 'lodash';
import useCommonFunction from '@/hooks/useCommonFunction';
import MultimediaAPI from '@/api/multimedia';
import service from '@/services/axios';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { BlockTraceData } from '@/base/BlockData';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import { ChatMessage } from '@/base/ChatMessage';
import MessageInputAndSend from './MessageInputAndSend/MessageInputAndSend';
import { convertUrl } from '@/utils/utils';

interface AIChatRegionProps extends IntrinsicElementProps<AIChatRegionContent> {
    blockTraceData: BlockTraceData;
    AIChatRef: React.RefObject<any>;
}

const AIChatRegion = forwardRef<any, AIChatRegionProps>((props, ref) => {
    // const AIChatRegion = ({ elementData, isEditable, handleFocusItem, handleResize,
    //     handleDragStop, handleDelete, blockTraceData, AIChatRef }) => {
    const { elementData, isEditable, handleFocusItem, handleResize,
        handleDragStop, handleDelete, blockTraceData, AIChatRef } = props;
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const { inputValue, setInputValue, handleSend, handleKeyDown } = useChat(blockTraceData, elementData);
    const { chatList, needFullAnswer, numMsgToContinue, readPresets, presetMessages = [],
        finishedPresetIndex = -1, userMessageAvatar = "", shouldReadQuestion, numMsgToDisableInput } = elementData.content;
    const [showFirstMsgAfterTeacherSpeak, setShowFirstMsgAfterTeacherSpeak] = useState(elementData.content.showFirstMsgAfterTeacherSpeak);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const { ttsStore, lectureStore, audioStore, commonStatusStore, userInfoStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const isComponentMounted = useRef<boolean | null>(null);
    const [currentPlayingPresetId, setCurrentPlayingPresetId] = useState(-1);
    const [currentPresetQuestion, setCurrentPresetQuestion] = useState<ChatMessage | null>(null);
    const { executeFunc } = useCommonFunction();
    const ttsApi = new MultimediaAPI();
    const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
    const intervalIdForPauseCheck = useRef<ReturnType<typeof setInterval> | null>(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [isAtBottom, setIsAtBottom] = useState(true); // 跟踪用户是否在底部  

    useEffect(() => {
        const container = chatContainerRef.current;

        const handleScroll = () => {
            if (container) {
                const { scrollTop, scrollHeight, clientHeight } = container;
                setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 100); // 检查是否在底部  
            }
            setIsScrolling(true);
            // 清除之前的定时器  
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
            // 设置在滚动后 1 秒隐藏滚动条  
            timeoutId.current = setTimeout(() => {
                setIsScrolling(false);
            }, 1000); // 1秒后隐藏滚动条  
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [chatContainerRef.current]);

    //更新blockTraceData
    const updateBlockTrace = async (newBlockTraceData: BlockTraceData) => {
        try {
            const res = await service.put('/block_trace', newBlockTraceData.toJSON());
        } catch (error) {
            console.log('error', error)
        }
    }

    const generateAudio = async (text: string) => {
        try {
            const res = await ttsApi.genVoiceAndUpload({ text: text }, 'tts');
            return res.data.file_url;
        } catch (error) {
            console.error('genVoiceAndUpload:', error);
            throw error;
        }
    };
    // 播放预设消息的音频  
    const playPresetMessageAudio = async (audioUrl: string | null,
        updateMessage: any,
        onDone: any,
        presetMessage: ChatMessage) => {
        let isRunningAtDefaultSpeed = false; // 是否以默认速度运行
        let index = 0;
        const renderMessage = (interval: number) => {
            intervalId.current = setInterval(() => {
                if (index < presetMessage.rawContent.length) {
                    const charsToAdd = presetMessage.rawContent.slice(index, index + 3);
                    updateMessage(charsToAdd);
                    index += 3;
                } else {
                    clearInterval(intervalId.current as ReturnType<typeof setInterval>);
                    intervalId.current = null;
                    clearInterval(intervalIdForPauseCheck.current as ReturnType<typeof setInterval>);
                    intervalIdForPauseCheck.current = null;
                    // 如果是没有音频URL的情况，直接调用onDone  
                    if ((!audioUrl) || (audioUrl && ttsStore.userNeedPause)) {
                        onDone();
                        commonStatusStore.setIsFetching(false);
                        updateBlockTrace(lectureStore.findBlockById(blockTraceData.id)!);
                    }
                }
            }, interval);
        };

        if (!audioUrl) {
            commonStatusStore.setIsFetching(true);
            // 没有音频 URL，只显示文本  
            renderMessage(20); // 控制速度为20ms  
            return;
        }

        // 有音频 URL，同时播放音频和显示文本  
        audioRef.current = new Audio(audioUrl);
        audioRef.current.play().then(() => {
            audioStore.setCurrentAudioEleId(elementData.id);
            commonStatusStore.setIsFetching(true);
            ttsStore.setUserNeedPause(false);
            ttsStore.setPlaying(true);
            renderMessage(500); // 控制显示速度为500ms  
            isRunningAtDefaultSpeed = true; // 设置为默认速度运行

            // 当音频播放结束时触发  
            if (audioRef.current) {
                audioRef.current.onended = () => {
                    if (intervalId.current) {
                        clearInterval(intervalId.current);
                        intervalId.current = null;
                    }
                    if (intervalIdForPauseCheck.current) {
                        clearInterval(intervalIdForPauseCheck.current);
                        intervalIdForPauseCheck.current = null;
                    }
                    onDone();
                    commonStatusStore.setIsFetching(false);
                    ttsStore.setPlaying(false);
                    console.log("ttsStore.isPlaying", ttsStore.isPlaying);
                    updateBlockTrace(lectureStore.findBlockById(blockTraceData.id)!);
                };
            }
            // 监听 ttsStore.userNeedPause 的变化  
            intervalIdForPauseCheck.current = setInterval(() => {
                if (ttsStore.userNeedPause && isRunningAtDefaultSpeed) {
                    clearInterval(intervalId.current as ReturnType<typeof setInterval>);
                    renderMessage(20); // 如果需要暂停，则调整速度为20ms  
                    isRunningAtDefaultSpeed = false; // 更新状态为未以默认速度运行  
                } else if (!ttsStore.userNeedPause && !isRunningAtDefaultSpeed) {
                    renderMessage(500); // 如果不需要暂停，恢复到500ms  
                    isRunningAtDefaultSpeed = true; // 更新状态为以默认速度运行
                }
            }, 100); // 每100ms检查一次状态  
        }).catch(error => {
            console.error('Audio play error:', error);
            renderMessage(20); // 发生错误时，以20ms速度显示文本  
            commonStatusStore.setIsFetching(false);
            ttsStore.setPlaying(false);
        });
    };

    //自动滚动
    const scrollChatContainer = () => {
        if (chatContainerRef.current) {
            const { current: container } = chatContainerRef;
            // 使用scrollTo方法进行平滑滚动
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth' // 这个设置能使滚动行为变得平滑
            });
        }
    }

    const showChatFirstMsg = () => {
        if (showFirstMsgAfterTeacherSpeak) {
            setShowFirstMsgAfterTeacherSpeak(false);
        }
    }

    useImperativeHandle(AIChatRef, () => ({
        showChatFirstMsg: showChatFirstMsg,
    }));

    // 添加useEffect以处理自动滚动
    useEffect(() => {
        if (isComponentMounted.current && isAtBottom) {
            scrollChatContainer(); // 只有在用户在底部时才自动滚动  
        }
    }, [elementData, commonStatusStore.isTeacherSpeaking, currentPresetQuestion, commonStatusStore.isFetching]); // 依赖于chat_list的变化

    //chatContainerRef.current高度变化时，自动滚动
    useEffect(() => {
        if (chatContainerRef.current) {
            const observer = new ResizeObserver(() => {
                if (isAtBottom) {
                    scrollChatContainer();
                }
            });
            observer.observe(chatContainerRef.current);
            return () => observer.disconnect();
        }
    }, [chatContainerRef.current]);


    useEffect(() => {
        const playAudio = () => {
            if (ttsStore.audioSegments.length > 0) {
                audioRef.current = new Audio(`data:audio/mp3;base64,${ttsStore.audioSegments[0]}`);
                audioRef.current.play();
                ttsStore.setPlaying(true);
                audioRef.current.onended = () => {
                    ttsStore.removeAudioSegment();
                    ttsStore.setPlaying(false);
                };
            }
        };

        if (!ttsStore.isPlaying && ttsStore.audioSegments.length > 0) {
            playAudio(); // only start a new audio when there is no audio currently playing
        }
    }, [ttsStore.audioSegments.length, ttsStore.isPlaying]);

    useEffect(() => {
        isComponentMounted.current = true;
        // 初始自动滚动到底部  
        scrollChatContainer();
        return () => {
            isComponentMounted.current = false;
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
            ttsStore.resetTTSStore();
        }
    }, [lectureStore.currentBlockIndex])

    useEffect(() => {
        if (audioStore.currentAudioEleId !== elementData.id && audioRef.current) {
            audioRef.current.pause();
            audioRef.current = null;
            ttsStore.resetTTSStore();
            ttsStore.setPlaying(false);//为了让自动播放终止
        }
    }, [audioStore.currentAudioEleId])

    useEffect(() => {
        if (ttsStore.userNeedPause) {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
                ttsStore.resetTTSStore();
                ttsStore.setPlaying(false);
            }
        }
    }, [ttsStore.userNeedPause])

    //是否可以点击继续按钮
    useEffect(() => {
        const isFullAnswerNeeded = needFullAnswer ?? true;
        if (finishedPresetIndex === presetMessages.length - 1 && isFullAnswerNeeded) {
            commonStatusStore.setIsClickPreset(true);
        } else if (numMsgToContinue !== undefined && numMsgToContinue <= chatList.length) {
            // 这里也需要考虑num_msg_to_continue
            commonStatusStore.setIsClickPreset(true);
        } else {
            commonStatusStore.setIsClickPreset(false);
        }
    }, [finishedPresetIndex, presetMessages.length])

    // 消息数目达到一定后，可以点击继续按钮
    useEffect(() => {
        if (chatList.length >= numMsgToContinue) {
            commonStatusStore.setIsClickPreset(true);
        }
    }, [chatList.length])

    useEffect(() => {
        console.log("useEffect - 210", showFirstMsgAfterTeacherSpeak, finishedPresetIndex);
        if (showFirstMsgAfterTeacherSpeak && finishedPresetIndex === -1) return;

        let presetMessage: ChatMessage | null = null;
        if (presetMessages.length > finishedPresetIndex + 1) {
            presetMessage = presetMessages[finishedPresetIndex + 1];
        }
        if (!isTeacher && presetMessage) {
            const newChatItem = cloneDeep(elementData);
            const newPresetMessage = cloneDeep(presetMessage);
            newPresetMessage.rawContent = '';
            const updateMessage = (charsToAdd: string) => {
                newPresetMessage.rawContent += charsToAdd;
                newChatItem.content.chatList = [...chatList, newPresetMessage];
                lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);
            };

            const onDone = () => {
                if (presetMessage?.doneCallFunc?.funcName !== undefined &&
                    presetMessage?.doneCallFunc?.funcName !== null) {
                    if (presetMessage?.doneCallFunc?.funcName === "changeTeacherWords") {
                        commonStatusStore.setIsTeacherSpeaking(true);
                    }
                }
                executeFunc(presetMessage?.doneCallFunc?.funcName, presetMessage?.doneCallFunc?.funcParams);
                newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);
            };

            if (presetMessage.type === 1) {
                if (!presetMessage.readStreaming && !presetMessage.streamingShow) {
                    newChatItem.content.chatList = [...chatList, presetMessage];
                    newChatItem.content.finishedPresetIndex = finishedPresetIndex + 1;
                    lectureStore.updateElement(blockTraceData.id, elementData.id, newChatItem.content);
                    updateBlockTrace(lectureStore.findBlockById(blockTraceData.id)!);
                } else {
                    newChatItem.content.chatList = [...chatList, newPresetMessage];
                    if (presetMessage.audioUrl) {
                        playPresetMessageAudio(convertUrl(presetMessage.audioUrl), updateMessage, onDone, presetMessage);
                    } else {
                        if (presetMessage.streamingShow) {
                            playPresetMessageAudio(null, updateMessage, onDone, presetMessage);
                            return;
                        }
                        generateAudio(presetMessage.rawContent).then(audioUrl => {
                            if (audioStore.currentAudioEleId !== elementData.id) return;
                            playPresetMessageAudio(audioUrl, updateMessage, onDone, presetMessage);
                        }).catch(error => {
                            playPresetMessageAudio(null, updateMessage, onDone, presetMessage);
                        });
                    }
                }
            } else if (presetMessage.type === 2) {
                setCurrentPresetQuestion(presetMessage);
            } else if (presetMessage.type === 4) {
                newChatItem.content.chatList = [...chatList, presetMessage];
                onDone();
                updateBlockTrace(lectureStore.findBlockById(blockTraceData.id)!);
            }
        }

        return () => {
            if (intervalId.current) clearInterval(intervalId.current);
            if (intervalIdForPauseCheck.current) clearInterval(intervalIdForPauseCheck.current);
        };
    }, [finishedPresetIndex, isTeacher, showFirstMsgAfterTeacherSpeak])

    useEffect(() => {
        if (commonStatusStore.buttonFlashing) {
            const time = setTimeout(() => {
                commonStatusStore.setButtonFlashing(false);
            }, 1000);
            return () => clearTimeout(time);
        }
    }, [commonStatusStore.buttonFlashing])

    //是否展示输入框
    //当numMsgToDisableInput为小于等于0时，展示输入框，除此当chatList的长度大于等于numMsgToDisableInput时，不展示输入框
    const showInputAreaFunc = () => {
        if (numMsgToDisableInput <= 0) {
            return true;
        } else if (numMsgToDisableInput > 0) {
            if (chatList.length >= numMsgToDisableInput) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}
    >
        <div
            style={{
                ...commonStyle,
                backgroundColor: `${elementData.content.backgroundColor}`,
                borderRadius: '20px'
            }}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} 
            ${isEditable ? styles.element : ''}`}
        >
            <div className={styles.chatBox}>
                <div className={styles.chatHead}>
                    <img src={HiLogo} className={styles.chatLogo} />
                    <div
                        className={styles.chatTitle}
                        style={{
                            color: `${elementData.content.titleColor}`,
                            fontSize: `${elementData.content.titleFontSize}px`
                        }}
                    >
                        {elementData.content.titleContent}
                    </div>
                </div>
                <div className={`${styles.chatContentBox} ${isScrolling ? styles.scrolling : ''}`} ref={chatContainerRef}>
                    <div className={styles.aiChat} >
                        {
                            isTeacher && (
                                presetMessages?.map((item, index) => {
                                    if (item.type === 1 || item.type === 4) {
                                        return (
                                            <ChatBubble key={index} item={item} role="gpt" />
                                        )
                                    } else if (item.type === 2) {
                                        return (
                                            <PresetQuestion
                                                key={item.id}
                                                msg={item}
                                                readPresets={readPresets}
                                                userMessageAvatar={userMessageAvatar}
                                            />
                                        )
                                    }
                                })
                            )
                        }
                        {
                            !isTeacher && (
                                elementData.content?.chatList?.map((item, index) => {
                                    const newItem = cloneDeep(item);
                                    return (
                                        <ChatBubble
                                            key={index}
                                            item={newItem}
                                            role="gpt"
                                            userMessageAvatar={userMessageAvatar}
                                            //如果是最后一个消息，并且ttsStore的isPlaying为true，则显示正在播放的图标
                                            isThisMsgPlaying={index === elementData.content.chatList.length - 1
                                                && (ttsStore.isPlaying || commonStatusStore.isFetching && elementData.content.shouldDoTts && !ttsStore.userNeedPause)
                                                && elementData.content.chatTools?.length === 0
                                            }
                                        />
                                    );
                                }))
                        }
                    </div>
                </div>
                {
                    isTeacher && elementData.content.showInputArea && (
                        <div className={styles.inputAreaBox}>
                            <MessageInputAndSend
                                msg={currentPresetQuestion!}
                                handleSend={handleSend}
                                elementDataId={elementData.id}
                                shouldReadQuestion={shouldReadQuestion}
                                currentPlayingPresetId={currentPlayingPresetId}
                                setCurrentPlayingPresetId={setCurrentPlayingPresetId}
                                finishedPresetIndex={finishedPresetIndex}
                                setCurrentPresetQuestion={setCurrentPresetQuestion}
                                currentQuestionIndex={presetMessages.findIndex(item => currentPresetQuestion && item.id === currentPresetQuestion.id)}
                                userMessageAvatar={userMessageAvatar}
                            />
                        </div>
                    )
                }
                {
                    !isTeacher && showInputAreaFunc() && !commonStatusStore.isTeacherSpeaking && (
                        <div className={styles.inputAreaBox}>
                            <MessageInputAndSend
                                msg={currentPresetQuestion!}
                                handleSend={handleSend}
                                elementDataId={elementData.id}
                                shouldReadQuestion={shouldReadQuestion}
                                currentPlayingPresetId={currentPlayingPresetId}
                                setCurrentPlayingPresetId={setCurrentPlayingPresetId}
                                finishedPresetIndex={finishedPresetIndex}
                                setCurrentPresetQuestion={setCurrentPresetQuestion}
                                currentQuestionIndex={presetMessages.findIndex(item => currentPresetQuestion && item.id === currentPresetQuestion.id)}
                                userMessageAvatar={userMessageAvatar}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    </BaseDragableElement>)
});

AIChatRegion.displayName = 'AIChatRegion';

export default observer(AIChatRegion);
