import React, { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import service from '@/services/axios';
import styles from './btnTextGroupSelector.module.less';

interface BtnTextGroupSelectorPros {
    fileSelectorOpen: boolean;
    setFileSelectorOpen: (open: boolean) => void;
    clickCallback: (item: any) => void;
    resourceType: number;
}


const BtnTextGroupSelector: React.FC<BtnTextGroupSelectorPros> = ({ 
    fileSelectorOpen, 
    setFileSelectorOpen, 
    clickCallback, 
    resourceType }) => {
    const [resourceFileList, setResourceFileList] = useState([]);

    useEffect(() => {
        if (fileSelectorOpen && resourceType) {
            getResourceFileList();
        }
    }, [fileSelectorOpen, resourceType]);

    const getResourceFileList = async () => {
        try {
            let filteredTemplateFiles: any = [];
            let res: any = await service.get('/fetch_file_urls', { params: { dir: 'templates' } });
            if (res.status === 'success') {
                // 需要进一步做过滤
                const templateFiles = res.data.files_info;
                for (let i = 0; i < templateFiles.length; i++) {
                    const tType = parseInt(templateFiles[i].file_url.split('/').pop().split('-')[0], 10);

                    if (tType === 103) {
                        // 需要从文件名中提前出thumbnail
                        const segs = templateFiles[i].file_url.split('/').pop().split('-');
                        let thumbnail = '';
                        const isAliyun = import.meta.env.VITE_OSS_SITE === "Aliyun";
                        if (!isAliyun)
                            thumbnail = 'https://agi4kids.s3.us-west-2.amazonaws.com/images/' + segs[1] + '.' + segs[2].split('.')[0];
                        else {
                            thumbnail = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/' + segs[1] + '.' + segs[2].split('.')[0];
                        }                               
                        const iProp = {
                            fullName: templateFiles[i].file_url,
                            thumbnail: thumbnail,
                            mode: 1
                        };

                        filteredTemplateFiles.push(iProp);
                    }
                }
            }
            setResourceFileList(filteredTemplateFiles);
        } catch (err) {
            console.log(err);
        }
    }

    const handleClick = (item: any) => {
        clickCallback(item);
        setFileSelectorOpen(false);
        document.body.style.cursor = 'copy';
    };

    const renderResourceItem = (item: any) => {
        return <div onClick={() => handleClick(item)} key={item.thumbnail}>
            <img src={item.thumbnail} style={{ width: '100px', height: '100px' }} />
            <div>img:{item.thumbnail.split('/').pop().substr(0, 12)}</div>
        </div>;
    };

    return (
        <Drawer
            title="选择文字按钮模板"
            placement="right"
            closable={true}
            onClose={() => setFileSelectorOpen(false)}
            open={fileSelectorOpen}
            width={500}
            className={styles.templateFileSelector}
        >
            <div className={styles.divHorizontalLayout}>
                <div className={styles.uploadMedia}>
                    <Button onClick={() => handleClick({ mode: 0, fullName: "new" })}>
                        新文字按钮组
                    </Button>
                </div>
            </div>
            <hr />
            <br></br>
            <br></br>
            <div className={styles.fileList}>
                {resourceFileList.map(renderResourceItem)}
            </div>
        </Drawer>
    );
};

export default BtnTextGroupSelector;