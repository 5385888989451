import 'reflect-metadata';
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
// import mixpanel from 'mixpanel-browser';

 
// 使用你的Google Analytics跟踪ID进行初始化
//ReactGA.initialize('G-LCLRCHC0DH');
//mixpanel.init('e25f8460b7d6b379b8ec4c2bdfc92575', 
//            {debug: true, track_pageview: true, persistence: 'localStorage'});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <App />
)
