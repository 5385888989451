import { makeObservable, observable, action } from "mobx";
import service from '@/services/axios';
import UserInfoData from "@/base/UserInfoData";
import UserAssetData from "@/base/UserAssetData";
import UserManagementAPI from '@/api/userManagement';

// mobX好像不能跟踪对象的属性，只能跟踪字段，所以我们要把
// UserInfoData和UserAssetData的字段flat到UserInfoStore中

class UserInfoStore {
    userInfoData: UserInfoData;
    userAssetData: UserAssetData;

    constructor() {
        this.userInfoData = new UserInfoData();
        this.userAssetData = new UserAssetData();

        makeObservable(this, {
            userInfoData: observable,
            userAssetData: observable,
            setUserInfoData: action,
            setUserAssertData: action,
            updateUserInfoFromData: action,
            updateUserAssetsFromData: action,
            refreshUserInfo: action,
            refreshUserAssets: action,
            updateUserAssets: action,
            resetUserInfo: action,
        });
    }

    setUserInfoData({
        id,
        userRole,
        userView,
        name,
        realUsername,
        phone,
        wechatID,
        avatarUrl,
        tagLine,
        age,
        gender
    }: Partial<UserInfoData> = {}) {
        if (id) {
            this.userInfoData.id = id;
        }
        if (userRole) {
            this.userInfoData.userRole = userRole;
        }
        if (userView) {
            this.userInfoData.userView = userView;
        } else {
            if (userRole === 1) {
                this.userInfoData.userView = localStorage.getItem('userView') || 'student';
            } else {
                this.userInfoData.userView = 'student'
            }
        }
        if (name) {
            this.userInfoData.name = name;
        }
        if (realUsername) {
            this.userInfoData.realUsername = realUsername;
        }
        if (phone) {
            this.userInfoData.phone = phone;
        }
        if (wechatID) {
            this.userInfoData.wechatID = wechatID;
        }
        if (avatarUrl) {
            this.userInfoData.avatarUrl = avatarUrl;
        }
        if (tagLine) {
            this.userInfoData.tagLine = tagLine;
        }
        if (age) {
            this.userInfoData.age = age;
        }
        if (gender) {
            this.userInfoData.gender = gender;
        }
    }

    setUserAssertData({
        id,
        numCoins,
        numDiamonds,
        medals
    }: Partial<UserAssetData> = {}) {
        if (id) {
            this.userAssetData.id = id;
        }
        if (numCoins) {
            this.userAssetData.numCoins = numCoins;
        }
        if (numDiamonds) {
            this.userAssetData.numDiamonds = numDiamonds;
        }
        if (medals) {
            this.userAssetData.medals = medals;
        }
    }



    // 使用 setUserInfoData 来更新用户信息
    updateUserInfoFromData = (data: any) => {
        this.setUserInfoData({
            id: data.id,
            userRole: data.role,
            name: data.name,
            realUsername: data.real_username,
            phone: data.phone,
            avatarUrl: data.avatar_url,
            tagLine: data?.info?.tagline,
            gender: data?.info?.gender,
            age: data?.info?.age,
        });
    }

    updateUserAssetsFromData = (data: any) => {
        this.setUserAssertData({
            id: data.user_id,
            // 需要做转换，和数据库里的schema不一样
            numCoins: data.points,
            numDiamonds: data.stars,
            medals: data.extras.medals || [],
        });
    }

    refreshUserInfo = async (userId: number = 0): Promise<void> => {
        try {
            let response: any;
            if (userId === 0) {
                response = await service.get('/me');
                if (response.status === 'success') {
                    userId = response.data.id;
                }
            }
            if (userId) {
                response = await service.get(`/user/${userId}`);
                if (response.status === 'success') {
                    this.updateUserInfoFromData(response.data);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    refreshUserAssets = async (userId: number): Promise<void> => {
        try {
            console.log('refresh user assets');
            const userAPI = new UserManagementAPI();
            const response = await userAPI.getUserAssets(userId);
            console.log(response.data);
            if (response.status === 'success') {
                this.updateUserAssetsFromData(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    updateUserAssets = async (data: any): Promise<void> => {
        try {
            const userManagementAPI = new UserManagementAPI();
            const res = await userManagementAPI.UpdateUserAssetsExpress(data);
            if (res.status === 'success') {
                this.updateUserAssetsFromData(res.data);
            } else {
                throw new Error('Failed to refresh user assets');
            }
        } catch (error) {
            console.error('Error reward user', error);
            throw error;
        }
    }

    resetUserInfo() {
        this.userInfoData.userRole = 0;
        this.userInfoData.name = '';
        this.userInfoData.realUsername = '';
        this.userInfoData.id = 0;
        this.userInfoData.avatarUrl = '';
        this.userAssetData = new UserAssetData();
    }

    isTeacherView() {
        return this.userInfoData.userRole === 1 && this.userInfoData.userView === "teacher";
    }
}

const userInfoStore = new UserInfoStore();
export { UserInfoStore, userInfoStore };
