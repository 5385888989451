import { makeObservable, observable, action } from "mobx";

class ViewportStore {
    innerWidth: number = window.innerWidth;
    blockWidth: number = 1024;
    blockHeight: number = 768;
    scaleRatio: number = 1;

    constructor() {
        makeObservable(this, {
            innerWidth: observable,
            blockWidth: observable,
            blockHeight: observable,
            scaleRatio: observable,
            setInnerWidth: action,
            setBlockWidth: action,
            setBlockHeight: action,
            setScaleRatio: action,
            updateScaleRatio: action,
            handleResize: action,
        });

        // 初始化时更新缩放比例  
        this.updateScaleRatio(window.innerWidth);

        // 监听窗口大小变化  
        window.addEventListener('resize', this.handleResize);
    }

    setInnerWidth(width: number) {
        this.innerWidth = width;
    }

    setBlockWidth(width: number) {
        this.blockWidth = width;
    }

    setBlockHeight(height: number) {
        this.blockHeight = height;
    }

    setScaleRatio(ratio: number) {
        this.scaleRatio = ratio;
    }

    updateScaleRatio(width: number) {
        if (width >= 769 && width <= 1280) {
            //这个范围，block尺寸为800*600
            this.setScaleRatio(800 / 1024);
        } else {
            // 如果不在指定范围内，可以设置成默认值，比如1  
            this.setScaleRatio(1);
        }
    }

    handleResize = () => {
        const newWidth = window.innerWidth;
        this.setInnerWidth(newWidth);
        this.updateScaleRatio(newWidth);
    }

    dispose() {
        window.removeEventListener('resize', this.handleResize);
    }
}

const viewportStore = new ViewportStore();
export { viewportStore, ViewportStore };
