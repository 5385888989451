import { useRef, useState } from 'react';
import styles from './codeRegion.module.less';
import PropTypes from 'prop-types';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement, { IntrinsicElementProps } from '../common/BaseDragableElement';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import CodeGreyIcon from '@/assets/img/code_grey_icon.svg';
import CodeBlueIcon from '@/assets/img/code_blue_icon.svg';
import GameBlueIcon from '@/assets/img/game_blue_icon.svg';
import GameGreyIcon from '@/assets/img/game_grey_icon.svg';
import ShareIcon from '@/assets/img/share_icon.svg';
import DownloadIcon from '@/assets/img/download_icon.svg';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Tooltip, QRCode, message } from 'antd';
import { userShareObject } from '@/api/userLikeObject';
import { createUserWork } from '@/api/userWork';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { generateMD5 } from '@/utils/utils';
import CodeRegionContent from '@/base/ElementData/CodeRegionContent';

const CodeRegion: React.FC<IntrinsicElementProps<CodeRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete }) => {
    const iframeRef = useRef(null);
    const { userInfoStore } = useStores();
    const [showMode, setShowMode] = useState('iframe');
    const [qrUrl, setQrUrl] = useState('');
    const [qrLoading, setQrLoading] = useState(false);
    const { showGameBtn = true, showCodeBtn = true,
        showDownloadBtn = true, showShareBtn = true, elementMode = "normal" } = elementData.content;

    const sanitizeCode = (code: string): string => {
        let newCode = code;
        // 如果code的第一行是 ```html， 去掉code的第一行和最后一行
        if (code.startsWith('```html')) {
            newCode = code.split('\n').slice(1, -1).join('\n');
        }

        return newCode;
    }

    //const cspMeta = document.querySelector('meta[http-equiv="Content-Security-Policy"]');
    //if (cspMeta) {
    //  cspMeta.setAttribute('content', 'default-src *');
    //}

    const clickShareIcon = async () => {
        setQrLoading(true);
        const data = {
            user_id: userInfoStore.userInfoData.id,
            object_type: "code",
            object_id: -1,
            channel: "classroom",
            extras: {},
            create_time: 0
        };
        try {
            const res = await userShareObject(data);
            if (res.status === 'success') {
                generateShareInfo();
            }
        } catch (error) {
            console.error('Error sharing song');
        }
    }

    const generateShareInfo = async () => {
        const srcCode = containsVariablePattern(elementData.content.code)
            ? sanitizeCode(rewriteText(elementData.content.code))
            : elementData.content.code;
        const codeData = {
            id: elementData.id,
            srcCode: srcCode,
            language: "html",
            title: `${userInfoStore.userInfoData.name}的游戏`,
            authorName: userInfoStore.userInfoData.name
        };
        const concatenatedString = `${userInfoStore.userInfoData.id}${codeData.id}${codeData.srcCode}`;
        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: "code",
            work_info: codeData,
            create_time: 0
        };
        const shareLinkRes: any = await createUserWork(shareLinkData);
        if (shareLinkRes.status === 'success') {
            setQrUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
            setQrLoading(false);
        } else {
            setQrLoading(false);
            message.error('生成分享链接失败');
        }
    };

    const handleDownload = () => {
        const codeContent = containsVariablePattern(elementData.content.code)
            ? sanitizeCode(rewriteText(elementData.content.code))
            : elementData.content.code;
        const blob = new Blob([codeContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'code.html'; // 设置文件名  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}>
        <div
            style={{ ...commonStyle }}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            {...(isEditable && { 'data-findparent': 'findparent' })}
        >
            <div className={styles.codeRegion} >
                <div
                    className={styles.codeContainer}
                    style={{ width: elementMode === "normal" ? "70%" : "100%" }}
                >
                    {showMode === "iframe" &&
                        <iframe className={styles.iframe}
                            srcDoc={
                                containsVariablePattern(elementData.content.code) ?
                                    sanitizeCode(rewriteText(elementData.content.code)) : elementData.content.code}
                        />
                    }
                    {showMode === "code" &&
                        <div className={styles.code}>
                            <SyntaxHighlighter language="html" style={tomorrowNight}>
                                {containsVariablePattern(elementData.content.code) ?
                                    sanitizeCode(rewriteText(elementData.content.code)) : elementData.content.code}
                            </SyntaxHighlighter>
                        </div>
                    }
                </div>
                {
                    elementMode === "normal" && (
                        <div className={styles.operationArea}>
                            <div className={styles.topBtnBox}>
                                {showGameBtn && <div
                                    className={`${showMode === "iframe" ? styles.active : ''} ${styles.normalBtn}`}
                                    onClick={() => setShowMode('iframe')}>
                                    <img
                                        src={showMode === "iframe" ? GameBlueIcon : GameGreyIcon} className={styles.btnIcon} />
                                    <span className={styles.btnText}>游戏</span>
                                </div>}
                                {showCodeBtn && <div
                                    className={`${showMode === "code" ? styles.active : ''} ${styles.normalBtn}`}
                                    onClick={() => setShowMode('code')}>
                                    <img
                                        src={showMode === "code" ? CodeBlueIcon : CodeGreyIcon} className={styles.btnIcon} />
                                    <span className={styles.btnText}>代码</span>
                                </div>}
                            </div>
                            <div className={styles.bottomBtnBox}>
                                {showShareBtn && <div className={styles.normalBtn} onClick={clickShareIcon}>
                                    <Tooltip
                                        color='white'
                                        trigger='click'
                                        title={
                                            <QRCode
                                                value={qrUrl} size={150}
                                                status={qrLoading ? "loading" : "active"} />
                                        }

                                    >
                                        <img src={ShareIcon} className={styles.btnIcon} />
                                        <span className={styles.btnText}>分享</span>
                                    </Tooltip>
                                </div>}
                                {showDownloadBtn && <div className={styles.normalBtn} onClick={handleDownload}>
                                    <img src={DownloadIcon} className={styles.btnIcon} />
                                    <span className={styles.btnText}>下载</span>
                                </div>}
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    </BaseDragableElement>);
};

export default observer(CodeRegion);
