import axios from 'axios';

// 创建 axios 实例
const service = axios.create({
  baseURL: '/api', // 使用 Vite 提供的环境变量 import.meta.env.VITE_BASE_URL
  timeout: 50000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    // 在这里可以做一些请求前的操作，比如给请求头添加token等
    const token = localStorage.getItem("token") || '';
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;//设置header的token
    }
    return config;
  },
  error => {
    // 对请求错误做些什么，自己定义
    Promise.reject(error);
  }
);


// response拦截器
service.interceptors.response.use(
  response => {
    // 在这里可以对响应数据做一些处理，比如统一处理错误信息等
    return response.data;
  },
  error => {
    // 如果返回的状态码为401或者403，跳转到登录页
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem("token");
      const loginUrl = import.meta.env.VITE_LOGIN_URL; // 使用环境变量中的URL
      window.location.href = loginUrl;
    }
    return Promise.reject(error);
  }
);

export default service;