import DataDefinition from "../DataDefinition";

class CodeInfo extends DataDefinition {
    srcCode: string;
    authorName: string;
    title: string;
    language: string;
    url: string;

    constructor({
        srcCode = "",
        authorName = "",
        title = "",
        language = "html",
        url = "",
    }: Partial<CodeInfo> = {}) {
        super();
        this.allowedFields = ['srcCode', 'authorName', 'title', 'language', 'url'];
        this.srcCode = srcCode;
        this.authorName = authorName;
        this.title = title;
        this.language = language;
        this.url = url;
    }
}

export default CodeInfo;