import React from 'react';
import styles from './paymentSuccess.module.less';
import PaySuccessImg from '@/assets/img/product_details/pay_success.png';

const PaymentSuccess: React.FC = () => {
    return (
        <div className={styles.paySuccessPage}>
            <img src={PaySuccessImg} className={styles.paySuccessImg} />
        </div>
    )
}

export default PaymentSuccess;
