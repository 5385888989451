import { useState, useEffect } from 'react';
import styles from './clearRecordModal.module.less';
import { Modal, Button, InputNumber, message } from 'antd';
import service from '@/services/axios';
import EmptyIcon from '@/assets/img/qingkong.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { getSectionTraceResetCountApi, updateSectionTraceResetCountApi } from '@/api/sectionTraceResetCount';


interface ClearRecordModalProps {
    userId: number;
    sectionId: number;
    currentPageInCourse?: number;
    mode: string;
}

const ClearRecordModal: React.FC<ClearRecordModalProps> = ({
    userId, sectionId, currentPageInCourse, mode
}) => {
    const [open, setOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState({ part: false, all: false });
    const [partValue, setPartValue] = useState(1);
    const { lectureStore, userInfoStore } = useStores();
    const [sectionResetCount, setSectionResetCount] = useState(0);
    const [blkResetCount, setBlkResetCount] = useState(0);
    const [sectionTraceResetInfo, setSectionTraceResetInfo] = useState<any>({});

    const clearRecords = async (offset: number) => {
        const key = offset >= 0 ? 'part' : 'all';
        if (key === 'all' && sectionResetCount >= 3) {
            message.warning("已经没有清除机会了");
            return;
        }
        if (key === 'part' && blkResetCount >= 20) {
            message.warning("已经没有清除机会了");
            return;
        }
        if (btnLoading) {
            setBtnLoading({ ...btnLoading, [key]: true });
        }

        let res: any = await service.post(`/learn_section/${userId}/${sectionId}`);
        if (res.status === "success") {
            let sectionTrace = res.data.section_trace;
            // 修改 section_trace 的 traces，根据 isPartialClear 决定清除方式  
            // console.log("offset", offset);
            sectionTrace.traces = offset >= 0 ?
                sectionTrace.traces.slice(0, offset) : [];
            res = await service.put('section_trace', sectionTrace);
            if (res.status === "success") {
                message.success(offset >= 0 ? `已清空${offset}页之后的记录` : "学习记录已清空");
                lectureStore.resetSpecifiedLearnedBlock(offset);
                if (btnLoading) {
                    setOpen(false);
                }
                if (userInfoStore.userInfoData.userRole !== 1) {
                    if (key === 'all') {
                        updateSectionTraceResetCount("section");
                    } else {
                        updateSectionTraceResetCount("blk");
                    }
                }
            } else {
                message.error("清除失败");
                console.error("Failed to clear chat record");
            }
        } else {
            message.error("清除失败");
            console.error("Failed to clear chat record");
        }
        if (btnLoading) {
            setBtnLoading({ ...btnLoading, [key]: false });
        }
    };

    const getSectionTraceResetCount = async () => {
        const res: any = await getSectionTraceResetCountApi(userId, sectionId);
        if (res.status === "success") {
            setSectionResetCount(res.data.section_reset_count);
            setBlkResetCount(res.data.blk_reset_count);
            setSectionTraceResetInfo(res.data);
            if (mode === "teacher" && currentPageInCourse !== undefined) {
                if (res.data.blk_reset_count === 19) {
                    message.warning("还有最后一次清除机会");
                }
            }
            if (mode === "student") {
                if (res.data.section_reset_count === 2) {
                    message.warning("还有最后一次清除机会");
                }
            }
        } else {
            message.error("获取清除记录次数失败");
            console.error("Failed to get section trace reset count");
            //关闭弹窗
            setOpen(false);
        }
    }

    const updateSectionTraceResetCount = async (updateItem: string) => {
        const data = {
            ...sectionTraceResetInfo,
            section_reset_count: updateItem === "section" ? sectionResetCount + 1 : sectionResetCount,
            blk_reset_count: updateItem === "blk" ? blkResetCount + 1 : blkResetCount
        };
        const res: any = await updateSectionTraceResetCountApi(data);
        if (res.status === "success") {
            setSectionResetCount(data.section_reset_count);
            setBlkResetCount(data.blk_reset_count);
            setSectionTraceResetInfo(data);
        } else {
            message.error("更新清除记录次数失败");
            console.error("Failed to update section trace reset count");
        }
    }

    useEffect(() => {
        if (sectionId && open) {
            getSectionTraceResetCount();
        }
    }, [sectionId, open]);
    return (
        <div>
            {mode === "teacher" &&
                <Modal
                    title="清除上课记录"
                    open={open}
                    onCancel={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(false);
                    }}
                    centered
                    footer={null}
                >
                    {
                        currentPageInCourse !== undefined ? <div>
                            <p>是否清空第{currentPageInCourse + 1}页之后的记录（包括当前页）？</p>
                            <Button
                                type="primary"
                                loading={btnLoading.part}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    clearRecords(currentPageInCourse + 1);
                                }}
                            >
                                确定
                            </Button>
                        </div> : (
                            <div>
                                <div className={styles.clearWay}>
                                    <p>1、部分清除</p>
                                    <InputNumber
                                        min={1}
                                        onChange={(value) => setPartValue(value ?? 1)}
                                        value={partValue}
                                    />
                                    <p>页之后的将被清除</p>
                                    <Button
                                        type="primary"
                                        loading={btnLoading.part}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            clearRecords(partValue);
                                        }}
                                    >
                                        确定
                                    </Button>
                                </div>
                                <div className={styles.clearWay}>
                                    <p>2、全部清除</p>
                                    <Button
                                        type="primary"
                                        loading={btnLoading.all}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            clearRecords(-1);
                                        }}
                                    >
                                        确定
                                    </Button>
                                </div>
                            </div>
                        )
                    }

                </Modal>
            }
            {mode === "student" &&
                <Modal
                    title="清除上课记录"
                    open={open}
                    onCancel={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(false);
                    }}
                    centered
                    footer={
                        <>
                            <Button
                                type="primary"
                                loading={btnLoading.all}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    clearRecords(-1);
                                }}
                            >
                                确定
                            </Button>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpen(false);
                                }}
                            >
                                取消
                            </Button>
                        </>
                    }
                >
                    {
                        <div className={styles.clearWay}>
                            <p>学习记录将全部清除，确定继续吗？</p>
                        </div>
                    }

                </Modal>
            }
            <div
                className={styles.clearBtn}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                <img src={EmptyIcon} alt="" />
            </div>
        </div>
    );
};

export default observer(ClearRecordModal);