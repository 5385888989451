import PropTypes from 'prop-types';
import TextPlayableRegion from '../TextRegion/TextPlayableRegion';
import ImageRegion from "../ImageRegion/ImageRegion";
import VideoRegion from "../VideoRegion/VideoRegion";
import ButtonRegion from "../ButtonRegion/ButtonRegion";
import AdvancedButtonRegion from '../ButtonRegion/AdvancedButtonRegion';
import BtnTextGroupRegion from "../BtnTextGroupRegion/BtnTextGroupRegion";
import BtnGenericGroupRegion from '../BtnGenericGroupRegion/BtnGenericGroupRegion';
import AudioRegion from '../AudioRegion/AudioRegion';
import SunoGen from '../SunoGen/SunoGen';
import AIChatRegion from '../AIChatRegion/AIChatRegion';
import TeacherWordsRegion from '../TeacherWordsRegion/TeacherWordsRegion';
import LineRegion from '../LineRegion/LineRegion';
import RatingRegion from '../RatingRegion/RatingRegion';
import CodeRegion from '../CodeRegion/CodeRegion';
import QRCodeRegion from '../QRCodeRegion/QRCodeRegion';
import DragClassifyRegion from '../DragClassifyRegion/DragClassifyRegion';
import ShowCodeRegion from '../ShowCodeRegion/ShowCodeRegion';
import FlashCardRegion from '../FlashCardRegion/FlashCardRegion';
import LineConnectorRegion from '../LineConnectorRegion/LineConnectorRegion';
import ShareWithSoundRegion from '../ShareWithSoundRegion/ShareWithSoundRegion';
import { ElementContent, ElementData } from '@/base/ElementData/ElementData';
import TextRegionContent from '@/base/ElementData/TextRegionContent';
import ImageRegionContent from '@/base/ElementData/ImageRegionContent';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import BtnGenericGroupRegionContent from '@/base/ElementData/BtnGenericGroupRegionContent';
import VideoRegionContent from '@/base/ElementData/VideoRegionContent';
import AudioRegionContent from '@/base/ElementData/AudioRegionContent';
import SunoGenContent from '@/base/ElementData/SunoGenContent';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import TeacherWordsRegionContent from '@/base/ElementData/TeacherWordsRegionContent';
import LineRegionContent from '@/base/ElementData/LineRegionContent';
import RatingRegionContent from '@/base/ElementData/RatingRegionContent';
import CodeRegionContent from '@/base/ElementData/CodeRegionContent';
import QRCodeRegionContent from '@/base/ElementData/QRCodeRegionContent';
import { DragClassifyRegionContent } from '@/base/ElementData/DragClassifyRegionContent';
import ShowCodeRegionContent from '@/base/ElementData/ShowCodeRegionContent';
import FlashCardRegionContent from '@/base/ElementData/FlashCardRegionContent';
import { LineConnectorRegionContent } from '@/base/ElementData/LineConnectorRegionContent';
import ShareWithSoundRegionContent from '@/base/ElementData/ShareWithSoundRegionContent';

const RenderElement = (props: any) => {
    // 这里接受的是一个特殊变量props
    let data: ElementData<ElementContent> = (props.elementData as ElementData<ElementContent>);
    let blockTraceData = props.blockTraceData;
    switch (data.type) {
        case 101:
            return (
                <TextPlayableRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<TextRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    handleContentChange={props.handleContentChange}
                    index={props.index}
                >
                </TextPlayableRegion>
            );
        case 102:
            return (
                <ImageRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ImageRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </ImageRegion>
            );
        case 103:
            return (
                <ButtonRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ButtonRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </ButtonRegion>
            );
        case 104:
            return (
                <AdvancedButtonRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<AdvancedButtonRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    handleContentChange={props.handleContentChange}
                    index={props.index}
                    ref={props.elementRef}
                >
                </AdvancedButtonRegion>
            );
        case 105:
            return (
                <BtnTextGroupRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<BtnTextGroupRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    blockTraceData={blockTraceData}
                    ref={props.innerRef}
                    elementRef={props.elementRef}
                >
                </BtnTextGroupRegion>
            );
        case 106:
            return (
                <BtnGenericGroupRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<BtnGenericGroupRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    blockTraceData={blockTraceData}
                    ref={props.innerRef}
                    elementRef={props.elementRef}
                >
                </BtnGenericGroupRegion>
            );
        case 107:
            return (
                <VideoRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<VideoRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </VideoRegion>
            );
        case 108:
            return (
                <AudioRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<AudioRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    lectureStore={props.lectureStore}
                    index={props.index}
                >
                </AudioRegion>
            );
        case 109:
            return (
                <SunoGen
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<SunoGenContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    lectureStore={props.lectureStore}
                    index={props.index}>
                </SunoGen>
            );
        case 110:
            return (
                <AIChatRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<AIChatRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    blockTraceData={blockTraceData}
                    AIChatRef={props.AIChatRef}
                >
                </AIChatRegion>
            );
        case 111:
            return (
                <TeacherWordsRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<TeacherWordsRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    ref={props.elementRef}
                    AIChatRef={props.AIChatRef}
                >
                </TeacherWordsRegion>
            );
        // case 112:
        //   目前空缺
        //   return ()
        case 113:
            return (
                <LineRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<LineRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </LineRegion>
            );
        case 114:
            return (
                <RatingRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<RatingRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                    index={props.index}>
                </RatingRegion>
            );
        case 115:
            return (
                <CodeRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<CodeRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </CodeRegion>
            );
        //case 116:
        //    return ();
        case 117:
            return (
                <QRCodeRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<QRCodeRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </QRCodeRegion>
            );
        case 118:
            return (
                <DragClassifyRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<DragClassifyRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </DragClassifyRegion>
            );
        case 119:
            return (
                <ShowCodeRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ShowCodeRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </ShowCodeRegion>
            );
        case 120:
            return (
                <FlashCardRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<FlashCardRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </FlashCardRegion>
            );
        case 121:
            return (
                <LineConnectorRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<LineConnectorRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </LineConnectorRegion>
            );
        case 122:
            return (
                <ShareWithSoundRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ShareWithSoundRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </ShareWithSoundRegion>
            );
        default:
            return null;
    }

}

export default RenderElement;

RenderElement.propTypes = {
    elementData: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    handleFocusItem: PropTypes.func,
    handleResize: PropTypes.func,
    handleDragStop: PropTypes.func,
    handleDelete: PropTypes.func,
    handleContentChange: PropTypes.func,
    index: PropTypes.number.isRequired,
    blockTraceData: PropTypes.object,
    lectureStore: PropTypes.object,
    innerRef: PropTypes.object,
    elementRef: PropTypes.object,
    AIChatRef: PropTypes.object,
}
