import React, { useState } from 'react';
import styles from "./settingPassword.module.less"
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { Button, Input, message } from 'antd';
import service from '@/services/axios';
import VerifyMobile from '../VerifyMobile/VerifyMobile';
import sha256 from 'crypto-js/sha256';

const SettingPassword: React.FC = () => {
    const { userInfoStore } = useStores();
    const [isVerifyCodeValid, setIsVerifyCodeValid] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const changePassword = async () => {
        if (!isVerifyCodeValid) {
            message.error('请先验证手机号');
            return;
        }
        if (!newPassword) {
            message.error('请输入新密码');
            return;
        }
        //密码中不能包含空格
        if (/\s/.test(newPassword)) {
            message.error('密码中不能包含空格');
            return;
        }
        const data = {
            id: userInfoStore.userInfoData.id,
            real_username: userInfoStore.userInfoData.realUsername || userInfoStore.userInfoData.phone,
            password: sha256(newPassword).toString()
        };
        try {
            const res: any = await service.put('/user_incremental', data);
            if (res.status === 'success') {
                message.success('修改成功');
            } else {
                message.error('修改失败');
            }
        } catch (error) {
            console.error('Error changing password', error);
            message.error('修改失败');
        }
    }
    return (
        <div className={styles.content}>
            <div className={styles.settingItem}>
                <div className={styles.stepTitle}>1.请先验证手机号码</div>
                <VerifyMobile
                    userPhoneNum={userInfoStore.userInfoData.phone}
                    verifySuccessCallback={() => setIsVerifyCodeValid(true)}
                />
                {isVerifyCodeValid && <>
                    <div className={styles.stepTitle}>2.设置密码</div>
                    <div className={styles.inputBox}>
                        <Input.Password
                            placeholder="请输入新密码"
                            className={styles.input}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            disabled={!isVerifyCodeValid}
                        />
                    </div>
                    <Button
                        type="primary"
                        className={`${styles.changePasswordBtn} ${newPassword ? styles.changePasswordBtnActive : ''}`}
                        onClick={changePassword}
                    >
                        确认修改
                    </Button>
                </>}
            </div>
        </div>
    )
}

export default observer(SettingPassword);
