import React from 'react';
import styles from './loginV2.module.less';
import { Modal } from 'antd';
import { useState, useEffect } from "react";
import { Button, message, Select } from "antd";
import { useNavigate } from "react-router-dom";
import service from "@/services/axios";
import qs from 'qs';
import sha256 from 'crypto-js/sha256';
import WeChatLogin from "./WeChatLogin/WeChatLogin";
import RobotLogo from "@/assets/img/robot_logo.svg";

const { Option } = Select;

const LoginV2: React.FC = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utm = searchParams.get("utm"); // 获取 utm 参数的值
    const [loginType, setLoginType] = useState("phone");
    const [currentMode, setCurrentMode] = useState("login");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState(2);
    const [phone, setPhone] = useState("");
    const [verifyCode, setVerifyCode] = useState("");
    const [isCodeBtnDisabled, setCodeBtnDisabled] = useState(false);// 获取验证码按钮是否禁用
    const [countdown, setCountdown] = useState(60); // 再次获取验证码倒计时秒数
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [verifyCodeError, setVerifyCodeError] = useState("");
    const navigate = useNavigate();
    const validateUsername = () => {
        if (!username.trim()) {
            setUsernameError("用户名不能为空");
            return false;
        }
        if (username.includes(" ")) {
            setUsernameError("用户名不能包含空格");
            return false;
        }
        setUsernameError("");
        return true;
    };

    const validatePassword = () => {
        if (!password.trim()) {
            setPasswordError("密码不能为空");
            return false;
        }
        if (password.includes(" ")) {
            setPasswordError("密码不能包含空格");
            return false;
        }
        if (password.length < 5) {
            setPasswordError("密码长度不能少于5个字符");
            return false;
        }
        if (password.length > 24) {
            setPasswordError("密码长度不能超过24个字符");
            return false;
        }
        setPasswordError("");
        return true;
    };

    const validatePhone = () => {
        if (!phone.trim()) {
            setPhoneError("手机号不能为空");
            return false;
        }
        if (!/^1[3456789]\d{9}$/.test(phone)) {
            setPhoneError("手机号格式不正确");
            return false;
        }
        setPhoneError("");
        return true;
    };

    const validateVerifyCode = () => {
        if (!verifyCode.trim()) {
            setVerifyCodeError("验证码不能为空");
            return false;
        }
        setVerifyCodeError("");
        return true;
    };

    const onLogin = async () => {
        const isUsernameValid = validateUsername();
        const isPasswordValid = validatePassword();

        if (!isUsernameValid || !isPasswordValid) {
            return;
        }

        const values = { username: username, password: sha256(password).toString() };
        const data = qs.stringify(values);
        try {
            const res: any = await service.post("/login", data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
            if (res.status === 'success') {
                message.success("登录成功", 1.5);
                localStorage.setItem("token", res.data.access_token);
                navigate("/");
            } else {
                message.error(res.data);
            }
        } catch (error) {
            console.warn(error);
            message.error("登录异常，请稍后再试");
        }
    };


    const onRegister = () => {
        const isUsernameValid = validateUsername();
        const isPasswordValid = validatePassword();

        if (!isUsernameValid || !isPasswordValid) {
            return;
        }
        const secureHash = sha256(password).toString();
        const values = {
            real_username: username,
            secure_hash: secureHash, role,
            utm: utm ? utm : ""
        };
        console.log(values);

        //注册
        service.post("/signup", values).then((res: any) => {
            if (res.status === "success") {
                message.success("注册成功");
                setCurrentMode("login");
            } else {
                message.error(res.data);
            }
        });
    };

    const onChangeMode = (mode: string) => {
        setUsername("");
        setPassword("");
        setUsernameError("");
        setPasswordError("");
        setCurrentMode(mode);
    };

    const onGetCode = async () => {
        const isPhoneValid = validatePhone();
        if (!isPhoneValid) {
            return;
        }
        setCodeBtnDisabled(true);
        const data = {
            sms_account: "7e7ce07d",
            phone_number: phone,
            sign: "神元力量",
            template_id: "ST_810192b9",
        };
        //获取验证码
        try {
            const res: any = await service.post("/send_sms_verify_code", data);
            if (res.status === "success") {
                message.success("验证码已发送");

                setCountdown(60);

                const timer = setInterval(() => {
                    setCountdown((prev) => {
                        if (prev === 1) {
                            clearInterval(timer);
                            setCodeBtnDisabled(false);
                            return 60; // 重置为初始倒计时秒数  
                        }
                        return prev - 1;
                    });
                }, 1000);
            } else {
                message.error("获取验证码失败，请稍后再试");
                setCodeBtnDisabled(false);
            }
        } catch (error) {
            console.warn(error);
            message.error("获取验证码异常，请稍后再试");
            setCodeBtnDisabled(false);
        }
    };

    const onPhoneLogin = async () => {
        const isPhoneValid = validatePhone();
        const isVerifyCodeValid = validateVerifyCode();

        if (!isPhoneValid || !isVerifyCodeValid) {
            return;
        }
        const data = {
            sms_account: "7e7ce07d",
            phone_number: phone,
            code: verifyCode,
            scene: "注册验证码",
            type: 0
        };
        //手机登录
        try {
            const res: any = await service.post("/check_sms_verify_code", data);
            if (res.status === "success" && res.data.Result === "0") {
                const loginRes: any = await service.post("/signup_and_login_phone", { phone: phone, utm: utm ? utm : "" });
                if (loginRes.status === "success") {
                    message.success("登录成功", 1.5);
                    localStorage.setItem("token", loginRes.data.access_token);
                    navigate("/");
                } else {
                    message.error("登录失败，请稍后再试");
                }
            } else {
                message.error("验证码错误，请重新输入");
            }
        } catch (error) {
            console.warn(error);
            message.error("登录异常，请稍后再试");
        }
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            const allowedOrigins = [
                'https://agi4kids.com',
                'https://www.agi4kids.com',
                'https://agi4kids.cn',
                'https://www.agi4kids.cn',
                'https://app.agi4kids.cn',
            ];
            // 确保只处理来自可信来源的消息  
            if (allowedOrigins.indexOf(event.origin) > -1) {
                if (event.data.redirect) {
                    // 执行跳转  
                    window.location.href = event.data.redirect;
                }
                // 处理token逻辑  
                if (event.data.token) {
                    localStorage.setItem('token', event.data.token);
                }
                // 处理错误逻辑
                if (event.data.errorText) {
                    message.error(event.data.errorText);
                }
            }
        };

        window.addEventListener('message', handleMessage, false);

        return () => {
            // 清理监听器  
            window.removeEventListener('message', handleMessage, false);
        };
    }, []);

    useEffect(() => {
        localStorage.removeItem('currentLevel');
        localStorage.removeItem('currentCourseId');
        localStorage.removeItem('currentCourseName');
        localStorage.removeItem('currentCourse');
    }, [])
    return (
        <div className={styles.loginV2Page}>
            <div className={styles.mainBox}>
                <div className={styles.topArea}>
                    <div className={styles.radishIcon}>
                        <img src={RobotLogo} alt="hello" />
                    </div>
                    <div className={styles.title}>欢迎来到艾宝AI</div>
                    <div className={styles.subTitle}>在这里我们将一起掌握并了解AI的知识</div>
                    <div className={styles.loginType}>
                        <span
                            className={`${loginType === "phone" ? styles.active : ""} ${styles.loginTypeItem}`}
                            onClick={() => setLoginType("phone")}
                        >
                            验证码登录
                        </span>
                        <span
                            className={`${loginType === "weChat" ? styles.active : ""} ${styles.loginTypeItem}`}
                            onClick={() => setLoginType("weChat")}
                        >
                            微信登录
                        </span>
                        <span
                            className={`${loginType === "account" ? styles.active : ""} ${styles.loginTypeItem}`}
                            onClick={() => setLoginType("account")}
                        >
                            账号登录
                        </span>
                    </div>
                </div>
                {
                    loginType === "phone" && (
                        <div className={styles.phoneBox}>
                            <div className={styles.phoneText}>手机号</div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder="请输入手机号"
                                    className={styles.input}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    onBlur={validatePhone}
                                />
                                {phoneError && <div className={styles.errorText}>{phoneError}</div>}
                            </div>
                            <div className={styles.verifyText}>验证码</div>
                            <div className={styles.getCodeBox}>
                                <input
                                    type="text"
                                    placeholder="请输入验证码"
                                    className={styles.input}
                                    value={verifyCode}
                                    onChange={(e) => setVerifyCode(e.target.value)}
                                    onBlur={validateVerifyCode}
                                />
                                <Button
                                    className={`${styles.getCodeBtn} ${phone.trim() !== "" && /^1[3456789]\d{9}$/.test(phone) ? styles.getCodeBtnActive : ""}`}
                                    onClick={onGetCode}
                                    disabled={isCodeBtnDisabled}
                                >
                                    {isCodeBtnDisabled ? `${countdown}s后重新获取` : '获取验证码'}
                                </Button>
                                {verifyCodeError && <div className={styles.errorText}>{verifyCodeError}</div>}
                            </div>

                            <Button className={styles.loginBtn} onClick={onPhoneLogin}>登录</Button>
                            <div className={styles.agreement}>
                                登录视为您已阅读并同意艾宝AI <span>用户使用条款</span> 和 <span>隐私政策</span>
                            </div>
                        </div>
                    )
                }
                {
                    loginType === "weChat" && (
                        <WeChatLogin utm={utm} />
                    )
                }
                {loginType === "account" && (
                    <div className={styles.accountLogin}>
                        {
                            currentMode === "login" && (
                                <div className={styles.loginBox}>
                                    <div className={styles.accountText}>账号</div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="text"
                                            placeholder="请输入账号"
                                            className={styles.input}
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            onBlur={validateUsername}
                                        />
                                        {usernameError && <div className={styles.errorText}>{usernameError}</div>}
                                    </div>
                                    <div className={styles.passwordText}>
                                        <span>密码</span>
                                        {/* <span className={styles.forget}>忘记密码?</span> */}
                                    </div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="password"
                                            placeholder="请输入密码"
                                            className={styles.input}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onBlur={validatePassword}
                                        />
                                        {passwordError && <div className={styles.errorText}>{passwordError}</div>}
                                    </div>
                                    <Button className={styles.loginBtn} onClick={onLogin}>登录</Button>
                                    {/* <div className={styles.goToRegister}>
                                还没有账号？点击这里 <span onClick={() => onChangeMode("register")}>注册</span>
                            </div> */}
                                    <div className={styles.agreement}>
                                        登录视为您已阅读并同意艾宝AI <span>用户使用条款</span> 和 <span>隐私政策</span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            currentMode === "register" && (
                                <div className={styles.registerBox}>
                                    <div className={styles.itemText}>邮箱或账号</div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="text"
                                            placeholder="请输入账号"
                                            className={styles.input}
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            onBlur={validateUsername}
                                        />
                                        {usernameError && <div className={styles.errorText}>{usernameError}</div>}
                                    </div>
                                    <div className={styles.itemText}>密码</div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="password"
                                            placeholder="请输入密码"
                                            className={styles.input}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onBlur={validatePassword}
                                        />
                                        {passwordError && <div className={styles.errorText}>{passwordError}</div>}
                                    </div>
                                    {import.meta.env.VITE_ENV_NAME === "DEVELOPMENT" && <div className={styles.itemText}>选择角色</div>}
                                    {import.meta.env.VITE_ENV_NAME === "DEVELOPMENT" && <Select
                                        placeholder="请选择角色"
                                        className={styles.select}
                                        value={role}
                                        onChange={(value) => setRole(value)}
                                    >
                                        <Option value={1}>教师</Option>
                                        <Option value={2}>学生</Option>
                                    </Select>}
                                    <Button className={styles.registerBtn} onClick={onRegister}>注册</Button>
                                    <div className={styles.goToLogin}>
                                        已有账号？点击这里 <span onClick={() => onChangeMode("login")}>登录</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoginV2;