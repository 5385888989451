import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import styles from "./presetAgents.module.less";
import CreateAgentModal from '../CreateAgentModal/CreateAgentModal';
import { generateMD5 } from '@/utils/utils';
import ChatData from '@/base/PlaygroundData/ChatData';
import { createUserWork, getAllUserWorks } from '@/api/userWork';
import DataDefinition from "@/base/DataDefinition";
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const PresetAgents: React.FC = () => {
    const navigate = useNavigate();
    const { userInfoStore, playgroundStore } = useStores();
    const [presetAgentsList, setPresetAgentsList] = useState<any[]>([]);
    const [isCreateAgentModalOpen, setIsCreateAgentModalOpen] = useState(false);

    //打开创建AI智能体的模态框
    const openCreateAgentModal = () => {
        setIsCreateAgentModalOpen(true);
    }

    const genNewAgentMd5 = () => {
        if (playgroundStore.userAgentList.length === 0) {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat0`);
        } else {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat${playgroundStore.userAgentList[0].id + 1}`);
        }
    }

    const createNewAgent = async (md5: string, presetAgentItem: any) => {
        try {
            const newChatAgent = new ChatData({
                md5: md5,
                userId: userInfoStore.userInfoData.id,
                userName: userInfoStore.userInfoData.name,
                workType: "playground_agent",
                workInfo: presetAgentItem.workInfo,
            });

            newChatAgent.workInfo.isPresetAgent = true;

            const createRes: any = await createUserWork(newChatAgent);
            if (createRes.status === 'success') {
                createRes.data = new ChatData(DataDefinition.toCamelCaseObj(createRes.data));
                playgroundStore.setUserAgentList([createRes.data, ...playgroundStore.userAgentList]);
                navigate(`/playground/chat/${createRes.data.md5}`);
            }

        } catch (error) {
            message.error('发生错误，请重试');
            throw error;
        }
    };

    const getAllPresetAgents = async () => {
        try {
            const res = await getAllUserWorks(0, "playground_agent");
            if (res.status === 'success') {
                const presetAgents = res.data.map((item: any) => {
                    item = new ChatData(DataDefinition.toCamelCaseObj(item));
                    return item;
                });
                console.log(presetAgents);
                setPresetAgentsList(presetAgents);
            }
        } catch (error) {
            message.error('发生错误，请重试');
            throw error;
        }
    }

    useEffect(() => {
        getAllPresetAgents();
    }, []);
    return (
        <div className={styles.discoverPage}>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    发现 AI 智能体
                </div>
                <div
                    className={styles.createAgentBtn}
                    onClick={openCreateAgentModal}
                >
                    + 创建 AI 智能体
                </div>
            </div>
            <div className={styles.presetAgentsBox}>
                <div className={styles.presetAgentsList}>
                    {presetAgentsList?.map(item => (
                        <div
                            key={item.id}
                            className={styles.presetAgentItemBox}
                            onClick={() => createNewAgent(genNewAgentMd5(), item)}
                        >
                            <div className={styles.presetAgentItem}>
                                <div className={styles.avatar}>
                                    <img src={item.workInfo.agentAvatar} alt="avatar" />
                                </div>
                                <div className={styles.introduce}>
                                    <div className={styles.agentName}>{item.workInfo.agentName}</div>
                                    <div className={styles.headerText}>{item.workInfo.headerText}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <CreateAgentModal
                isCreateAgentModalOpen={isCreateAgentModalOpen}
                setIsCreateAgentModalOpen={setIsCreateAgentModalOpen}
            />
        </div>
    )
}

export default observer(PresetAgents);
