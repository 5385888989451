import PropTypes from 'prop-types';
import { InputNumber, Input, Button, Select } from 'antd';
import styles from "./audioRegionModifier.module.less";
import { useEffect } from 'react';
import MediaFileSelectorModifier from '../MediaFileSelectorModifier/MediaFileSelectorModifier';
import URLModifier from '../URLModifier/URLModifier';
import { ElementData } from '@/base/ElementData/ElementData';
import AudioRegionContent from '@/base/ElementData/AudioRegionContent';

const { TextArea } = Input;
const { Option } = Select;

interface AudioRegionModifierProps {
    focusedItem: ElementData<AudioRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, type: string) => void;
};


const AudioRegionModifier: React.FC<AudioRegionModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex,
}) => {
    useEffect(() => {
        if (focusedItem.content.playerType === 'small') {
            onInputChange(focusedItem.id, 'width', 260)
            onInputChange(focusedItem.id, 'height', 350)
        } else if (focusedItem.content.playerType === 'small_horizontal') {
            onInputChange(focusedItem.id, 'width', 360)
            onInputChange(focusedItem.id, 'height', 160)
        } else if (focusedItem.content.playerType === 'medium') {
            onInputChange(focusedItem.id, 'width', 900)
            onInputChange(focusedItem.id, 'height', 700)
        }
    }, [focusedItem.content.playerType])
    return (
        <div>
            <div className={styles.mainModify}>
                <MediaFileSelectorModifier
                    mediaType={"images"}
                    afterSelectionCallback={url => onInputChange(focusedItem.id, 'songCoverUrl', url)}
                    btnText='歌曲封面'
                />
                <URLModifier
                    urlTitle='歌曲封面url'
                    value={focusedItem.content.songCoverUrl}
                    changeValue={value => onInputChange(focusedItem.id, 'songCoverUrl', value)}
                />
                <URLModifier
                    urlTitle='音频url'
                    value={focusedItem.content.src}
                    changeValue={value => onInputChange(focusedItem.id, 'src', value)}
                />
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>X</div>
                    <InputNumber
                        value={focusedItem.content.positionX}
                        onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                    />
                    <div className={styles.modifyItemTitle}>Y</div>
                    <InputNumber
                        value={focusedItem.content.positionY}
                        onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>歌名</div>
                    <Input
                        value={focusedItem.content.title}
                        onChange={e => onInputChange(focusedItem.id, 'title', e.target.value)}
                        className={styles.titleInput}
                        placeholder='请输入歌名'
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>作者</div>
                    <Input
                        value={focusedItem.content.artist}
                        onChange={e => onInputChange(focusedItem.id, 'artist', e.target.value)}
                        className={styles.titleInput}
                        placeholder='请输入作者'
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>歌词</div>
                    <TextArea
                        value={focusedItem.content.lyrics}
                        onChange={e => onInputChange(focusedItem.id, 'lyrics', e.target.value)}
                        className={styles.lyricTextArea}
                        placeholder='请输入歌词'
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>播放器type</div>
                    <Select
                        value={focusedItem.content.playerType}
                        onChange={value => onInputChange(focusedItem.id, 'playerType', value)}
                        style={{ width: 120 }}
                    >
                        <Option value="small">小(竖)</Option>
                        <Option value="small_horizontal">小(横)</Option>
                        <Option value="medium">中</Option>
                        <Option value="large">大</Option>
                    </Select>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>层级</div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
        </div>
    )
}
export default AudioRegionModifier;
