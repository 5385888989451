import { useState } from "react";
import { Button, Upload, message, Radio, Modal } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import service from "@/services/axios";
import PropTypes from 'prop-types';

const { Dragger } = Upload;

const UploadMediaBulk = ({ successCallBack, isVisible, handleOk, handleCancel }) => {
    const [uploading, setUpLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploadDir, setUploadDir] = useState('/');
    const [acceptStr, setAcceptStr] = useState('image/*');
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const beforeUpload = async () => {
        console.log('beforeUpload');
        setUpLoading(false);
        setUploadedFiles([]);
    }

    const handleUploadChange = (info) => {
        console.log('handleUploadChange info', info);
    };

    const customRequest = async (options) => {
        console.log('customRequest options', options);
        setUpLoading(true)
        const data = new FormData();
        data.append('file', options.file);
        const endpoint = `/uploadfile?dir=${encodeURIComponent(uploadDir)}`
        console.log('customRequest endpoint', endpoint);
        try {
            const res = await service.post(endpoint, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (res.status === 'success') {
                message.success(`${options.file.name} uploaded successfully`);
                console.log(`${options.file.name} uploaded successfully`);
                console.log(options.file, uploadedFiles);
                uploadedFiles.push(options.file.name);
                console.log('uploadedFiles -- ', uploadedFiles);
                successCallBack && successCallBack(res.data.file_url);
                setUpLoading(false)
            }
        } catch (error) {
            message.error(`${options.file.name} file upload failed`);
            console.error(error);
        }
        setUpLoading(false)
    }

    const handleRadioChange = (e) => {
        setUploadDir(e.target.value); // Update the selected upload directory
        if (e.target.value === 'images') {
            setAcceptStr('image/jpeg,image/jpg,image/png,image/svg');
        } else if (e.target.value === 'videos') {
            setAcceptStr('video/mp4,video/webm,video/ogg');
        }
    };

    const propsUpload = {
        accept: acceptStr,
        beforeUpload,
        customRequest,
        //showUploadList: false,
        multiple: true
    };

    return (<Modal
        title="上传文件"
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Radio.Group onChange={handleRadioChange} value={uploadDir}>
            <Radio value="images">images</Radio>
            <Radio value="videos">videos</Radio>
        </Radio.Group>
        <Dragger
            fileList={fileList}
            onChange={handleUploadChange}
            onRemove={(file) => {
                setFileList(fileList.filter((item) => item.uid !== file.uid));
            }}
            showUploadList={false}
            {...propsUpload}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">点击或拖拽文件到此区域进行上传</p>
            <p className="ant-upload-hint">
                支持单个或批量上传。严禁上传公司数据或其他违禁文件。
            </p>
        </Dragger>
        {uploadedFiles.length > 0 && (
            <div>
                {uploadedFiles.map((fileName, index) => (
                    <p key={index}>{fileName} 上传成功！</p>
                ))}
            </div>
        )}
    </Modal>);


};

UploadMediaBulk.propTypes = {
    successCallBack: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};


export default UploadMediaBulk;