import { useEffect, useState } from 'react';
import service from '@/services/axios';
import styles from "./bindWeChatRedirect.module.less";
import { useParams } from 'react-router-dom';

const BindWeChatRedirect = () => {
    const [tipText, setTipText] = useState('绑定中...');
    const { userId } = useParams();

    const weChatLoginAuth = async (code: string, state: string) => {
        try {
            const res: any = await service.get('/bind_wechat_openid', {
                params: {
                    code,
                    state
                }
            });
            if (res.status === 'success') {
                const data = {
                    id: userId,
                    wechat_id: res.data.openid
                };
                try {
                    const res: any = await service.put('/user_incremental', data);
                    if (res.status === 'success') {
                        setTipText('绑定成功');
                    } else {
                        setTipText('绑定失败');
                    }
                } catch (error) {
                    console.error('Error changing password', error);
                    setTipText('绑定失败');
                }
            } else {
                console.log('res:', res);
                setTipText('绑定失败');
            }
        } catch (error) {
            console.log('error:', error);
            setTipText('绑定失败');
        }
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code && state) {
            weChatLoginAuth(code, state);
        }
    }, [])

    return (
        <div className={styles.weChatAuthBox}>
            <div className={styles.loadingBox}>
                <div className={styles.loadingIcon}></div>
                <div className={styles.loadingText}>
                    {tipText}
                </div>
            </div>
        </div>
    )
}

export default BindWeChatRedirect;