import React, { useState } from 'react';
import styles from './blockNavigator.module.less';
import ToggleOpenIcon from "@/assets/img/block_history_list.svg";
import ToggleCloseIcon from "@/assets/img/block_history_list_close.svg";
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

const BlockNavigator: React.FC = () => {
    const { lectureStore } = useStores();
    const [isShow, setIsShow] = useState(false);

    return (
        <div className={styles.blockNavigator}>
            <div className={styles.toggleBtn}>
                <img src={isShow ? ToggleCloseIcon : ToggleOpenIcon} alt="toggle" onClick={() => setIsShow(!isShow)} />
            </div>
            <div className={styles.blockPageList} style={{ display: isShow ? 'flex' : 'none' }}>
                {
                    lectureStore.learnedBlockList.map((block, index) => {
                        return (
                            <div
                                key={index}
                                className={styles.blockPageItem}
                                onClick={() => lectureStore.setCurrentBlockIndex(index)}
                            >
                                {index + 1}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default observer(BlockNavigator);
