import { makeObservable, observable, action } from "mobx";
import ChatData from '@/base/PlaygroundData/ChatData';

class PlayGroundStore {
    userAgentList: ChatData[] = [];

    constructor() {
        makeObservable(this, {
            userAgentList: observable,
            setUserAgentList: action,
        });
    }

    setUserAgentList(list: ChatData[]) {
        this.userAgentList = list;
    }
}

const playgroundStore = new PlayGroundStore();
export { playgroundStore, PlayGroundStore };
