import styles from './shareWithSoundRegion.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement, { IntrinsicElementProps } from '../common/BaseDragableElement';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import { useState, useEffect } from 'react';
import LikeIcon from '@/assets/img/heart_red.svg';
import UnLikeIcon from '@/assets/img/heart_grey.svg';
import ShareIcon from '@/assets/img/share_icon.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { QRCode, Tooltip, message } from 'antd';
import { likeObjectAndUpdate, unlikeObjectAndUpdate, userShareObject, getLikeCnt } from '@/api/userLikeObject';
import { convertUrl } from '@/utils/utils';
import { createUserWork } from '@/api/userWork';
import { generateMD5 } from '@/utils/utils';
import ShareWithSoundRegionContent from '@/base/ElementData/ShareWithSoundRegionContent';
import AudioPlayingIcon from '@/assets/img/chat_component/audio_playing.svg';
import StopAudioIcon from '@/assets/img/chat_component/stop_audio.svg';
import PlayAudioIcon from '@/assets/img/chat_component/play_input_message.svg';
import GenAudioLoading from '@/assets/img/chat_component/gen_audio_loading.svg';
import MultimediaAPI from '@/api/multimedia';
import service from '@/services/axios';
import { BlockTraceData } from '@/base/BlockData';
import { cloneDeep } from 'lodash';
import BasicShareWithSoundInfo from '@/base/ShareData/BasicShareWithSoundInfo';

interface ShareWithSoundRegionProps extends IntrinsicElementProps<ShareWithSoundRegionContent> {
    blockTraceData: BlockTraceData;
}

const ShareWithSoundRegion: React.FC<ShareWithSoundRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    blockTraceData
}) => {
    const { userInfoStore, lectureStore } = useStores();
    const ttsAPI = new MultimediaAPI();
    const [isPlayingContent, setIsPlayingContent] = useState(false);
    const [isHoveredAudioPlayingIcon, setIsHoveredAudioPlayingIcon] = useState(false);
    const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
    const [audio] = useState(new Audio(elementData.content.contentVoiceUrl));
    const [contentAudioUrl, setContentAudioUrl] = useState(elementData.content.contentVoiceUrl || '');
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [qrUrl, setQrUrl] = useState('暂无');
    const [qrLoading, setQrLoading] = useState(false);
    const isTeacher = userInfoStore.isTeacherView();

    useEffect(() => {
        audio.addEventListener('ended', () => {
            setIsPlayingContent(false);
        });

        return () => {
            audio.pause();
            audio.src = "";
            audio.load();
        }
    }, [audio]);

    //icon显示，播放时显示暂停，暂停时显示播放，生成音频时显示加载
    const showAudioIcon = () => {
        if (isPlayingContent) {
            return isHoveredAudioPlayingIcon ? StopAudioIcon : AudioPlayingIcon;
        } else if (isGeneratingAudio) {
            return GenAudioLoading;
        } else {
            return PlayAudioIcon;
        }
    }

    const genVoice = async (text: string) => {
        let res: any;
        try {
            setIsGeneratingAudio(true);
            res = await ttsAPI.genVoiceAndUpload(
                {
                    text: text,
                    voice_type: 'BV700_streaming'
                }, 'tts');

            // 更新音频源和状态  
            setContentAudioUrl(res.file_url); // 存储生成的音频链接  
            audio.src = res.file_url;

            if (isTeacher) {
                return;
            }

            // 更新block trace data
            const newElementData = cloneDeep(elementData);
            newElementData.content.contentVoiceUrl = res.file_url;
            const newBlockTraceData = cloneDeep(blockTraceData);
            newBlockTraceData.traceInfo = blockTraceData.traceInfo.map((element) => {
                if (element.id === elementData.id) {
                    return newElementData;
                }
                return element;
            });

            try {
                const res: any = await service.put('/block_trace', newBlockTraceData);
                if (res.status === "success") {
                    lectureStore.updateElement(blockTraceData.id, elementData.id, newElementData.content);
                }
            } catch (error) {
                console.error('Error updating block trace data:', error);
            } finally {
                setIsGeneratingAudio(false);
            }
        } catch (error) {
            console.error('genVoiceAndUpload:', error);
        } finally {
            setIsGeneratingAudio(false);
        }
    }

    //点击播放按钮
    const clickAudioIcon = async () => {
        if (isGeneratingAudio) return;

        if (isPlayingContent) {
            audio.pause();
            setIsPlayingContent(false);
        } else {
            // 检查现有的音频源  
            if (contentAudioUrl) { // 使用存储的音频链接  
                audio.src = contentAudioUrl;
            } else {
                await genVoice(containsVariablePattern(elementData.content.content) ? rewriteText(elementData.content.content) : elementData.content.content);
            }
            audio.play().then(() => {
                setIsPlayingContent(true);
            }).catch(err => {
                console.error('Error playing audio:', err);
            });
        }
    }

    const getLikeCount = async () => {
        try {
            const res = await getLikeCnt({ object_id: blockTraceData.id });
            setLikeCount(res.data);
        } catch (error) {
            console.error('Error getting like count:', error);
        }
    }

    const clickLikeIcon = async () => {
        if (isTeacher) {
            return;
        }
        if (!userInfoStore.userInfoData.id || !blockTraceData.id) {
            return;
        }
        const data = {
            user_id: userInfoStore.userInfoData.id,
            object_id: blockTraceData.id,
            object_type: 'basicWithSound',
            create_time: 0
        }
        if (isLiked) {
            const res = await unlikeObjectAndUpdate(data);
            if (res.status === 'success') {
                setIsLiked(false);
                setLikeCount(res.data);
            }
        } else {
            const res = await likeObjectAndUpdate(data);
            if (res.status === 'success') {
                setIsLiked(true);
                setLikeCount(res.data);
            }
        }
    }

    const clickShareIcon = async () => {
        setQrLoading(true);
        let audioUrl = contentAudioUrl;
        //没有音频时，生成音频
        if (!audioUrl) {
            try {
                let res = await ttsAPI.genVoiceAndUpload(
                    {
                        text: containsVariablePattern(elementData.content.content) ? rewriteText(elementData.content.content) : elementData.content.content,
                        voice_type: 'BV700_streaming'
                    }, 'tts');
                audioUrl = res.file_url;
            } catch (error) {
                console.error('genVoiceAndUpload:', error);
                audioUrl = '';
            }
        }

        const wornInfoData = new BasicShareWithSoundInfo({
            title: containsVariablePattern(elementData.content.title) ? rewriteText(elementData.content.title) : elementData.content.title,
            content: containsVariablePattern(elementData.content.content) ? rewriteText(elementData.content.content) : elementData.content.content,
            imgUrl: containsVariablePattern(elementData.content.imgUrl) ? rewriteText(elementData.content.imgUrl) : elementData.content.imgUrl,
            shareDescription: containsVariablePattern(elementData.content.shareDescription) ? rewriteText(elementData.content.shareDescription) : elementData.content.shareDescription,
            contentVoiceUrl: audioUrl,
        })
        const concatenatedString = JSON.stringify(wornInfoData);

        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: "basicWithSound",
            work_info: wornInfoData,
            create_time: 0
        };
        const shareLinkRes: any = await createUserWork(shareLinkData);
        if (shareLinkRes.status === 'success') {
            setQrUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
            setQrLoading(false);
        } else {
            setQrLoading(false);
            message.error('生成分享链接失败');
        }
    };

    useEffect(() => {
        if (blockTraceData?.id && !isTeacher) {
            getLikeCount();
        }
    }, [blockTraceData?.id]);

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.mediumAudioBox}>
                    <div className={styles.playRegion}>
                        <div className={styles.player}>
                            <div className={styles.songCover}>
                                {elementData.content.imgUrl ? (
                                    <img src={containsVariablePattern(elementData.content.imgUrl) ? rewriteText(elementData.content.imgUrl) : elementData.content.imgUrl} alt="封面" />
                                ) : (
                                    <div className={styles.defaultCover}></div>
                                )}
                            </div>
                        </div>
                        <div className={styles.userOperation}>
                            <div className={styles.like} onClick={clickLikeIcon}>
                                {isLiked ? (
                                    <img src={LikeIcon} alt="" />
                                ) : (
                                    <img src={UnLikeIcon} alt="" />
                                )}
                                {likeCount !== 0 && <div className={styles.likeCount}>{likeCount}</div>}
                            </div>
                            <div className={styles.share} onClick={clickShareIcon}>
                                <Tooltip
                                    color='white'
                                    trigger='click'
                                    title={<QRCode
                                        value={qrUrl} size={150}
                                        status={qrLoading ? "loading" : "active"}
                                    />
                                    }
                                >
                                    <img src={ShareIcon} alt="" />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className={styles.infoRegion}>
                        <div className={styles.songName}>
                            {containsVariablePattern(elementData.content.title) ?
                                rewriteText(elementData.content.title) : elementData.content.title}
                        </div>
                        <div className={styles.lyricBox}>
                            <div className={styles.lyric}>
                                <div className={styles.content}>
                                    {containsVariablePattern(elementData.content.content) ?
                                        rewriteText(elementData.content.content) : elementData.content.content}
                                </div>
                            </div>
                            <div className={styles.playContentBtn}>
                                <img
                                    src={showAudioIcon()}
                                    alt="audio"
                                    onClick={clickAudioIcon}
                                    onMouseEnter={() => setIsHoveredAudioPlayingIcon(true)}
                                    onMouseLeave={() => setIsHoveredAudioPlayingIcon(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    )
}

export default observer(ShareWithSoundRegion);
