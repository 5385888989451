import styles from './shareCode.module.less';
import CodeInfo from '@/base/ShareData/CodeInfo';
import StartGameIcon from '@/assets/img/share_page/share_code_icon.png';

interface ShareCodeProps {
    codeInfo: CodeInfo;
}

const ShareCode: React.FC<ShareCodeProps> = ({ codeInfo }) => {
    const onClickStartGame = () => {
        window.location.href = codeInfo.url;
    }
    return (
        <div className={styles.page}>
            <div className={styles.contentBox}>
                <div className={styles.title_1}>
                    这是{codeInfo.authorName}创作的游戏
                </div>
                <div className={styles.title_2}>
                    快来试试吧！
                </div>
                <div className={styles.startBtnBox}>
                    <img src={StartGameIcon} className={styles.btnIcon} />
                    <div className={styles.startGameBtn} onClick={onClickStartGame}>
                        开始游戏
                    </div>
                </div>
                <div className={styles.tooltip}>
                    温馨提示：如果想分享游戏，请在此页面分享
                </div>
            </div>
        </div>
    )
}

export default ShareCode;