import { useState } from 'react';
import YinFuIcon from '@/assets/img/yinfu.svg';
import styles from './genCustomMusic.module.less';
import MagicWand from '../MagicWand/MagicWand';
import { Button, message, Select } from 'antd';
import MultimediaAPI from '@/api/multimedia';
import { createSong, musicGenV2, addSongToAlbum } from '@/api/playgroundMusic';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import { userInfoStore } from '@/store/userInfoStore';
import { requestLLMCallV2 } from '@/services/requestLLMCall';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import MicrophoneIcon from '@/assets/img/playground/microphone.svg';
import { checkLyricsLength } from '@/utils/utils';

const { Option } = Select;

interface GenCustomMusicProps {
    userId: number;
    getAlbumList: () => void;
};

const GenCustomMusic: React.FC<GenCustomMusicProps> = ({ userId, getAlbumList }) => {
    const vendor = import.meta.env.VITE_GEN_MUSIC_VENDOR;
    const api = new MultimediaAPI();
    const [songTitle, setSongTitle] = useState("");
    const [songLyric, setSongLyric] = useState("");
    const [songStyle, setSongStyle] = useState("");
    const [apiStatus, setApiStatus] = useState(0); // 0: not started, 1: generating,
    let genreList = [
        { value: "流行", label: "流行" },
        { value: "民谣", label: "民谣" },
        { value: "电子", label: "电子" },
        { value: "摇滚", label: "摇滚" },
        { value: "儿歌", label: "儿歌" },
        { value: "情歌", label: "情歌" },
        { value: "古典", label: "古典" },
        { value: "爵士", label: "爵士" },
        { value: "嘻哈", label: "嘻哈" },
        { value: "节奏布鲁斯", label: "节奏布鲁斯" },
        { value: "金属", label: "金属" },
        { value: "蓝调", label: "蓝调" },
        { value: "古风", label: "古风" },
        { value: "中国风", label: "中国风" },
        { value: "戏曲", label: "戏曲" },
        { value: "校园", label: "校园" },
        { value: "Kpop", label: "Kpop" },
        { value: "ACG", label: "ACG" },
        { value: "宗教歌曲", label: "宗教歌曲" },
        { value: "Bossa Nova", label: "Bossa Nova" },
    ];
    //将生成的歌曲信息存入数据库
    const saveSong = async (name: string, lyrics: string, musicUrl: string,
        coverUrl: string, style: string) => {
        try {
            const songData = {
                id: 0,
                name: name,
                author_id: userId,
                lyrics: lyrics,
                music_url: musicUrl,
                cover_url: coverUrl,
                tags: style,
                play_count: 0,
                like_count: 0,
                update_time: 0
            };

            // 保存歌曲  
            const songResponse: any = await createSong(songData);
            if (songResponse.status === 'success') {
                console.log('save song success:', songResponse);

                // 将歌曲添加到专辑  
                const albumResponse: any = await addSongToAlbum(userId, songResponse.data.id);
                if (albumResponse.status === 'success') {
                    setApiStatus(0);
                    message.success('歌曲创作完成，快去听听吧~');

                    // 获取专辑列表  
                    getAlbumList();
                } else {
                    console.error('add song to album failed');
                }
            } else {
                console.error('save song failed');
            }
        } catch (error) {
            console.error('save song error:', error);
        }
    };
    const pollForSongUrl = async (taskID: string) => {
        let isPolling = false;
        let totalTry = 0;
        const intervalId = setInterval(async () => {
            if (isPolling) return;
            totalTry += 1;
            try {
                isPolling = true;
                const res: any = await api.getSongByTaskId(taskID);
                console.log('getSongByTaskId', res);
                if (res && res['status'] === 'success' && res['data']['song_status'] === 'done') {
                    console.log('song is done');
                    clearInterval(intervalId);
                    saveSong(res['data']['song']['title'], res['data']['song']['lyrics'].replace(/\[.*?\]/g, ' '), res['data']['song']['audio_url'], res['data']['song']['song_cover_url'], "");
                    setApiStatus(0);
                } else if (res && res['status'] === 'success' && res['data']['song_status'] === 'processing') {
                    console.error('song is still being generated');
                } else {
                    console.error('song pool request error');
                }
            } catch (error) {
                // setApiStatus(0);
                //clearInterval(intervalId);
                //console.error('getSongById:', error);
            } finally {
                isPolling = false; // 请求完成后，标志位恢复，允许下一次请求
                console.log('totalTry:', totalTry);
                if (totalTry >= 100) {
                    clearInterval(intervalId);
                    handleError();
                }
            }
        }, 3000); // 每3秒轮询一次  
    };

    const handleGenerate = async () => {
        if (songTitle.trim() === "") {
            message.warning('请输入歌名~');
            return;
        }
        if (songLyric.trim() === "") {
            message.warning('请输入歌词~');
            return;
        }
        if (!checkLyricsLength(songLyric)) {
            message.warning('歌词长度过长，请删减~');
            return;
        }
        if (songStyle.trim() === "") {
            message.warning('请输入曲风~');
            return;
        }
        if (apiStatus === 1) {
            message.warning('正在生成中，请稍等');
            return;
        }
        setApiStatus(1);

        const requestData = {
            request_id: "string",
            title: songTitle,
            lyrics: songLyric,
            genre: songStyle || '',
        };

        try {
            const res: any = await api.nfGenSong(requestData);
            if (res && res['status'] == 'success') {
                const taskID = res['task_id'];
                pollForSongUrl(taskID);
            } else {
                setApiStatus(0);
                message.error('无法获取歌曲生成的task_id');
            }
        } catch (error) {
            console.error('genMusic:', error);
            handleError();
        }
    };

    const handleError = () => {
        message.error('生成歌曲失败，请重试~');
        setApiStatus(0);
    }

    const handleMagicGen = (prompt: string, setHandler: any) => {
        //需要做rewrite
        const requestParams = LLMCallRequestData.genLLMCallParams(
            prompt,
            userInfoStore.userInfoData.id,
            userInfoStore.userInfoData.name,
            false,
        )

        requestLLMCallV2(requestParams).then((res) => {
            if (res) {
                console.log('res.content :', res.content);
                setHandler(res.content);
            } else {
                console.error('res is null');
            }
        }).catch((error) => {
            console.error('Error:', error);
        });

    };
    return (
        <div className={styles.customGen}>
            <div className={styles.customHeader}>
                <div className={styles.boboMusicIcon}>
                    <img src={YinFuIcon} />
                </div>
                <div className={styles.headerTitle}>歌曲大模型</div>
            </div>
            <div className={styles.songTitle}>
                <input
                    className={styles.input}
                    value={songTitle}
                    onChange={(e) => setSongTitle(e.target.value)}
                    placeholder='请输入歌名'
                />
                <div className={styles.magicGenBtn}>
                    <SpeechToText
                        setInputMessage={setSongTitle}
                        inputMessage={songTitle}
                        microphoneIcon={MicrophoneIcon}
                    />
                    <MagicWand onMagicGenerate={() => {
                        handleMagicGen("我想写一首歌，帮我生成一个歌名,直接给出歌名，不要其他的内容,不要引号", setSongTitle);
                    }} />
                </div>
            </div>
            <div className={styles.songLyric}>
                <textarea
                    className={styles.textarea}
                    value={songLyric}
                    onChange={(e) => setSongLyric(e.target.value)}
                    placeholder='请输入歌词'
                />
                <div className={styles.magicGenBtn}>
                    <SpeechToText
                        setInputMessage={setSongLyric}
                        inputMessage={songLyric}
                        microphoneIcon={MicrophoneIcon}
                    />
                    <MagicWand onMagicGenerate={() => {
                        if (songTitle.trim() === "") {
                            message.warning('请先生成歌名~');
                            return;
                        }
                        handleMagicGen(`我想写一首歌${songTitle}，帮我生成歌词，只要歌词内容，不要和歌词不相关的其他内容(包括歌名，小节提示等)`, setSongLyric);
                    }} />
                </div>
            </div>
            <div className={styles.songStyle}>
                <Select
                    className={styles.select}
                    // value={songStyle}
                    onChange={(value) => setSongStyle(value)}
                    placeholder="请选择曲风"
                >
                    {genreList.map((item) => (
                        <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))}
                </Select>
            </div>

            <Button
                className={styles.genMusicButton}
                onClick={handleGenerate}
                loading={apiStatus === 1}
                icon={<img src={YinFuIcon} />}
            >
                {["开始创作", "创作中（大概需要2~3分钟）"][apiStatus]}
            </Button>
        </div>
    )
}

export default GenCustomMusic;