import DataDefinition from "../DataDefinition";

class ChatAgentInfo extends DataDefinition {
    systemPrompt: string;
    headerText: string;
    agentAvatar: string;

    constructor({
        systemPrompt = '',
        headerText = '',
        agentAvatar = '',
    }: Partial<ChatAgentInfo> = {}) {
        super();
        this.allowedFields = ['systemPrompt', 'headerText', 'agentAvatar'];
        this.systemPrompt = systemPrompt;
        this.headerText = headerText;
        this.agentAvatar = agentAvatar;
    }
}

export default ChatAgentInfo;