import styles from '../common/element.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { useState, useEffect, useRef } from 'react';
import MultimediaAPI from '@/api/multimedia';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import AudioDynamicIcon from '@/components/AudioDynamicIcon/AudioDynamicIcon';
import AudioStaticIcon from '@/components/AudioStaticIcon/AudioStaticIcon';
import AudioPauseIcon from '@/components/AudioPauseIcon/AudioPauseIcon';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { ElementData } from '@/base/ElementData/ElementData';
import TextRegionContent from '@/base/ElementData/TextRegionContent';
import { Property } from 'csstype'

interface TextPlayableRegionProps extends IntrinsicElementProps<TextRegionContent> {
    handleContentChange: (elementData: ElementData<TextRegionContent>, newText: string) => void;
}

const TextPlayableRegion: React.FC<TextPlayableRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    handleContentChange
}) => {
    const { audioStore, viewportStore, userInfoStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const { scaleRatio } = viewportStore;
    const [isEditing, setIsEditing] = useState(false);
    const [isHandlePlaying, setIsHandlePlaying] = useState(false);//手动点击播放按钮时，是否正在播放
    const [isHovering, setIsHovering] = useState(false); // 添加一个新的状态来跟踪鼠标是否悬浮在播放图标上
    const audioHandlePlayingRef = useRef<HTMLAudioElement | null>(null);
    let finalText = containsVariablePattern(elementData.content.text) ? rewriteText(elementData.content.text) : elementData.content.text;

    useEffect(() => {
        if (elementData.content.audioUrl) {
            audioHandlePlayingRef.current = new Audio(elementData.content.audioUrl);
            // 添加ended事件，播放结束时触发
            audioHandlePlayingRef.current.addEventListener('ended', () => {
                setIsHandlePlaying(false);
            });
        }
        // 组件卸载时移除事件监听器
        return () => {
            if (audioHandlePlayingRef.current) {
                audioHandlePlayingRef.current.removeEventListener('ended', () => {
                    setIsHandlePlaying(false);
                });
                audioHandlePlayingRef.current.pause();
            }
        };
    }, []);

    const imgIcon = () => {
        if (isHandlePlaying) {
            if (isHovering) {
                return <AudioPauseIcon color={elementData.content.audioIconColor || '#1195db'} />;
            } else {
                return <AudioDynamicIcon color={elementData.content.audioIconColor || '#1195db'} />;
            }
        } else {
            return <AudioStaticIcon color={elementData.content.audioIconColor || '#1195db'} />;
        }
    }

    const audioIconSize = () => {
        if (elementData.content.audioIconSize === 'small') {
            return styles.smallAudioIcon;
        } else if (elementData.content.audioIconSize === 'medium') {
            return styles.mediumAudioIcon;
        } else {
            return styles.largeAudioIcon;
        }
    }

    const handleDoubleClick = () => {
        if (isEditable) {
            setIsEditing(true);
        }
    };

    const handleBlur = (e: any) => {
        if (isEditable) {
            setIsEditing(false);
            console.log('handleBlur', e.target.nodeName, e, elementData);
            if (e.target.nodeName === 'SPAN') {
                return;
            }
            handleContentChange(elementData, e.target.innerText);
        }
    };

    const handlePlayText = () => {
        audioStore.setCurrentAudioEleId(elementData.id);
        const api = new MultimediaAPI();

        if (!elementData.content.audioUrl) {
            api.genVoiceAndUpload({ text: finalText }, 'tts')
                .then((res) => {
                    console.log('genVoiceAndUpload:', res);
                    elementData.content.audioUrl = res.file_url;

                    // 创建新的Audio实例用于播放生成的URL
                    audioHandlePlayingRef.current = new Audio(res.file_url);

                    // 监听 ended 事件
                    audioHandlePlayingRef.current.onended = () => {
                        setIsHandlePlaying(false);
                    };

                    audioHandlePlayingRef.current.play().catch((error) => {
                        console.error('Error playing audio:', error);
                    });

                    setIsHandlePlaying(true);
                })
                .catch((error) => {
                    console.error('genVoiceAndUpload:', error);
                });
        } else {
            // 直接使用之前创建的Audio实例播放
            if (audioHandlePlayingRef.current) {
                audioHandlePlayingRef.current.play().catch((error) => {
                    console.error('Error playing audio:', error);
                });

                setIsHandlePlaying(true);
            } else {
                console.error('audioHandlePlayingRef.current is not initialized');
            }
        }
    };


    const handlePauseText = () => {
        if (audioHandlePlayingRef.current) {
            audioHandlePlayingRef.current.pause();
            setIsHandlePlaying(false);
        }
    };

    useEffect(() => {
        if (!isTeacher) {
            if (elementData.content.autoPlay) {
                handlePlayText();
            }
        }
        return () => {
            if (audioHandlePlayingRef.current) {
                audioHandlePlayingRef.current.pause();
                setIsHandlePlaying(false);
            }
        }
    }, [])

    useEffect(() => {
        if (audioStore.currentAudioEleId !== elementData.id) {
            if (audioHandlePlayingRef.current) {
                audioHandlePlayingRef.current.pause();
                setIsHandlePlaying(false);
            }
        }
    }, [audioStore.currentAudioEleId, elementData.id])

    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}
    >
        <div
            style={{
                ...commonStyle,
                fontSize: `${scaleRatio * elementData.content.size}px`,
                color: `${elementData.content.color}`,
                fontWeight: `${elementData.content.fontWeight}`,
                whiteSpace: 'pre-wrap', wordBreak: 'break-all',
                border: `${elementData.content.borderWidth}px solid ${elementData.content.borderColor}`,
                borderRadius: `${elementData.content.borderRadius}px`,
                boxSizing: 'border-box', userSelect: 'none',
                paddingLeft: `${elementData.content.paddingLeft}px`,
                paddingRight: `${elementData.content.paddingRight}px`,
                paddingTop: `${elementData.content.paddingTop}px`,
                paddingBottom: `${elementData.content.paddingBottom}px`,
            }}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            onDoubleClick={handleDoubleClick}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            contentEditable={isEditing}
            onBlur={handleBlur}
            suppressContentEditableWarning={true}>
            <div
                className={styles.text}
                style={{
                    textAlign: elementData.content.textAlign as Property.TextAlign,
                    justifyContent: elementData.content.textAlign,
                    alignItems: elementData.content.verticalAlign,
                    display: 'flex',
                    lineHeight: `${elementData.content.lineHeight || '1.2'}`,
                }}
            >
                {elementData.content.showText ? (
                    <>{finalText}</>
                ) : (
                    elementData.content.showText === undefined ? (
                        <>{finalText}</>
                    ) : (
                        isEditable ? (
                            <span style={{ opacity: 0.3 }}>{finalText}</span>
                        ) : <span style={{ opacity: 0 }}>{finalText}</span>
                    )
                )}

                {elementData.content.showAudioIcon && (
                    <div
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        onClick={isHandlePlaying ? handlePauseText : handlePlayText}
                        className={`${styles.playIcon} ${audioIconSize()}`}
                    >
                        {imgIcon()}
                    </div>
                )}
            </div>
        </div>
    </BaseDragableElement >)
};

export default observer(TextPlayableRegion);
