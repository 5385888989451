import DataDefinition from "../DataDefinition";

class BasicSharePageInfo extends DataDefinition {
    title: string;
    body: string;
    imgUrl: string;
    backgroundStyle: string;
    authorName: string;

    constructor({
        title = "",
        body = "",
        imgUrl = "",
        backgroundStyle = "blue",
        authorName = "",
    }: Partial<BasicSharePageInfo> = {}) {
        super();
        this.allowedFields = ['title', 'body', 'imgUrl', 'backgroundStyle', 'authorName'];
        this.title = title;
        this.body = body;
        this.imgUrl = imgUrl;
        this.backgroundStyle = backgroundStyle;
        this.authorName = authorName;
    }
}

export default BasicSharePageInfo;