import React, { useState } from 'react';
import { Modal, Input, message, Select } from 'antd';
import service from '@/services/axios';
import { genObjectIDbyTS } from '@/utils/id_generator';

interface CopyBlockModalProps {
    isOpen: boolean;
    onClose: () => void;
    targetSectionId: number;
    successCallback?: () => void;
    scrollBottom?: () => void;
}

const CopyBlockModal: React.FC<CopyBlockModalProps> = ({
    isOpen,
    onClose,
    targetSectionId,
    successCallback,
    scrollBottom
}) => {
    const [sourceSectionId, setSourceSectionId] = useState<number>(0);
    const [blockIndex, setBlockIndex] = useState<number>(0);

    const replaceIDsInObject = (obj: any): any => {
        if (Array.isArray(obj)) {
            // 如果是数组，遍历每一项  
            return obj.map(item => replaceIDsInObject(item));
        } else if (obj && typeof obj === 'object') {
            // 如果是对象，遍历每一对键值  
            const newObj: any = {};
            for (const key in obj) {
                if (key === 'id') {
                    // 如果键是 'id'，替换为新生成的 ID  
                    newObj[key] = genObjectIDbyTS();
                } else {
                    // 否则，递归处理  
                    newObj[key] = replaceIDsInObject(obj[key]);
                }
            }
            return newObj;
        }
        return obj;
    };

    const handleCopyBlock = async () => {
        if (!sourceSectionId) {
            message.error('Please enter section ID');
            return;
        }
        if (!blockIndex) {
            message.error('请输入第几个block');
            return;
        }
        try {
            const response: any = await service.get(`/section/${sourceSectionId}`);
            if (response.status === 'success') {
                let originBlockList = response.data.blks;
                // 从原始数据中获取对应的block，如果不存在则提示
                if (originBlockList.length < blockIndex - 1) {
                    message.error('Block index is out of range');
                    return;
                }
                let block = originBlockList[blockIndex - 1];
                console.log(block);
                // 生成新的block
                let newBlock = replaceIDsInObject(block);
                console.log(newBlock);
                const sectionRequestData = {
                    section_id: targetSectionId,
                    block: newBlock,
                    offset: -1
                }
                // 发送请求
                const response2: any = await service.put('/section_add_blk', sectionRequestData);
                if (response2.status === 'success') {
                    message.success('Block copied successfully');
                    successCallback && successCallback();
                    scrollBottom && scrollBottom();
                    onClose();
                } else {
                    message.error('Failed to copy block');
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal
            title="Copy Block"
            open={isOpen}
            onCancel={onClose}
            footer={null}
            centered
        >
            <Input placeholder="Section的ID" onChange={(e) => setSourceSectionId(parseInt(e.target.value))} />
            <Input placeholder="第几个block" onChange={(e) => setBlockIndex(parseInt(e.target.value))} />
            <button onClick={handleCopyBlock}>复制</button>
        </Modal>
    )
}

export default CopyBlockModal;