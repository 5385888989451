import DataDefinition from '../DataDefinition';
import { ElementContent, ElementData } from './ElementData';
import { genObjectIDbyTS } from '../../utils/id_generator';
import AdvancedButtonRegionContent from './AdvancedButtonRegionContent';

class FirstGroupItem extends DataDefinition {
    id: number;
    value: string;
    @Reflect.metadata('design:type', ElementData)
    btnObj: ElementData<AdvancedButtonRegionContent> | null;
    templateUrl: string;

    constructor({
        id = genObjectIDbyTS(),
        value = "group1_item1",
        btnObj = AdvancedButtonRegionContent.genDefaultAdvBtnAsBtnObj({
            text: "group1_item1",
            width: 200,
            height: 75,
            textAlign: 'left',
            paddingLeft: 15,
            paddingRight: 15
        }),
        templateUrl = "default_group1_item"
    }: Partial<FirstGroupItem> = {}) {
        super();
        this.allowedFields = ['id', 'value', 'btnObj', 'templateUrl'];
        this.id = id;
        this.value = value;
        this.btnObj = btnObj;
        this.templateUrl = templateUrl;
    }
}

class SecondGroupItem extends DataDefinition {
    id: number;
    value: string;
    correctAnswer: string;
    @Reflect.metadata('design:type', ElementData)
    btnObj: ElementData<AdvancedButtonRegionContent> | null;
    templateUrl: string;

    constructor({
        id = genObjectIDbyTS(),
        value = "group2_item1",
        correctAnswer = "",
        btnObj = AdvancedButtonRegionContent.genDefaultAdvBtnAsBtnObj({
            text: "group2_item1",
            width: 200,
            height: 75,
            textAlign: 'left',
            paddingLeft: 15,
            paddingRight: 15
        }),
        templateUrl = "default_group2_item"
    }: Partial<SecondGroupItem> = {}) {
        super();
        this.allowedFields = ['id', 'value', 'correctAnswer', 'btnObj', 'templateUrl'];
        this.id = id;
        this.value = value;
        this.correctAnswer = correctAnswer;
        this.btnObj = btnObj;
        this.templateUrl = templateUrl;
    }
}

class LineConnectorRegionContent extends ElementContent {
    backgroundColor: string;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', FirstGroupItem)
    firstGroup: FirstGroupItem[];
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', SecondGroupItem)
    secondGroup: SecondGroupItem[];
    connectionsResult: Record<string, string>[];
    shouldComplete: boolean;
    visible: boolean;
    connectionMode: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 800,
        height = 460,
        backgroundColor = "#ffffff",
        firstGroup = [new FirstGroupItem()],
        secondGroup = [new SecondGroupItem()],
        connectionsResult = [],
        shouldComplete = true,
        visible = true,
        connectionMode = "one_to_one"

    }: Partial<LineConnectorRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'backgroundColor', 'firstGroup', 'secondGroup', 'connectionsResult', 'shouldComplete',
            'visible', 'connectionMode'];
        this.backgroundColor = backgroundColor;
        this.firstGroup = firstGroup;
        this.secondGroup = secondGroup;
        this.connectionsResult = connectionsResult;
        this.shouldComplete = shouldComplete;
        this.visible = visible;
        this.connectionMode = connectionMode;
    }

    getChildren(): ElementData<ElementContent>[] {
        let result: ElementData<ElementContent>[] = [];
        this.firstGroup.forEach(item => {
            if (item.btnObj) {
                result.push(item.btnObj);
                result = result.concat(item.btnObj.content.getChildren());
            }
        });
        this.secondGroup.forEach(item => {
            if (item.btnObj) {
                result.push(item.btnObj);
                result = result.concat(item.btnObj.content.getChildren());
            }
        });
        return result;
    }

    getPrettyClassifyResult(): string {
        let resultString = "用户还没进行连线";
        if(Object.keys(this.connectionsResult).length > 0) {
            // iterate through the result field
            resultString = "用户的连线结果是：";
            for (let key in this.connectionsResult) {
                resultString += key + "和" + this.connectionsResult[key].toString() + "联在了一起。";
            }
        }
        return resultString;
    }

    convertToText(): string {
        let text = "用户需要对Items进行连线。" + this.getPrettyClassifyResult();
        text += this.getPrettyClassifyResult();
        return text;
    }

    getReadableContent(): string {
        return this.convertToText();
    }
};

export { LineConnectorRegionContent, FirstGroupItem, SecondGroupItem };