import React, { useEffect, useState } from 'react';
import styles from "./payments.module.less";
import { useNavigate } from 'react-router-dom';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { Table } from 'antd';
import { getUserAllPayments } from '@/api/userCenter';

const Payments: React.FC = () => {
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [paymentsList, setPaymentsList] = useState<any[]>([]);
    const columns = [
        {
            title: '商品名称',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            render: (status: number) => {
                const statusMap: { [key: number]: string } = {
                    0: '未支付',
                    1: '支付成功',
                    2: '取消支付',
                    3: '支付失败',
                };
                return statusMap[status];
            }
        },
        {
            title: '支付时间',
            dataIndex: 'create_time',
            key: 'create_time',
            render: (timestamp: number) => {
                const date = new Date(timestamp * 1000); // 假设时间戳是秒  
                return date.toLocaleString(); // 转换为本地时间字符串  
            },
        },
        {
            title: '订单号',
            dataIndex: 'external_order_id',
            key: 'external_order_id',
        },
    ];

    const getAllPayments = async () => {
        try {
            const res: any = await getUserAllPayments(Number(userInfoStore.userInfoData.phone));
            if (res.status === "success") {
                //为每一项添加key
                res.data.forEach((item: any) => {
                    item.key = item.order_id;
                });
                setPaymentsList(res.data);
            }
        } catch (error) {
            console.error('Error checking login status', error);
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id === 0) return;
        getAllPayments();
    }, [userInfoStore.userInfoData.id]);

    return (
        <div className={styles.paymentsPage}>
            <div className={styles.goBack} onClick={() => navigate('/user')}>
                <img src={GoBackIcon} alt="go back" />
            </div>

            <div className={styles.content}>
                <div className={styles.mainTitle}>订单</div>
                <div className={styles.subTitle}>在这里查看你的订单</div>
                <div className={styles.paymentsBox}>
                    <div className={styles.paymentsIcon}>
                        {/* <img src={UserPaymentsIcon} /> */}
                    </div>
                    <div className={styles.title}>
                        全部订单
                    </div>
                    <div className={styles.paymentsList}>
                        <Table
                            columns={columns}
                            dataSource={paymentsList}
                            pagination={false}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default observer(Payments);