import React from 'react';
import styles from '../common/element.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { convertUrl } from '@/utils/utils';
import VideoRegionContent from '@/base/ElementData/VideoRegionContent';


const VideoRegion: React.FC<IntrinsicElementProps<VideoRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete
}) => {
    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <video
                    style={{ width: '100%', height: '100%' }}
                    src={convertUrl(elementData.content.src)}
                    controls
                    className={styles.video}
                />
            </div>
        </BaseDragableElement>
    );
};

export default VideoRegion;
