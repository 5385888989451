import { InputNumber, Button, Input, Select } from 'antd';
import styles from './shareWithSoundModifier.module.less'
import ShareWithSoundRegionContent from '@/base/ElementData/ShareWithSoundRegionContent';
import { ElementData } from '@/base/ElementData/ElementData';
import MediaFileSelectorModifier from '../MediaFileSelectorModifier/MediaFileSelectorModifier';
import URLModifier from '../URLModifier/URLModifier';
import TextToSpeech from '@/components/TextToSpeech/TextToSpeech';

const { TextArea } = Input;

interface ShowCodeModifierProps {
    focusedItem: ElementData<ShareWithSoundRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};


const ShareWithSoundModifier: React.FC<ShowCodeModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <MediaFileSelectorModifier
                mediaType={'images'}
                afterSelectionCallback={url => onInputChange(focusedItem.id, 'imgUrl', url)}
                btnText={'图片'}
            />
            <URLModifier
                urlTitle='图片url'
                value={focusedItem.content.imgUrl}
                changeValue={value => onInputChange(focusedItem.id, 'imgUrl', value)}
            />
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>标题</div>
                <TextArea
                    value={focusedItem.content.title}
                    onChange={e => onInputChange(focusedItem.id, 'title', e.target.value)}
                    autoSize={{ minRows: 6, maxRows: 20 }}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>内容</div>
                <TextArea
                    value={focusedItem.content.content}
                    onChange={e => onInputChange(focusedItem.id, 'content', e.target.value)}
                    autoSize={{ minRows: 6, maxRows: 20 }}
                />
            </div>
            <div className={styles.modifyItem}>
                <TextToSpeech
                    text={focusedItem.content.content || ''}
                    btnText='生成音频'
                    successCallback={(url) => onInputChange(focusedItem.id, 'contentVoiceUrl', url)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>当前音频链接：</div>
                <div className={styles.modifyItemContent}>
                    {focusedItem.content.contentVoiceUrl ? focusedItem.content.contentVoiceUrl : '暂无'}
                </div>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>分享描述</div>
                <TextArea
                    value={focusedItem.content.shareDescription}
                    onChange={e => onInputChange(focusedItem.id, 'shareDescription', e.target.value)}
                    autoSize={{ minRows: 6, maxRows: 20 }}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
        </div>
    )
};

export default ShareWithSoundModifier;
